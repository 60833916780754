import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { operationTypes } from "utils/constants";
import { useLegacyState } from "utils/hooks";
import { axiosRequest } from "utils/api";
import { devicesOptions } from "utils/options";
import { getCountryOptions, getLanguageOptions, getRegionsForCountry } from "utils/formatters";
import { shouldDisableFormSubmission } from "utils/forms";

import { SelectInput, Button, ButtonGroup, TextInput } from "components/forms";
import { Box } from "components/utils";

function CreateKeywordForm({ defaultKeyword, onConfirm, onCancel }) {
  const [operationInProgress, setOperationInProgress] = useState(false);

  const [state, setState] = useLegacyState({
    countries: [],
    languages: [],
  });

  const [defaultValues] = useState({
    keyword: "",
    country: null,
    region: null,
    language: null,
    is_mobile: devicesOptions?.[0],
  });

  const {
    register,
    handleSubmit,
    errors: formErrors,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues,
  });

  const formValues = watch();

  useEffect(() => {
    if (defaultKeyword) {
      setValue("keyword", defaultKeyword?.keyword);
      setValue(
        "is_mobile",
        devicesOptions?.find((d) => Boolean(d.value) === defaultKeyword.is_mobile),
      );
    }
  }, [defaultKeyword]);

  useEffect(() => {
    if (defaultKeyword && state.languages.length) {
      setValue(
        "language",
        state.languages?.find((l) => l?.label === defaultKeyword?.language),
      );
    }
  }, [state.languages]);

  useEffect(() => {
    if (defaultKeyword && state.countries.length) {
      setValue(
        "country",
        state.countries?.find((c) => c?.label === defaultKeyword?.country),
      );
    }
  }, [state.countries]);

  const getCountries = async () =>
    axiosRequest({
      url: `/v1/serp-report/countries`,
      dataSetter: (countryData) => {
        const countries = getCountryOptions({ countryData });

        setState((s) => ({ ...s, countries }));
      },
    });

  const getLanguages = async () =>
    axiosRequest({
      url: `/v1/serp-report/languages`,
      dataSetter: (languageData) => {
        const languages = getLanguageOptions({ languageData });

        setState((s) => ({ ...s, languages }));
      },
    });

  const getRegions = async ({ location, isoCode }, setRegions) => {
    if (!isoCode) {
      return setRegions([]);
    }

    const regions = await axiosRequest({
      url: `/v1/serp-report/locations/${isoCode}?location=${location}`,
      dataSetter: (regionData) => getRegionsForCountry({ regionData, location, isoCode }),
    });

    return setRegions(regions);
  };

  const debouncedRegionSearch = useDebouncedCallback((...args) => {
    getRegions(...args);
  }, 500);

  useEffect(() => {
    getCountries();
    getLanguages();
  }, []);

  const handleOperation = async ({ values, type } = {}) => {
    await setOperationInProgress(true);

    if (type === operationTypes.CANCEL) {
      await onCancel();
    } else {
      await onConfirm(values);
    }

    setOperationInProgress(false);
  };

  const fields = [
    {
      label: "Keyword",
      type: "text",
      name: "keyword",
      component: TextInput,
      validation: {
        required: "Keyword is required",
      },
      autoFocus: true,
    },
    {
      label: "Country",
      name: "country",
      component: SelectInput,
      validation: {
        required: "Country is required",
      },
      placeholder: "Select country...",
      control,
      options: state?.countries,
      searchable: true,
      width: "100%",
    },

    {
      label: "Region",
      name: "region",
      component: SelectInput,
      placeholder: !formValues?.country ? "Select country first..." : "Select region...",
      control,
      async: true,
      width: "100%",
      loadOptions: (location, setRegions) =>
        debouncedRegionSearch({ location, isoCode: formValues?.country?.value }, setRegions),
      disabled: !formValues?.country,
    },
    {
      label: "Language",
      name: "language",
      component: SelectInput,
      validation: {
        required: "Language is required",
      },
      placeholder: "Select language...",
      control,
      options: state?.languages,
      searchable: true,
      width: "100%",
    },
    {
      label: "Device",
      name: "is_mobile",
      component: SelectInput,
      options: devicesOptions,
      validation: {
        required: "Device is required",
      },
      control,
      width: "100%",
    },
  ];

  const disableSubmit = shouldDisableFormSubmission({ formValues, nullableFields: ["region"] });

  const submitButtonText = defaultKeyword ? "Update keyword" : "Add keyword";

  return (
    <form onSubmit={handleSubmit((values) => handleOperation({ values, type: operationTypes.CONFIRM }))}>
      {fields.map(({ component: Component, validation, inputProps, ...fieldProps }) => (
        <Component
          key={fieldProps?.name}
          ref={register(validation)}
          error={formErrors?.[fieldProps?.name]?.message}
          {...inputProps}
          {...fieldProps}
        />
      ))}
      <Box flex justifyLeft margin="40px 0 0">
        <ButtonGroup>
          <Button
            type="button"
            disabled={operationInProgress}
            variant={Button.Variants.DANGER}
            onClick={() => handleOperation({ type: operationTypes.CANCEL })}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={disableSubmit}
            loading={operationInProgress}
            variant={Button.Variants.SECONDARY}
          >
            {submitButtonText}
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
}

export default CreateKeywordForm;
