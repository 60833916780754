import styled from "styled-components/macro";
import { theme } from "utils/theme";

const ErrorMessage = styled.div`
  font-size: 11px;
  min-height: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.colors.DANGER};
  visibility: ${({ children: error }) => (error ? "visible" : "hidden")};
  margin-bottom: ${({ withMargin }) => withMargin && "20px"};
  margin-top: 10px;
  text-align: right;
`;

export default ErrorMessage;
