import styled from "styled-components/macro";

import { SectionTitle } from "components/utils/Typography";
import { Box } from "components/utils";

function TopPanel({ title, leftSection, rightSection }) {
  return (
    <Wrapper>
      {leftSection && (
        <Box>
          {title && (
            <Box mb="16px">
              <SectionTitle>{title}</SectionTitle>
            </Box>
          )}
          {leftSection}
        </Box>
      )}
      {rightSection && <Box>{rightSection}</Box>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default TopPanel;
