import { DiffNegative, DiffPositive } from "components/icons";
import styled from "styled-components/macro";

import { theme } from "utils/theme";

function DiffIndicator({ value, displayValue }) {
  const isPositive = value > 0;
  const isNegative = value < 0;

  const color = isPositive
    ? theme.colors.DIFF_POSITIVE
    : isNegative
    ? theme.colors.DIFF_NEGATIVE
    : theme.colors.DIFF_NEUTRAL;

  const DiffComponent = isPositive ? DiffPositive : isNegative ? DiffNegative : "div";

  return (
    <Wrapper>
      <IconWrapper>
        <DiffComponent color={color} width="10px" height="7px" />
      </IconWrapper>
      <ValueWrapper color={color}>{displayValue || value}</ValueWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 4px;
  display: flex;
`;

const ValueWrapper = styled.div`
  font-size: 12px;
  line-height: 1;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${({ color }) => color};
`;

export default DiffIndicator;
