import * as React from "react";

const SvgComponent = (props) => (
  <svg width={18} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)" fill="#8e9ab7">
      <path d="M11.818 11.997H3.241a.6.6 0 1 0 0 1.2h8.577a.6.6 0 0 0 0-1.2ZM3.241 11.196h2.853a.6.6 0 1 0 0-1.2H3.241a.6.6 0 0 0 0 1.2ZM3.241 9.197H6.16a.6.6 0 1 0 0-1.2H3.24a.6.6 0 1 0 0 1.2Z" />
      <path d="M17.074 2.682a1.54 1.54 0 0 0-.589-1.036L14.997.34a1.71 1.71 0 0 0-2.339.278L9.377 4.511c-.024-.003-.046-.014-.07-.014H1.6c-.882 0-1.6.718-1.6 1.6v8.5c0 .881.718 1.6 1.6 1.6h8.475l1.256 1.75c.112.156.294.25.487.25h.014a.6.6 0 0 0 .49-.272l1.125-1.729h.957c.882 0 1.6-.718 1.6-1.6V6.098c0-.34-.117-.656-.308-.923l1.05-1.297c.269-.34.386-.765.328-1.195Zm-2.27 11.915c0 .22-.179.4-.4.4h-1.282a.602.602 0 0 0-.503.271l-.824 1.266-.925-1.287a.598.598 0 0 0-.487-.25H1.6a.4.4 0 0 1-.4-.4v-8.5c0-.22.18-.4.4-.4h6.777l-.254.301c-.011.014-.014.031-.025.045-.02.03-.036.061-.051.095a.583.583 0 0 0-.044.119c-.003.014-.012.026-.015.041l-.558 3.806a.6.6 0 0 0 .772.66l3.795-1.182a.587.587 0 0 0 .3-.228c.01-.01.024-.014.033-.026l2.475-3.055v8.323Zm1.006-11.47-4.345 5.364-2.71.844.396-2.694 4.428-5.253c.175-.212.482-.249.663-.116l1.49 1.306c.084.064.14.157.153.262a.384.384 0 0 1-.075.288Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h17.089v18.196H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
