import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.604 7.607" {...props}>
      <path
        d="M4.426 4.742a.55.55 0 00-.777.033l-.79.86.016-5.083A.55.55 0 002.327 0h-.002a.55.55 0 00-.55.548L1.76 5.642l-.801-.885a.55.55 0 10-.816.738L1.89 7.427c.104.114.251.18.406.18h.002a.55.55 0 00.405-.177l1.756-1.91a.55.55 0 00-.033-.778z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
