import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components/macro";

import { theme } from "utils/theme";
import { convertStorageToString } from "utils/formatters";

import { CloseButton, Preloader } from "components/shared";
import { Error, PdfDocument, UploadFile, UploadImage } from "components/icons";
import { Box } from "components/utils";

import { uploadFile } from "Files/redux/filesSlice";

const FileInput = ({ register, label, id, name, fullWidth, hideLabel, setValue, accept = "application/pdf" }) => {
  const dispatch = useDispatch();

  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    register(name);
  }, [register]);

  const onDrop = useCallback(async ([file]) => {
    setUploadInProgress(true);
    setUploadError(false);

    try {
      await setUploadedFile({
        name: file?.name,
        size: file?.size,
      });

      const rs = await dispatch(uploadFile({ file }));

      const fileUid = rs?.payload?.uid;

      if (!fileUid) {
        setUploadError(true);
        setUploadInProgress(false);
        setValue(name, null);

        return;
      }

      setValue(name, fileUid);

      setUploadError(false);
      setUploadInProgress(false);
    } catch (err) {
      setUploadError(err);
    } finally {
      //
    }
  }, []);

  const onDropRejected = useCallback(() => {
    setUploadInProgress(false);
    setUploadError(true);
    setUploadedFile(null);

    setValue(name, null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept,
    onDropRejected,
  });

  return (
    <FormControl fullWidth={fullWidth}>
      {label && !hideLabel && (
        <InputLabel htmlFor={id || name}>
          <span>{label}</span>
          <span>:</span>
        </InputLabel>
      )}
      <InputWrapper isDragActive={isDragActive} hasError={uploadError} {...getRootProps()}>
        <input {...getInputProps()} accept={accept} type="file" />
        {uploadError && !uploadInProgress ? (
          <PlaceholderText>
            <div>
              <Error width={34} height={34} />
            </div>
            <span>This file is not supported...</span>
          </PlaceholderText>
        ) : (
          <PlaceholderText>
            <div>{isDragActive ? <UploadFile width={34} height={34} /> : <UploadImage width={34} height={34} />}</div>
            <span>{isDragActive ? "Drop file to add..." : "Drag the file here or click to select..."}</span>
          </PlaceholderText>
        )}
      </InputWrapper>
      {uploadedFile && !uploadError && (
        <FileList>
          <Box flex justifyBetween>
            <Box flex>
              <Box mr="10px">
                <PdfDocument width="30px" height="37px" />
              </Box>
              <Box>
                <FileName>{uploadedFile?.name}</FileName>
                <FileSize>{convertStorageToString({ bytes: uploadedFile?.size })}</FileSize>
              </Box>
            </Box>
            <Box flex alignCenter>
              {uploadInProgress ? (
                <Box flex alignCenter>
                  <Preloader />
                </Box>
              ) : (
                <Box position="relative">
                  <CloseButton
                    onClick={() => {
                      setUploadedFile(null);
                      setValue(name, null);
                    }}
                    top={-32}
                    right={-14}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box />
        </FileList>
      )}
    </FormControl>
  );
};

const FileList = styled.div`
  border-radius: 10px;
  border: solid 1px ${theme.colors.FILE_INPUT_LIST_BORDER};
  margin-top: 20px;
  font-family: Rubik;
  font-weight: 500;
  padding: 13px 13px 10px;
`;

const PlaceholderText = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    margin-top: 10px;
    color: ${theme.colors.FILE_INPUT_PLACEHOLDER_TEXT};
  }
`;

const FileName = styled.div`
  overflow-wrap: anywhere;
  text-align: left;
  color: ${theme.colors.FILE_INPUT_FILE_NAME};
  font-size: 12px;
  line-height: 1.83;
`;

const FileSize = styled.div`
  text-align: left;
  color: ${theme.colors.FILE_INPUT_FILE_SIZE};
  font-size: 11px;
  line-height: 1.83;
`;

const FormControl = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  font-family: ${theme.fonts.RUBIK};
  display: block;
  font-size: ${theme.sizes.INPUT_LABEL_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 1.69;
  color: ${theme.colors.INPUT_LABEL_TEXT};
  margin-bottom: 10px;
`;

const InputWrapper = styled.div`
  position: relative;
  font-family: ${theme.fonts.RUBIK};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  border: 2px dashed ${theme.colors.INPUT_INACTIVE_BORDER};
  height: 165px;
  font-size: ${theme.sizes.INPUT_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${({ disabled }) => (disabled ? theme.colors.TEXT_DISABLED : theme.colors.INPUT_TEXT)};
  line-height: 1.83;
  cursor: pointer;
  outline: none;

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      border: 2px dashed ${theme.colors.INPUT_ACTIVE_BORDER};
    `};

  ${({ hasError }) =>
    hasError &&
    css`
      border: 2px dashed ${theme.colors.DANGER};
    `};

  &:focus,
  &:hover {
    border: 2px dashed ${theme.colors.INPUT_ACTIVE_BORDER};

    ${({ hasError }) =>
      hasError &&
      css`
        border: 2px dashed ${theme.colors.DANGER};
      `};
  }
`;

export default FileInput;
