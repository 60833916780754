import { forwardRef, useCallback } from "react";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_TOOLS } from "utils/editorTools";
import styled from "styled-components/macro";
import { theme } from "utils/theme";

const Editor = forwardRef(({ blocks, label = "Content", height }, ref) => {
  const ReactEditorJS = createReactEditorJS();

  const handleInitialize = useCallback((instance) => {
    ref.current = instance;
  }, []);

  return (
    <>
      {label && (
        <InputLabel>
          <span>{label}</span>
          <span>:</span>
        </InputLabel>
      )}
      <Content height={height}>
        <ReactEditorJS defaultValue={blocks} tools={EDITOR_TOOLS} onInitialize={handleInitialize} />
      </Content>
    </>
  );
});

const InputLabel = styled.label`
  font-family: ${theme.fonts.RUBIK};
  display: block;
  font-size: ${theme.sizes.INPUT_LABEL_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 1.69;
  color: ${theme.colors.INPUT_LABEL_TEXT};
  margin-bottom: 10px;
`;

const Content = styled.div`
  background-color: ${theme.colors.INPUT_BACKGROUND};
  color: ${theme.colors.BLOG_TEXT};
  border-radius: 9px;
  max-width: 810px;
  height: ${({ height }) => height};
  font-family: ${theme.fonts.MERRIWEATHER};
  overflow-y: auto;
  max-height: ${({ height }) => height};

  .codex-editor__redactor {
    padding-bottom: 150px;
    margin-right: 0;
  }

  .ce-block {
    padding: 0 15px;
  }

  .ce-block__content {
    max-width: 749px;
    line-height: 1.3;
    margin: 0 auto;
  }

  .ce-block--focused {
    margin-right: 0;
  }

  .ce-toolbar {
    left: unset;
    right: 20px;
  }

  .ce-toolbox {
    right: 60px;
  }

  h1 {
    font-size: 48px;
    font-weight: ${theme.fontWeights.BOLD};
    padding: 30px 0 0;
  }

  h2 {
    padding: 60px 0 0;
    font-size: 32px;
    line-height: 1.5;
    font-weight: ${theme.fontWeights.BOLD};
  }

  h3 {
    padding: 50px 0 0;
    font-size: 24px;
    font-weight: ${theme.fontWeights.BOLD};
  }

  h4,
  h5,
  h6 {
    font-weight: ${theme.fontWeights.BOLD};
    padding: 30px 0 0;
  }

  ul,
  ol {
    list-style-position: inside;
    padding: 30px 0 0;
  }

  li {
    padding: 0;
    line-height: 2.1em;
  }
  ul > li:before {
    font-size: 25px;
    margin-right: 15px;
  }

  .cdx-nested-list__item-children {
    padding: 0;
    padding-left: 5px;
  }

  a {
    color: ${theme.colors.BLOG_LINK};
    font-weight: ${theme.fontWeights.BOLD};

    &:hover {
      color: ${theme.colors.LINK_BLACK};
    }
  }

  .ce-paragraph {
    padding: 40px 0 0;
    font-size: 18px;
    line-height: 2.1;
  }

  .image-tool {
    padding: 30px 0 0;
  }

  mark {
    background-color: ${theme.colors.YELLOW};
  }

  .ce-delimiter {
    margin-top: 20px;

    &::before {
      font-size: 20px;
    }
  }
`;

export default Editor;
