import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import cookie from "react-cookies";
import config from "../utils/config";

const jwtToken = cookie.load("jwt-token");

export const baseQuery = fetchBaseQuery({
  baseUrl: config.API_URL,
  prepareHeaders: (headers, { getState }) => {
    if (!headers.get("content-type")) {
      headers.set("content-type", `application/json`);
    }

    let { token } = getState().auth;
    if (!token) {
      token = jwtToken;
    }

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const mainApi = createApi({
  baseQuery,
  keepUnusedDataFor: 99999999,
  endpoints: () => ({}),
});
