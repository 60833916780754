import { createSelector, createSlice } from "@reduxjs/toolkit";

const SLICE_KEY = "app";

const INITIAL_STATE = {
  isLoading: 0,
  pendingRequests: [],
};

const appSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    increaseLoading: (state) => {
      state.isLoading += 1;
    },
    decreaseLoading: (state) => {
      state.isLoading += -1;
    },
    handleRequestLoadingStart: (state, { payload: { actionType } }) => {
      state.pendingRequests.push(actionType);
    },
    handleRequestLoadingEnd: (state, { payload: { actionType } }) => {
      state.pendingRequests = state.pendingRequests.filter((request) => request !== actionType);
    },
  },
});

export const appSelector = createSelector(
  ({ app }) => app,
  ({ isLoading }) => ({
    isLoading,
  }),
);

export const pendingActionsSelector = ({ state, actions }) => {
  if (typeof actions === "string") {
    return state.app?.pendingRequests?.includes(actions);
  }

  return state.app?.pendingRequests?.some((action) => actions.includes(action));
};

const { reducer } = appSlice;

export const { increaseLoading, decreaseLoading, handleRequestLoadingStart, handleRequestLoadingEnd } =
  appSlice.actions;

export default reducer;
