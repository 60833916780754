import styled from "styled-components/macro";

import { theme } from "utils/theme";
import { numberToPercentage } from "utils/formatters";

import { DiffIndicator } from "components/shared";

function SummaryValue({ value, caption, unit, icon: Icon, chart, diff, color = theme.colors.SUMMARY_VALUE_TEXT }) {
  return (
    <Wrapper>
      <ValueWrapper>
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        {(value || value === 0) && <Value color={color}>{value}</Value>}
        {unit && <Unit>{unit}</Unit>}
        {diff ? (
          <DiffWrapper>
            <DiffIndicator displayValue={numberToPercentage({ value: diff })} value={diff} />
          </DiffWrapper>
        ) : null}
      </ValueWrapper>
      {caption && <Caption>{caption}</Caption>}
      {chart && <ChartWrapper>{chart}</ChartWrapper>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const IconWrapper = styled.div`
  margin-right: 12px;

  svg {
    min-width: 22px;
  }
`;

const DiffWrapper = styled.div`
  margin-left: 10px;
`;

const Value = styled.div`
  font-size: 30px;
  line-height: 0.73;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${({ color }) => color};
`;

const Unit = styled.div`
  font-size: 12px;
  line-height: 1;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.SUMMARY_VALUE_TEXT};
  margin-left: 5px;
`;

const Caption = styled.div`
  font-size: 12px;
  line-height: 1.83;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.SUMMARY_CAPTION_TEXT};
  text-align: center;
  margin-top: 8px;
`;

const ChartWrapper = styled.div`
  margin-top: 12px;
`;

export default SummaryValue;
