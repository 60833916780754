import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Modal } from "components/shared";
import { Box } from "components/utils";
import { Message } from "components/utils/Typography";

function ModalWithForm({
  icon,
  children,
  title,
  description,
  id,
  isOpen,
  width,
  onRequestClose,
  titleAlign = "center",
}) {
  return (
    <Modal id={id} isOpen={isOpen} width={width} height="200px" onRequestClose={onRequestClose}>
      <ContentWrapper>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Box width="100%">
          <Title align={titleAlign}>{title}</Title>
          <Description>{description}</Description>
          {children && <Form>{children}</Form>}
        </Box>
      </ContentWrapper>
    </Modal>
  );
}

const Form = styled.div`
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.MODAL_TITLE};
  font-weight: ${theme.fontWeights.MEDIUM};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: ${({ align }) => align};
  color: ${theme.colors.MODAL_TITLE};
  margin-bottom: 20px;
`;

const Description = styled(Message)`
  margin-bottom: 20px;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export default ModalWithForm;
