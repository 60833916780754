import { configureStore } from "@reduxjs/toolkit";

import { mainApi } from "api/api";
import appReducer from "App/redux/appSlice";
import authReducer from "Auth/redux/authSlice";
import logsReducer from "Logs/redux/logsSlice";
import usersReducer from "Users/redux/usersSlice";
import filesReducer from "Files/redux/filesSlice";
import seoReportsReducer from "Seo/redux/seoSlice";
import adminsReducer from "Admins/redux/adminsSlice";
import serpReportsReducer from "Serp/redux/serpSlice";
import filtersReducer from "Filters/redux/filtersSlice";
import projectsReducer from "Projects/redux/projectsSlice";
import reportLogsReducer from "ReportLogs/redux/reportLogsSlice";
import lighthouseReportsReducer from "Lighthouse/redux/lighthouseSlice";
import configureMiddleware from "./configureMiddleware";

const middleware = configureMiddleware({}, [], []);

const store = configureStore({
  reducer: {
    [mainApi.reducerPath]: mainApi.reducer,
    app: appReducer,
    auth: authReducer,
    logs: logsReducer,
    users: usersReducer,
    files: filesReducer,
    admins: adminsReducer,
    filters: filtersReducer,
    projects: projectsReducer,
    reportLogs: reportLogsReducer,
    serpReports: serpReportsReducer,
    seoReports: seoReportsReducer,
    lighthouseReports: lighthouseReportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(mainApi.middleware, middleware),
});

export default store;
