import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

const environment = process.env.REACT_APP_ENVIRONMENT;

const configureMiddleware = (_, platformDeps, platformMiddleware) => {
  const middleware = [
    thunk.withExtraArgument({
      ...platformDeps,
    }),
    ...platformMiddleware,
  ];

  const enableLogger = environment !== "production" && environment !== "development";

  // Logger must be the last middleware in chain.
  if (enableLogger) {
    const ignoredActions = [];
    const logger = createLogger({
      collapsed: true,
      predicate: (getState, action) => ignoredActions.indexOf(action.type) === -1,
      // Convert immutable to JSON.
      stateTransformer: (state) => JSON.parse(JSON.stringify(state)),
      timestamp: false,
      duration: false,
      diff: false,
      titleFormatter: ({ type }) => type,
    });
    middleware.push(logger);
  }

  return middleware;
};

export default configureMiddleware;
