import { sortOrders } from "utils/constants";
import { logLevels, paymentStatuses, planStatuses, planTypes } from "utils/entities";

export const logLevelsOptions = [
  { label: "All logs", value: logLevels.ALL },
  { label: "Critical", value: logLevels.CRITICAL },
  { label: "Error", value: logLevels.ERROR },
  { label: "Warning", value: logLevels.WARNING },
  { label: "Info", value: logLevels.INFO },
];

export const limitOptions = [
  { label: "5", value: 5 },
  { label: "15", value: 15 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

export const sortingOptionsAlpha = [
  { label: "Off", value: sortOrders.OFF },
  { label: "A-Z", value: sortOrders.ASCENDING },
  { label: "Z-A", value: sortOrders.DESCENDING },
];

export const sortingOptionsNumeric = [
  { label: "Off", value: sortOrders.OFF },
  { label: "Ascending", value: sortOrders.ASCENDING },
  { label: "Descending", value: sortOrders.DESCENDING },
];

export const toggleOptions = [
  { label: "Off", value: "off" },
  { label: "On", value: "on" },
];

export const booleanOptions = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const planTypesOptions = [
  { label: "None", value: planTypes.NONE },

  { label: "Demo", value: planTypes.DEMO },
  { label: "Trial", value: planTypes.TRIAL },

  { label: "Seodity Plan 1", value: planTypes.SEODITY_PLAN_1, isPaid: true },
  { label: "Seodity Plan 2", value: planTypes.SEODITY_PLAN_2, isPaid: true },
  { label: "Seodity Plan 3", value: planTypes.SEODITY_PLAN_3, isPaid: true },

  { label: "Custom Pricing", value: planTypes.CUSTOM, isPaid: true },

  { label: "AppSumo Tier 1", value: planTypes.APPSUMO_TIER1 },
  { label: "AppSumo Tier 2", value: planTypes.APPSUMO_TIER2 },
  { label: "AppSumo Tier 3", value: planTypes.APPSUMO_TIER3 },
  { label: "AppSumo Tier 4", value: planTypes.APPSUMO_TIER4 },
  { label: "AppSumo Tier 5", value: planTypes.APPSUMO_TIER5 },
];

export const paymentStatusesOptions = [
  { label: "None", value: paymentStatuses.NONE },
  { label: "Pending", value: paymentStatuses.PENDING },
  { label: "Paid", value: paymentStatuses.PAID },
  { label: "Past due", value: paymentStatuses.PAST_DUE },
  { label: "Failed", value: paymentStatuses.FAILED },
];

export const planStatusesOptions = [
  { label: "Active", value: planStatuses.ACTIVE },
  { label: "Cancelling", value: planStatuses.CANCELLING },
  { label: "Cancelled", value: planStatuses.CANCELLED },
  { label: "Expired", value: planStatuses.EXPIRED },
];

export const isActiveOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const devicesOptions = [
  { label: "Desktop", value: 0 },
  { label: "Mobile", value: 1 },
];

export const rankTrackerKeywordsOptions = [
  { label: "None", value: "" },
  { label: "Failed", value: paymentStatuses.FAILED },
];
