import styled from "styled-components/macro";

function LinkValue({ children, onClick }) {
  return <Anchor onClick={onClick}>{children}</Anchor>;
}

const Anchor = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export default LinkValue;
