import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="invoices_icon__Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 21.073 17.374"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".invoices_icon__st0{fill:#8794b2}"}</style>
      <path
        className="invoices_icon__st0"
        d="M6.097 4.59h1.64v1.148h-1.64zM6.097 6.927h1.64v1.148h-1.64zM13.358 5.927a4.418 4.418 0 00-.993-.287 5.42 5.42 0 01-.587-.156.72.72 0 01-.307-.175.341.341 0 01-.09-.236c0-.144.055-.251.168-.32.112-.07.265-.105.458-.105.186 0 .336.037.453.11.117.075.189.162.216.26.027.041.059.07.095.089a.279.279 0 00.128.026h.89c.04 0 .075-.014.102-.044a.142.142 0 00.04-.097 1.01 1.01 0 00-.171-.507 1.6 1.6 0 00-.483-.472 2.051 2.051 0 00-.783-.29v-.486c0-.05-.018-.09-.05-.122a.17.17 0 00-.119-.047h-.56a.162.162 0 00-.169.169v.472c-.455.064-.811.221-1.07.473a1.236 1.236 0 00-.388.918c0 .28.063.512.19.698s.308.338.55.452c.24.115.532.208.873.28.266.055.479.107.638.16.16.05.276.11.348.175a.318.318 0 01.108.246c0 .14-.066.25-.2.33-.133.082-.33.122-.59.122-.158 0-.29-.019-.395-.057a.709.709 0 01-.256-.152.788.788 0 01-.152-.196.592.592 0 00-.095-.088.243.243 0 00-.141-.033h-.85a.144.144 0 00-.106.044.136.136 0 00-.044.098c.01.207.072.403.19.59.116.187.29.346.523.476.231.13.52.218.867.263v.466a.161.161 0 00.169.169h.56a.167.167 0 00.169-.17v-.472a2.44 2.44 0 00.837-.25c.238-.125.424-.29.556-.492a1.25 1.25 0 00.2-.702c0-.274-.06-.503-.176-.685-.117-.182-.302-.33-.553-.445z"
      />
      <path
        className="invoices_icon__st0"
        d="M21.067 5.203l-.008-.35c-.012-.634-.024-1.289-.208-1.967-.354-1.304-.946-2.808-4.14-2.78L15.732 0H4.46c-.952 0-1.727.775-1.727 1.726v9.389H.7a.7.7 0 00-.699.699v1.772c0 1.848 1.393 3.412 3.313 3.72a.734.734 0 00.057.006c.077.03.162.046.25.046h10.336c.019 0 .035-.01.054-.01.011 0 .02.007.032.008.17.012.332.018.488.018.961 0 1.679-.23 2.185-.702.493-.458.742-1.117.742-1.96V7.495l2.22-.005c.364 0 .683-.133.924-.382.506-.521.48-1.368.466-1.905zM3.667 15.96a.721.721 0 00-.133-.035c-1.238-.198-2.136-1.182-2.136-2.34v-1.072h10.22l.001 1.819c.063.668.208 1.2.458 1.628h-8.41zM16.06 6.68V14.712c0 .446-.097.753-.296.938-.25.233-.729.338-1.39.318a.696.696 0 00-.196-.104c-.65-.216-1.052-.436-1.162-1.596v-2.453a.7.7 0 00-.7-.7H4.13V1.727c0-.18.148-.328.33-.328H15.73c.18 0 .328.147.328.328v4.955zm3.557-.588l-2.159.004v-4.37c0-.05-.01-.096-.014-.145 1.468.2 1.882 1.024 2.058 1.672.14.51.15 1.052.16 1.625l.009.396c.012.415-.006.69-.054.818z"
      />
    </svg>
  );
}

export default SvgComponent;
