import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { theme } from "utils/theme";
import { routes } from "utils/routes";

import { Logo } from "components/icons";

function NavLogo({ text = "Seodity Admin Panel", noLink }) {
  if (noLink) {
    return (
      <Wrapper>
        <Logo />
        <LogoText>{text}</LogoText>
      </Wrapper>
    );
  }

  return (
    <StyledLink to={routes.DASHBOARD}>
      <Wrapper>
        <Logo />
        <LogoText>{text}</LogoText>
      </Wrapper>
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  max-width: 206px;
`;

const LogoText = styled.div`
  text-transform: uppercase;
  color: ${theme.colors.LOGO_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  font-size: 13px;
  margin-left: 10px;
`;

export default NavLogo;
