import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="plan_gold_icon__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 41.04 30.154"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".plan_gold_icon__st0{fill:#f4d9de}.plan_gold_icon__st1{fill:#f9f2f3}"}</style>
      <path className="plan_gold_icon__st0" d="M28.34 6.949l-2.112-5.345H14.861l-2.112 5.345z" />
      <path className="plan_gold_icon__st1" d="M13.137 1.604H7.388l-4.79 5.345h8.427z" />
      <path className="plan_gold_icon__st0" d="M2.62 8.552l15.781 17.285-7.052-17.285z" />
      <path
        className="plan_gold_icon__st1"
        d="M28.009 8.552H13.08l7.464 18.292 3.353-8.216 1.707-4.184zM38.378 6.949l-5.24-5.345h-5.186l2.112 5.345z"
      />
      <path className="plan_gold_icon__st0" d="M27.089 15.05l-1.707 4.184-2.657 6.51L38.378 8.552H29.74z" />
      <path
        d="M40.81 7.14L34.049.24a.8.8 0 00-.573-.24H7.03a.804.804 0 00-.597.267L.204 7.215c-.035.04-.053.087-.079.131-.021.037-.05.07-.065.108a.798.798 0 00-.056.278c0 .007-.004.012-.004.019 0 .106.023.206.06.299.016.04.047.074.07.111.026.044.044.091.08.13l19.722 21.601a.801.801 0 00.592.262h.001a.8.8 0 00.592-.262L40.831 8.24a.802.802 0 00-.02-1.102zm-7.672-5.536l5.24 5.345h-8.314l-2.112-5.345h5.186zm-6.91 0l2.112 5.345H12.75l2.112-5.345h11.367zm-5.684 25.24L13.08 8.552H28.01l-2.405 5.892-1.707 4.184-3.353 8.216zM7.388 1.604h5.749l-2.112 5.345H2.597l4.791-5.345zM2.62 8.552h8.729L18.4 25.837 2.62 8.552zm20.105 17.192l2.657-6.51 1.707-4.183 2.651-6.499h8.638L22.725 25.744z"
        fill="#e02254"
      />
    </svg>
  );
}

export default SvgComponent;
