import { useEffect } from "react";

const useOnClickOutside = ({ ref = null, action = () => {}, handlerId }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const canClose = !handlerId || (handlerId && event.target.id === handlerId);

        if (canClose) {
          action();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action, handlerId]);
};

export default useOnClickOutside;
