import { toggleTypes, columnTypes, sortOrders, viewKeys, filterComparators } from "utils/constants";

import { TextInput } from "components/forms";

export const filterValueTypes = {
  [columnTypes.ALPHA]: {
    component: TextInput,
    options: [
      { label: "Contains", value: filterComparators.CONTAINS },
      { label: "Not contains", value: filterComparators.NOT_CONTAINS },
      { label: "Equals", value: filterComparators.EQUALS },
      { label: "Not equals", value: filterComparators.NOT_EQUALS },
    ],
  },
  [columnTypes.NUMERIC]: {
    component: TextInput,
    options: [
      { label: "Less than", value: filterComparators.LESS_THAN },
      { label: "Greater than", value: filterComparators.GREATER_THAN },
      { label: "Equals", value: filterComparators.EQUALS },
      { label: "Not equals", value: filterComparators.NOT_EQUALS },
    ],
  },
};

export const filterConfig = {
  [viewKeys.INVOICES]: {
    columns: [
      { label: "Title", value: "title", type: columnTypes.ALPHA, sortable: true },
      { label: "Created at", value: "created_at", sortable: true, type: columnTypes.ALPHA },
    ],
    defaultFilters: [
      {
        column: { label: "Title", value: "title", type: columnTypes.ALPHA, sortable: true },
        sorting: { label: "Off", value: sortOrders.OFF },
        filterEnabled: { label: "Off", value: toggleTypes.OFF },
      },
    ],
  },
  [viewKeys.LOGS]: {
    columns: [
      { label: "Level", value: "level", sortable: true, type: columnTypes.ALPHA },
      { label: "Type", value: "type", sortable: true, type: columnTypes.ALPHA },
      { label: "Created at", value: "created_at", sortable: true, type: columnTypes.ALPHA },
      { label: "Content", value: "content", sortable: true, type: columnTypes.ALPHA },
    ],
    defaultFilters: [
      {
        column: { label: "Level", value: "level", sortable: true, type: columnTypes.ALPHA },
        sorting: { label: "Off", value: sortOrders.OFF },
        filterEnabled: { label: "Off", value: toggleTypes.OFF },
      },
    ],
  },
  [viewKeys.PROJECTS]: {
    columns: [
      { label: "User e-mail", value: "account.email", type: columnTypes.ALPHA, sortable: false },
      { label: "Domain", value: "domain", type: columnTypes.ALPHA, sortable: true },
      { label: "Keywords count", value: "keywords_count", type: columnTypes.NUMERIC, sortable: true },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    defaultFilters: [
      {
        column: { label: "User e-mail", value: "account.email", type: columnTypes.ALPHA, sortable: false },
        sorting: { label: "Off", value: sortOrders.OFF },
        filterEnabled: { label: "Off", value: toggleTypes.OFF },
      },
    ],
  },
  [viewKeys.USERS]: {
    columns: [
      { label: "E-mail", value: "email", type: columnTypes.ALPHA, sortable: true },
      { label: "Plan", value: "plan_id", type: columnTypes.ALPHA, sortable: true },
      { label: "Payment status", value: "payment_status", type: columnTypes.ALPHA, sortable: true },
      { label: "Active until", value: "active_until", type: columnTypes.ALPHA, sortable: true },
      { label: "Last active", value: "last_active", type: columnTypes.ALPHA, sortable: true },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    defaultFilters: [
      {
        column: { label: "E-mail", value: "email", type: columnTypes.ALPHA, sortable: true },
        sorting: { label: "Off", value: sortOrders.OFF },
        filterEnabled: { label: "Off", value: toggleTypes.OFF },
      },
    ],
  },
};
