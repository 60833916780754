import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { useModal, useTabs } from "utils/hooks";
import { modalKeys, tabKeys, viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";
import { theme } from "utils/theme";

import { AppTemplate } from "components/templates";
import {
  DomainIndicator,
  JsonDisplay,
  LoadableValue,
  SummaryGrid,
  SummaryValue,
  TopPanel,
  Drawer,
  ConfirmationModal,
} from "components/shared";
import { Box } from "components/utils";
import { Button } from "components/forms";
import { Message } from "components/utils/Typography";

import {
  ProjectDetailsCompetitionTab,
  ProjectDetailsInfoTab,
  ProjectDetailsKeywordsTab,
  ProjectDetailsReportLogsTab,
  ProjectDetailsResourcesTab,
  ProjectDetailsSettingsTab,
} from "Projects/tabs";
import { getProject, projectsSelector } from "Projects/redux/projectsSlice";
import { useOnDemandSEOAnalysisMutation, useOnDemandSERPAnalysisMutation } from "api/services/project";
import toast, { toastIds } from "utils/toast";
import styled from "styled-components/macro";
import { Plus } from "components/icons";

function ProjectDetailsPage() {
  const route = getRouteByKey({ viewKey: viewKeys.PROJECT_DETAILS });
  const { tabs } = route;

  const [serpAnalysisModalOpen, setSerpAnalysisModalOpen] = useState(false);
  const [seoAnalysisModalOpen, setSeoAnalysisModalOpen] = useState(false);

  const dispatch = useDispatch();

  const { id } = useParams();

  const { selectedTab, goToTab } = useTabs({ tabs });

  const { entities: projectsEntities, error: projectError } = useSelector(projectsSelector);

  const project = projectsEntities?.[id];

  const [onDemandSERPAnalysis] = useOnDemandSERPAnalysisMutation();
  const [onDemandSEOAnalysis] = useOnDemandSEOAnalysisMutation();

  const launchOnDemandSERPAnalysis = async () => {
    try {
      await onDemandSERPAnalysis({ uid: id }).unwrap();
      toast.success("Serp report added successfully.", {
        toastId: toastIds.SUCCESS_ON_DEMAND_SERP_REPORT,
      });
    } catch (error) {
      toast.error("An error occurred while adding serp report. Please try again.", {
        toastId: toastIds.ERROR_ON_DEMAND_SERP_REPORT,
      });
    }
    setSerpAnalysisModalOpen(false);
  };

  const launchOnDemandSEOAnalysis = async () => {
    try {
      await onDemandSEOAnalysis({ uid: id }).unwrap();
      toast.success("Seo report added successfully.", {
        toastId: toastIds.SUCCESS_ON_DEMAND_SEO_REPORT,
      });
    } catch (error) {
      toast.error("An error occurred while adding seo report. Please try again.", {
        toastId: toastIds.ERROR_ON_DEMAND_SEO_REPORT,
      });
    }
    setSeoAnalysisModalOpen(false);
  };

  useEffect(() => {
    dispatch(getProject({ id }));
  }, [dispatch]);

  const TopPanelComponent = () => (
    <TopPanel
      title={route?.label}
      leftSection={
        <Box>
          <LoadableValue
            spinnerProps={{
              color: theme.colors.PRIMARY,
              scale: "0.105",
              alignLeft: true,
            }}
          >
            {project && (
              <DomainIndicator domain={project?.domain} faviconUrl={project?.current_seo_report?.favicon_base64} />
            )}
          </LoadableValue>
        </Box>
      }
      rightSection={
        <Box>
          <SummaryGrid
            items={[
              <SummaryValue value={project?.keywords_count} caption="Keywords count" />,
              <SummaryValue value={project?.competitors?.length} caption="Competitors count" />,
            ]}
          />
        </Box>
      }
    />
  );

  const { isOpen: openedProjectDetailsJsonId, setOpen: setOpenedProjectDetailsJsonId } = useModal();

  const OptionsComponent = useCallback(
    () => (
      <>
        <ButtonWrapper>
          <Button
            variant={Button.Variants.PRIMARY}
            onClick={() => {
              setSerpAnalysisModalOpen(true);
            }}
          >
            SERP analysis
          </Button>
          <Button
            variant={Button.Variants.PRIMARY}
            onClick={() => {
              setSeoAnalysisModalOpen(true);
            }}
          >
            On-site analysis
          </Button>
          <Button
            variant={Button.Variants.SECONDARY}
            onClick={() => {
              setOpenedProjectDetailsJsonId(modalKeys.UI_ID_PROJECT_DETAILS_JSON_MODAL);
            }}
          >
            JSON
          </Button>
        </ButtonWrapper>
        <Drawer
          isOpen={openedProjectDetailsJsonId === modalKeys.UI_ID_PROJECT_DETAILS_JSON_MODAL}
          onRequestClose={() => {
            setOpenedProjectDetailsJsonId(null);
          }}
        >
          <JsonDisplay json={project} />
        </Drawer>
      </>
    ),
    [openedProjectDetailsJsonId, project],
  );

  return (
    <AppTemplate
      route={route}
      tabNavigation={{
        tabs,
        goToTab,
        selectedTab,
      }}
      TopPanelComponent={!projectError && TopPanelComponent}
      OptionsComponent={!projectError && OptionsComponent}
    >
      {projectError ? (
        <Message>{projectError}</Message>
      ) : (
        <>
          {selectedTab?.key === tabKeys.PROJECT_DETAILS_INFO && <ProjectDetailsInfoTab project={project} />}
          {selectedTab?.key === tabKeys.PROJECT_DETAILS_COMPETITION && (
            <ProjectDetailsCompetitionTab project={project} />
          )}
          {selectedTab?.key === tabKeys.PROJECT_DETAILS_KEYWORDS && <ProjectDetailsKeywordsTab project={project} />}
          {selectedTab?.key === tabKeys.PROJECT_DETAILS_REPORT_LOGS && (
            <ProjectDetailsReportLogsTab project={project} />
          )}
          {selectedTab?.key === tabKeys.PROJECT_DETAILS_RESOURCES && <ProjectDetailsResourcesTab />}
          {selectedTab?.key === tabKeys.PROJECT_DETAILS_SETTINGS && <ProjectDetailsSettingsTab project={project} />}
        </>
      )}
      <ConfirmationModal
        icon={<Plus width="10px" height="10px" color={theme.colors.WHITE} />}
        width="350px"
        title="On Demand"
        description="Are you sure you want to run SERP analysis?"
        id={modalKeys.UI_ID_ON_DEMAND_SERP_REPORT_MODAL}
        isOpen={serpAnalysisModalOpen}
        onConfirm={launchOnDemandSERPAnalysis}
        onCancel={() => {
          setSerpAnalysisModalOpen(false);
        }}
      />
      <ConfirmationModal
        icon={<Plus width="10px" height="10px" color={theme.colors.WHITE} />}
        width="350px"
        title="On Demand"
        description="Are you sure you want to run On-Site analysis?"
        id={modalKeys.UI_ID_ON_DEMAND_SEO_REPORT_MODAL}
        isOpen={seoAnalysisModalOpen}
        onConfirm={launchOnDemandSEOAnalysis}
        onCancel={() => {
          setSeoAnalysisModalOpen(false);
        }}
      />
    </AppTemplate>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default ProjectDetailsPage;
