import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg
      id="sort_alpha_asc_icon_Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 11.355 12.83"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.sort_alpha_asc_icon_st1{fill:${color}}`}</style>
      <path
        d="M3.374 4.008h-1.98l-.351.994H0L1.82.116h1.127L4.76 5.002H3.717l-.343-.994zm-.266-.749L2.38 1.173 1.652 3.26h1.456z"
        fill={color}
      />
      <path
        className="sort_alpha_asc_icon_st1"
        d="M8.759 12.533c.107.118.236.17.42.18a.55.55 0 00.41-.198l1.64-1.978a.55.55 0 10-.847-.701l-.654.79.019-5.033a.565.565 0 00-.55-.552.55.55 0 00-.55.548l-.02 5.16-.801-.886a.551.551 0 00-.816.739l1.749 1.931zM4.017 12.634H.3v-.687l2.395-3.435H.363v-.875h3.589v.684l-2.393 3.438h2.458v.875z"
      />
    </svg>
  );
}

export default SvgComponent;
