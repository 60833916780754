import * as React from "react";

function SvgComponent({ color = "#8695b1", ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...props}>
      <path fill={color} d="M10 4H6V0H4v4H0v2h4v4h2V6h4z" />
    </svg>
  );
}

export default SvgComponent;
