import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="calendar_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 13.704 14.65"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".calendar_icon_st0{fill:#4a4e59}"}</style>
      <path
        className="calendar_icon_st0"
        d="M11.054.945h-.743V0h-1v.945H8.243V0h-1v.945H6.176V0h-1v.945H4.108V0h-1v.945H2.65A2.653 2.653 0 000 3.595V12a2.653 2.653 0 002.65 2.65h8.404a2.653 2.653 0 002.65-2.65V3.595a2.653 2.653 0 00-2.65-2.65zm-8.404 1.3h.458v.856h1v-.856h1.068v.856h1v-.856h1.067v.856h1v-.856h1.068v.856h1v-.856h.743c.745 0 1.35.606 1.35 1.35v.826H1.3v-.826c0-.744.605-1.35 1.35-1.35zm8.404 11.105H2.65c-.745 0-1.35-.605-1.35-1.35V5.421h11.104V12c0 .745-.605 1.35-1.35 1.35z"
      />
      <path
        className="calendar_icon_st0"
        d="M9.481 7.256h-1v.57H5.346v-.57h-1v.57h-.921v1h.921v1.067h-.921v1h.921v.717h1v-.717h3.135v.717h1v-.717h.854v-1h-.854V8.826h.854v-1h-.854v-.57zm-1 2.637H5.346V8.826h3.135v1.067z"
      />
    </svg>
  );
}

export default SvgComponent;
