import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="upload_image_icon__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 34.016 34.016"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".upload_image_icon__st1{fill:#2783b7}"}</style>
      <path
        d="M26.755 34.016H7.26A7.26 7.26 0 010 26.755V7.26A7.26 7.26 0 017.26 0h19.495a7.26 7.26 0 017.26 7.26v19.495a7.26 7.26 0 01-7.26 7.26z"
        fill="#e0ecf6"
      />
      <circle className="upload_image_icon__st1" cx={23.64} cy={10.93} r={2.339} />
      <path
        className="upload_image_icon__st1"
        d="M25.37 24.69l-1.752-3.01-1.867-3.21a.879.879 0 00-1.547-.003l-1.21 2.066-.317-.545-2.855-4.91a.879.879 0 00-1.547-.002l-2.872 4.9-2.755 4.7c-.387.661.05 1.524.771 1.526l5.611.009 5.61.01c.018 0 .03-.014.048-.015h.277l3.628.006c.722.002 1.161-.86.776-1.522z"
      />
    </svg>
  );
}

export default SvgComponent;
