import * as React from "react";

const SvgComponent = ({ color = "#E8E8E9", ...props }) => (
  <svg width={28} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        d="M10.99.447 25.955.395c.845-.003 1.536.723 1.54 1.623l.11 31.851c.004.9-.682 1.631-1.527 1.634l-24.065.085c-.847.003-1.534-.723-1.537-1.623L.395 10.767"
        fill="#fff"
      />
      <path
        d="M2.01 35.983c-1.06 0-1.924-.904-1.928-2.017L0 10.77l.789-.003.082 23.197c.002.68.513 1.23 1.139 1.23h.003l24.063-.084c.628-.003 1.137-.558 1.135-1.238L27.099 2.02c0-.335-.124-.648-.345-.881-.216-.227-.509-.368-.798-.35L10.992.841 10.99.052 25.953 0c.555.01 1.007.21 1.373.596.36.38.56.884.562 1.42L28 33.868c.004 1.115-.858 2.026-1.92 2.03l-24.065.085H2.01Z"
        fill={color}
      />
      <path d="m10.99.447.022 8.515a1.816 1.816 0 0 1-1.808 1.824l-8.81.03L10.99.447Z" fill={color} />
      <path
        d="M19.89 16.737c-.003-.606-.313-1.093-.696-1.092L.881 15.71c-.056 0-.097.03-.143.05v10.157c.053.03.112.05.18.05l18.312-.064c.383-.001.69-.49.688-1.097l-.029-8.068Z"
        fill="#020202"
      />
      <path
        d="M4.974 17.598h.823v4.268c0 1.694-.832 2.214-1.93 2.214-.303 0-.672-.066-.88-.151l.123-.672c.17.066.416.122.69.122.738 0 1.174-.33 1.174-1.58v-4.201ZM7.17 17.674a9.55 9.55 0 0 1 1.58-.123c.815 0 1.41.19 1.79.53.35.303.558.766.558 1.334 0 .577-.17 1.031-.493 1.363-.434.463-1.145.7-1.948.7-.247 0-.474-.01-.663-.057v2.555H7.17v-6.302Zm.824 3.075c.18.047.407.066.681.066.994 0 1.6-.482 1.6-1.362 0-.842-.597-1.25-1.505-1.25-.36 0-.634.029-.776.067v2.48ZM17.063 23.692c-.369.132-1.098.35-1.96.35-.964 0-1.76-.245-2.383-.842-.55-.53-.89-1.381-.89-2.375.01-1.902 1.315-3.294 3.454-3.294.738 0 1.315.161 1.59.294l-.2.672c-.34-.151-.766-.274-1.41-.274-1.551 0-2.563.965-2.563 2.564 0 1.618.974 2.574 2.46 2.574.54 0 .908-.076 1.098-.17v-1.903h-1.297v-.662h2.101v3.066Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgComponent;
