/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useModal } from "utils/hooks";
import toast, { toastIds } from "utils/toast";
import { columnTypes, modalKeys } from "utils/constants";
import { getRegionLabel, getUniqueKeywordKey, parseKeyword, isSameKeyword } from "utils/formatters";

import {
  Table,
  KeywordValue,
  NullableValue,
  MenuDropdown,
  OptionsBar,
  ConfirmationModal,
  ModalWithForm,
  DeviceValue,
} from "components/shared";
import { Button } from "components/forms";
import { Remove } from "components/icons";

import { CreateKeywordForm } from "Keywords/components";
import { getProject, updateProject } from "Projects/redux/projectsSlice";

function ProjectDetailsKeywordsTab({ project }) {
  const dispatch = useDispatch();

  const [keywordToRemoveId, setKeywordToRemoveId] = useState(null);
  const [keywordToEditId, setKeywordToEditId] = useState(null);

  const { keywords } = project;

  const headers = useMemo(
    () => [
      { label: "Keyword", value: "keyword", type: columnTypes.ALPHA, sortable: false },
      { label: "Country", value: "country", type: columnTypes.ALPHA, sortable: false },
      { label: "Location", value: "location", type: columnTypes.ALPHA, sortable: false },
      { label: "Language", value: "language", type: columnTypes.ALPHA, sortable: false },
      { label: "Device", value: "is_mobile", type: columnTypes.ALPHA, sortable: false },
    ],
    [],
  );

  const { isOpen: openedCreateKeywordId, setOpen: setOpenedCreateKeywordId } = useModal();

  const rows = useMemo(
    () =>
      keywords?.map((keyword) => {
        return {
          options: {
            key: getUniqueKeywordKey({ keyword }),
          },
          columns: {
            keyword: (
              <KeywordValue
                isoCode={keyword?.country_iso_code}
                location={keyword?.location}
                isMobile={keyword?.is_mobile}
                keyword={keyword?.keyword}
                hideIcons
              />
            ),
            country: <NullableValue>{keyword?.country}</NullableValue>,
            location: <NullableValue>{getRegionLabel({ region: keyword?.location })}</NullableValue>,
            language: <NullableValue>{keyword?.language}</NullableValue>,
            is_mobile: <DeviceValue isMobile={keyword?.is_mobile} />,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "Edit",
                      value: "edit",
                      onClick: () => {
                        setKeywordToEditId(keyword);
                        setOpenedCreateKeywordId(modalKeys.UI_ID_CREATE_KEYWORD_MODAL);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setKeywordToRemoveId(keyword);
                      },
                    },
                  ]}
                />
              </>
            ),
          },
        };
      }),
    [keywords],
  );

  const RightSideComponent = () => (
    <Button
      onClick={() => {
        setKeywordToEditId(null);
        setOpenedCreateKeywordId(modalKeys.UI_ID_CREATE_KEYWORD_MODAL);
      }}
    >
      Add new keyword
    </Button>
  );

  const handleKeywordCreate = async (formValues) => {
    try {
      const allKeywords = project?.keywords;
      const keywordToAdd = parseKeyword({ formValues });
      const keywordsUpdated = [...allKeywords, keywordToAdd];

      const rs = await dispatch(
        updateProject({
          id: project?.uid,
          data: {
            keywords: keywordsUpdated,
          },
        }),
      );

      if (rs?.payload) {
        if (rs?.payload?.error) {
          toast.error("An error occurred while adding keywords. Please try again shortly.", {
            toastId: toastIds.ERROR_UPDATE_PROJECT,
          });
        } else {
          setOpenedCreateKeywordId(null);

          toast.success("Keyword list updated successfully", {
            toastId: toastIds.SUCCESS_UPDATE_PROJECT,
          });

          dispatch(getProject({ id: project?.uid }));
        }
      }
    } catch (error) {
      toast.error("An error occurred while adding keywords. Please try again shortly.", {
        toastId: toastIds.ERROR_UPDATE_PROJECT,
      });
    }
  };

  const handleKeywordRemove = async () => {
    try {
      const allKeywords = project?.keywords;
      const keywordToRemove = keywordToRemoveId;

      const keywordsUpdated = allKeywords?.filter(
        (keyword) => !isSameKeyword({ keyword1: keyword, keyword2: keywordToRemove }),
      );

      const rs = await dispatch(
        updateProject({
          id: project?.uid,
          data: {
            keywords: keywordsUpdated,
          },
        }),
      );

      if (rs?.payload) {
        if (rs?.payload?.error) {
          toast.error("An error occurred while removing keywords. Please try again shortly.", {
            toastId: toastIds.ERROR_UPDATE_PROJECT,
          });
        } else {
          setOpenedCreateKeywordId(null);
          setKeywordToRemoveId(null);

          toast.success("Keyword list updated successfully", {
            toastId: toastIds.SUCCESS_UPDATE_PROJECT,
          });

          dispatch(getProject({ id: project?.uid }));
        }
      }
    } catch (error) {
      toast.error("An error occurred while removing keywords. Please try again shortly.", {
        toastId: toastIds.ERROR_UPDATE_PROJECT,
      });
    }
  };

  const handleKeywordEdit = async (formValues) => {
    try {
      const allKeywords = project?.keywords;
      const keywordToUpdateIndex = allKeywords?.findIndex((keyword) =>
        isSameKeyword({ keyword1: keyword, keyword2: keywordToEditId }),
      );

      if (keywordToUpdateIndex === -1) {
        return;
      }

      const keywordsUpdated = [...allKeywords];
      keywordsUpdated[keywordToUpdateIndex] = parseKeyword({ formValues });

      const rs = await dispatch(
        updateProject({
          id: project?.uid,
          data: {
            keywords: keywordsUpdated,
          },
        }),
      );

      if (rs?.payload) {
        if (rs?.payload?.error) {
          toast.error("An error occurred while updating keyword. Please try again shortly.", {
            toastId: toastIds.ERROR_UPDATE_PROJECT,
          });
        } else {
          setOpenedCreateKeywordId(null);

          toast.success("Keyword list updated successfully", {
            toastId: toastIds.SUCCESS_UPDATE_PROJECT,
          });

          dispatch(getProject({ id: project?.uid }));
        }
      }
    } catch (error) {
      toast.error("An error occurred while updating keyword. Please try again shortly.", {
        toastId: toastIds.ERROR_UPDATE_PROJECT,
      });
    }
  };

  const keywordFormTitle = keywordToEditId ? "Edit keyword" : "Add new keyword";

  return (
    <>
      <OptionsBar RightSideComponent={RightSideComponent} />
      <Table isLoading={!keywords} headers={headers} rows={rows} emptyStateText="No keywords available" />
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected keyword?"
        id={modalKeys.UI_ID_REMOVE_KEYWORD_MODAL}
        isOpen={keywordToRemoveId}
        onConfirm={handleKeywordRemove}
        onCancel={() => {
          setKeywordToRemoveId(null);
        }}
      />
      <ModalWithForm
        titleAlign="left"
        width="507px"
        title={keywordFormTitle}
        id={modalKeys.UI_ID_CREATE_KEYWORD_MODAL}
        isOpen={openedCreateKeywordId === modalKeys.UI_ID_CREATE_KEYWORD_MODAL}
        onRequestClose={() => {
          setOpenedCreateKeywordId(null);
          setKeywordToEditId(null);
        }}
      >
        <CreateKeywordForm
          onConfirm={keywordToEditId ? handleKeywordEdit : handleKeywordCreate}
          defaultKeyword={keywordToEditId}
          onCancel={() => {
            setOpenedCreateKeywordId(null);
            setKeywordToEditId(null);
          }}
        />
      </ModalWithForm>
    </>
  );
}

export default ProjectDetailsKeywordsTab;
