import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { columnTypes, modalKeys, viewKeys } from "utils/constants";
import { getRouteByKey, routes } from "utils/routes";
import { useModal, usePageLoading, useQueryParams } from "utils/hooks";
import { getPaginationParams, getMetaParams } from "utils/pagination";
import toast, { toastIds } from "utils/toast";
import { parseRoute } from "utils/formatters";

import {
  BooleanValue,
  ConfirmationModal,
  DateTimeValue,
  Drawer,
  JsonDisplay,
  LinkValue,
  MenuDropdown,
  NullableValue,
  SummaryValue,
  Table,
  TopPanel,
} from "components/shared";
import { Box } from "components/utils";
import { Plus, Remove } from "components/icons";
import { AppTemplate } from "components/templates";

import { pendingActionsSelector } from "App/redux/appSlice";
import {
  actionTypes,
  getSerpReports,
  serpReportsSelector,
  removeSerpReport,
  onDemandSerpReport,
  reparseSerpReport,
} from "Serp/redux/serpSlice";
import { theme } from "../../utils/theme";

function SerpReportsPage() {
  const { initialLoading } = usePageLoading();

  const route = getRouteByKey({ viewKey: viewKeys.SERP_REPORTS });

  const dispatch = useDispatch();
  const history = useHistory();

  const { params } = useQueryParams();

  const { ids: serpReportIds, entities: serpReportEntities, meta: serpReportsMeta } = useSelector(serpReportsSelector);
  const loadingSerpReports = useSelector((state) =>
    pendingActionsSelector({ state, actions: actionTypes.GET_SERP_REPORTS }),
  );

  useEffect(() => {
    dispatch(
      getSerpReports({
        params: {
          ...getPaginationParams({ params }),
        },
      }),
    );
  }, [dispatch, params]);

  const TopPanelComponent = useCallback(
    () => (
      <TopPanel
        title={route?.label}
        leftSection={<Box />}
        rightSection={
          <Box>
            <SummaryValue value={serpReportsMeta?.all} caption="Serp reports count" />
          </Box>
        }
      />
    ),
    [serpReportsMeta, params],
  );

  const headers = useMemo(
    () => [
      { label: "E-mail", value: "account.email", type: columnTypes.ALPHA, sortable: false },
      { label: "Domain", value: "project_domain", type: columnTypes.ALPHA, sortable: true },
      { label: "Keywords count", value: "keywords_count", type: columnTypes.NUMERIC, sortable: true },
      { label: "Is started", value: "is_started", type: columnTypes.ALPHA, sortable: true },
      { label: "Is done", value: "is_done", type: columnTypes.ALPHA, sortable: true },
      { label: "Is failure", value: "is_failure", type: columnTypes.ALPHA, sortable: true },
      { label: "Error", value: "failure_reason", type: columnTypes.ALPHA, sortable: true },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    [],
  );

  const [reportToReparse, setReportToReparse] = useState(null);
  const [reportToRemoveId, setReportToRemoveId] = useState(null);
  const [projectIdToOnDemand, setProjectIdToOnDemand] = useState(null);
  const { isOpen: openedJsonId, setOpen: setOpenedJsonId } = useModal();

  const rows = useMemo(
    () =>
      serpReportIds?.map((id) => {
        const report = serpReportEntities?.[id];

        return {
          options: {
            key: id,
            onRowClick: () => {
              setOpenedJsonId(id);
            },
          },
          columns: {
            email: (
              <LinkValue
                onClick={() => {
                  history.push(
                    parseRoute({
                      route: routes.USER_DETAILS,
                      params: {
                        id: report?.account?.uid,
                      },
                    }),
                  );
                }}
              >
                {report?.account?.email}
              </LinkValue>
            ),
            domain: <NullableValue>{report?.project_domain}</NullableValue>,
            keywords_count: <NullableValue>{report?.keywords_count}</NullableValue>,
            is_started: <BooleanValue>{report?.is_started}</BooleanValue>,
            is_done: <BooleanValue>{report?.is_done}</BooleanValue>,
            is_failure: <BooleanValue>{report?.is_failure}</BooleanValue>,
            failure_reason: <NullableValue>{report?.failure_reason}</NullableValue>,
            created_at: <DateTimeValue date={report?.created_at} />,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "JSON",
                      value: "json",
                      onClick: () => {
                        setOpenedJsonId(id);
                      },
                    },
                    {
                      label: "Analyse again",
                      value: "on-demand",
                      onClick: () => {
                        setProjectIdToOnDemand(report?.project_uid);
                      },
                    },
                    {
                      label: "Reparse",
                      value: "reparse",
                      onClick: () => {
                        setReportToReparse(report?.uid);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setReportToRemoveId(id);
                      },
                    },
                  ]}
                />
                <Drawer
                  isOpen={openedJsonId === id}
                  onRequestClose={() => {
                    setOpenedJsonId(null);
                  }}
                >
                  <JsonDisplay json={report} />
                </Drawer>
              </>
            ),
          },
        };
      }),
    [serpReportIds, openedJsonId],
  );

  const handleReportRemove = async () => {
    if (reportToRemoveId) {
      try {
        const rs = await dispatch(removeSerpReport({ id: reportToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Serp report removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_SERP_REPORT,
          });

          dispatch(
            getSerpReports({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error("An error occurred while removing serp report. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_SERP_REPORT,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing serp report. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_SERP_REPORT,
        });
      }
    }

    setReportToRemoveId(null);
  };

  const handleReportReparse = async () => {
    if (reportToReparse) {
      try {
        const rs = await dispatch(reparseSerpReport({ id: reportToReparse }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Serp report reparsed successfully.", {
            toastId: toastIds.ERROR_REPARSE_SERP_REPORT,
          });

          dispatch(
            getSerpReports({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error("An error occurred while reparsing serp report. Please try again.", {
            toastId: toastIds.ERROR_REPARSE_SERP_REPORT,
          });
        }
      } catch (error) {
        toast.error("An error occurred while reparsing serp report. Please try again.", {
          toastId: toastIds.ERROR_REPARSE_SERP_REPORT,
        });
      }
    }

    setReportToReparse(null);
  };

  const handleReportOnDemand = async () => {
    if (projectIdToOnDemand) {
      try {
        const rs = await dispatch(onDemandSerpReport({ project_id: projectIdToOnDemand }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Serp report added successfully.", {
            toastId: toastIds.SUCCESS_ON_DEMAND_SERP_REPORT,
          });

          dispatch(
            getSerpReports({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error("An error occurred while adding serp report. Please try again.", {
            toastId: toastIds.ERROR_ON_DEMAND_SERP_REPORT,
          });
        }
      } catch (error) {
        toast.error("An error occurred while adding serp report. Please try again.", {
          toastId: toastIds.ERROR_ON_DEMAND_SERP_REPORT,
        });
      }
    }

    setProjectIdToOnDemand(null);
  };

  return (
    <>
      <AppTemplate route={route} TopPanelComponent={TopPanelComponent}>
        <Table
          isLoading={!serpReportIds}
          isRefetchLoading={loadingSerpReports || initialLoading}
          headers={headers}
          rows={rows}
          meta={getMetaParams({ meta: serpReportsMeta, queryParams: params })}
          emptyStateText="No reports available"
        />
      </AppTemplate>
      <ConfirmationModal
        icon={<Plus width="32px" height="32px" />}
        width="300px"
        title="Reparse"
        description="Are you sure you want to reparse selected report?"
        id={modalKeys.UI_ID_REPARSE_REPORT_MODAL}
        isOpen={reportToReparse}
        onConfirm={handleReportReparse}
        onCancel={() => {
          setReportToReparse(null);
        }}
      />
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected report?"
        id={modalKeys.UI_ID_REMOVE_REPORT_MODAL}
        isOpen={reportToRemoveId}
        onConfirm={handleReportRemove}
        onCancel={() => {
          setReportToRemoveId(null);
        }}
      />
      <ConfirmationModal
        icon={<Plus width="10px" height="10px" color={theme.colors.WHITE} />}
        width="300px"
        title="On Demand"
        description="Are you sure you want to analyse again report?"
        id={modalKeys.UI_ID_ON_DEMAND_REPORT_MODAL}
        isOpen={projectIdToOnDemand}
        onConfirm={handleReportOnDemand}
        onCancel={() => {
          setProjectIdToOnDemand(null);
        }}
      />
    </>
  );
}

export default SerpReportsPage;
