import ReactModal from "react-modal";

import { theme } from "utils/theme";

import { CloseButton } from "components/shared";

const drawerContentStyles = {
  position: "relative",
  backgroundColor: theme.colors.WHITE,
  width: "100%",
  height: "100%",
  maxWidth: "530px",
  marginLeft: "auto",
  borderLeft: `1px solid ${theme.colors.BLACK}11`,
  padding: "30px 20px",
  overflowY: "auto",
  borderRadius: 0,
  top: 0,
  inset: 0,
};

const drawerStyles = {
  overlay: {
    backgroundColor: theme.colors.MODAL_BACKDROP,
    overflow: "auto",
    zIndex: 1000,
  },
  content: drawerContentStyles,
};

function Drawer({ isOpen, onRequestClose, children, id = "drawer" }) {
  return (
    <ReactModal
      id={id}
      appElement={document.getElementById("root")}
      style={drawerStyles}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {children}
      <CloseButton onClick={onRequestClose} />
    </ReactModal>
  );
}

export default Drawer;
