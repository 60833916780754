import { format, parseISO } from "date-fns";
import styled from "styled-components/macro";

import { dateFormats } from "utils/constants";
import { theme } from "utils/theme";

import { Box } from "components/utils";

function DateTimeValue({ date, noTime }) {
  if (!date) {
    return "-";
  }

  const dateObj = parseISO(date);

  if (!dateObj) {
    return "-";
  }

  return (
    <Box flex alignCenter>
      {!noTime ? (
        <>
          <TimeValue>{format(dateObj, dateFormats.HOURS_MINUTES)}</TimeValue>
          <span>&nbsp;&nbsp;</span>
        </>
      ) : null}
      <span>{format(dateObj, dateFormats.YYYY_MM_DD)}</span>
    </Box>
  );
}

const TimeValue = styled.div`
  width: 32px;
  display: block;
  color: ${theme.colors.TIME_VALUE_TEXT};
`;

export default DateTimeValue;
