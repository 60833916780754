import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Spinner } from "components/shared";

import { appSelector } from "App/redux/appSlice";

function AppLoadingIndicator() {
  const { isLoading } = useSelector(appSelector);

  return isLoading ? (
    <Wrapper>
      <Spinner scale={0.2} color={theme.colors.PRIMARY} />
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: transparent;
  z-index: 100000;
  transition: all 0.1s;
`;

export default AppLoadingIndicator;
