import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods } from "utils/constants";

const SLICE_KEY = "seoReports";

const seoReportsSchema = new schema.Entity("seoReports", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_SEO_REPORTS: "seoReports/getSeoReports",
  REMOVE_SEO_REPORT: "seoReports/removeSeoReport",
};

const endpoints = {
  GET_SEO_REPORTS: `/v1/seo-reports`,
  REMOVE_SEO_REPORT: ({ id }) => `/v1/seo-reports/${id}`,
};

export const seoReportsSelector = createSelector(
  ({ seoReports }) => seoReports,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const getSeoReports = createAsyncThunk(actionTypes.GET_SEO_REPORTS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_SEO_REPORTS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_SEO_REPORTS,
    thunkApi,
    params,
    schema: { seoReports: [seoReportsSchema] },
  }),
);

export const removeSeoReport = createAsyncThunk(actionTypes.REMOVE_SEO_REPORT, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.REMOVE_SEO_REPORT,
    method: httpMethods.DELETE,
    endpoint: endpoints.REMOVE_SEO_REPORT({ id }),
    thunkApi,
  }),
);

const seoReportsSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getSeoReports.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.seoReports;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.seoReports;
    });
    builder.addCase(getSeoReports.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = seoReportsSlice;

export default reducer;
