import * as React from "react";

function SvgComponent({ color = "#24262b", ...props }) {
  return (
    <svg
      id="search_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 18.56 20.001"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.search_icon_st0{fill:${color}}`}</style>
      <path
        className="search_icon_st0"
        d="M18.317 18.349l-3.346-3.886c2.922-3.176 3.07-8.12.186-11.466A8.566 8.566 0 009.262.025a8.567 8.567 0 00-6.27 2.068C-.61 5.2-1.017 10.656 2.087 14.258a8.608 8.608 0 006.542 2.992c1.68 0 3.364-.493 4.827-1.483l3.347 3.886a.998.998 0 001.41.106 1 1 0 00.105-1.41zM3.602 12.953c-2.385-2.767-2.072-6.96.696-9.345a6.575 6.575 0 014.816-1.589 6.58 6.58 0 014.528 2.283 6.634 6.634 0 01-.693 9.345c-2.77 2.385-6.963 2.074-9.347-.694z"
      />
      <path
        className="search_icon_st0"
        d="M8.615 3.924a4.712 4.712 0 00-4.707 4.707.5.5 0 101 0 3.71 3.71 0 013.707-3.707.5.5 0 100-1z"
      />
    </svg>
  );
}

export default SvgComponent;
