import styled, { css } from "styled-components/macro";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { theme } from "utils/theme";
import { columnTypes, toggleTypes, UI_ID_ADVANCED_FILTERS_FORM } from "utils/constants";
import { sortingOptionsAlpha, sortingOptionsNumeric, toggleOptions } from "utils/options";
import { filterValueTypes } from "utils/filters";

import { Button, ButtonGroup, OptionButton, SelectInput } from "components/forms";
import { Close, Plus } from "components/icons";

import { filtersSelector, setFilter } from "Filters/redux/filtersSlice";

function AdvancedFiltersForm({ onCancel, onConfirm, filterConfig, viewKey }) {
  const dispatch = useDispatch();

  const { filters: currentFilters } = useSelector((state) => filtersSelector({ state, viewKey }));

  const filterColumns = filterConfig?.[viewKey]?.columns;
  const defaultFilters = filterConfig?.[viewKey]?.defaultFilters;

  const { control, handleSubmit, watch, register, setValue, reset } = useForm({
    defaultValues: {
      filters: currentFilters || defaultFilters,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: UI_ID_ADVANCED_FILTERS_FORM,
  });

  const onReset = () => {
    reset({ defaultValues: defaultFilters });
    setValue("filters", defaultFilters, { shouldValidate: true });
  };

  return (
    <Wrapper>
      <div>
        <form
          onSubmit={handleSubmit((filters) => {
            dispatch(setFilter({ filters, viewKey }));

            onConfirm();
          })}
        >
          <Filters>
            {fields.map((field, index) => {
              const shouldShowPlusButton = fields.length === 1 || index === fields.length - 1;

              const fieldName = `filters[${index}]`;

              const values = watch();

              const f = values?.filters?.[index];

              const isFilterEnabled = f?.filterEnabled?.value === toggleTypes.ON;
              const sortingOptions =
                f?.column?.type === columnTypes.ALPHA ? sortingOptionsAlpha : sortingOptionsNumeric;

              const filterValueTypesOptions = filterValueTypes?.[f?.column?.type];

              const FilterValueComponent = filterValueTypesOptions?.component;
              const filterValueOptions = filterValueTypesOptions?.options;

              const filtersCount = fields.length;
              const showSeparator = filtersCount && index !== filtersCount - 1;

              const isSortable = f?.column?.sortable === true;

              return (
                <SingleFilterWrapper key={field.id} showSeparator={showSeparator}>
                  <SelectInput
                    big
                    width="100%"
                    control={control}
                    name={`${fieldName}.column`}
                    label="Column"
                    options={filterColumns}
                    value={values?.filters?.[index]?.column || filterColumns?.[0]}
                  />
                  <SelectInput
                    big
                    width="100%"
                    control={control}
                    name={`${fieldName}.sorting`}
                    label="Sorting"
                    options={sortingOptions}
                    value={values?.filters?.[index]?.sorting || sortingOptions?.[0]}
                    disabled={!isSortable}
                  />
                  <SelectInput
                    big
                    width="100%"
                    control={control}
                    name={`${fieldName}.filterEnabled`}
                    label="Filtering"
                    options={toggleOptions}
                    value={values?.filters?.[index]?.filterEnabled || toggleOptions?.[0]}
                  />
                  <OptionButtonWrapper>
                    <OptionButton
                      color="transparent"
                      icon={<Close width="10px" height="10px" />}
                      onClick={() => {
                        if (filtersCount === 1) {
                          onReset();
                          return;
                        }

                        remove(index);
                      }}
                    />
                    <PlusButton shouldShowPlusButton={shouldShowPlusButton}>
                      <OptionButton
                        hoverBackgroundColor={theme.colors.BUTTON_OPTION_HOVER}
                        hoverIconColor={theme.colors.WHITE}
                        icon={<Plus width="10px" height="10px" color={theme.colors.WHITE} />}
                        onClick={() => append({})}
                      />
                    </PlusButton>
                  </OptionButtonWrapper>
                  {isFilterEnabled ? (
                    <>
                      <SelectInput
                        big
                        width="100%"
                        control={control}
                        name={`${fieldName}.filterType`}
                        label="Type"
                        options={filterValueTypesOptions?.options}
                        value={values?.filters?.[index]?.filterType || filterValueOptions?.[0]}
                      />
                      <FilterValueField>
                        <FilterValueComponent
                          ref={register}
                          width="100%"
                          control={control}
                          name={`${fieldName}.filterValue`}
                          label="Value"
                          value={values?.filters?.[index]?.filterValue || ""}
                        />
                      </FilterValueField>
                    </>
                  ) : null}
                </SingleFilterWrapper>
              );
            })}
          </Filters>
          <ButtonGroup>
            <Button variant={Button.Variants.DANGER} onClick={onCancel}>
              Cancel
            </Button>
            <Button variant={Button.Variants.DANGER} onClick={onReset}>
              Reset
            </Button>
            <Button type="submit" variant={Button.Variants.SECONDARY}>
              Apply
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </Wrapper>
  );
}

const PlusButton = styled.div`
  visibility: ${({ shouldShowPlusButton }) => (shouldShowPlusButton ? "visible" : "hidden")};
`;

const FilterValueField = styled.div`
  grid-column: span 2;
`;

const OptionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 18px;
  margin-left: -10px;
`;

const Wrapper = styled.div``;

const Filters = styled.ul`
  margin-bottom: 40px;
`;

const SingleFilterWrapper = styled.li`
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  column-gap: 20px;
  align-items: center;
  border-bottom: 1px solid transparent;

  ${({ showSeparator }) =>
    showSeparator &&
    css`
      border-bottom: 1px solid ${theme.colors.ADVANCED_FILTERS_SEPARATOR};
      padding-bottom: 20px;
      margin-bottom: 20px;
    `};
`;

export default AdvancedFiltersForm;
