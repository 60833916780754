import { toast } from "react-toastify";

export const toastIds = {
  ERROR_FETCHING_ADMIN_DATA: "ERROR_FETCHING_ADMIN_DATA",
  //
  ERROR_CREATE_USER: "ERROR_CREATE_USER",
  ERROR_REMOVE_USER: "ERROR_REMOVE_USER",
  ERROR_UPDATE_USER: "ERROR_UPDATE_USER",
  ERROR_CREATE_PROJECT: "ERROR_CREATE_PROJECT",
  ERROR_REMOVE_PROJECT: "SUCCESS_REMOVE_PROJECT",
  ERROR_REMOVE_REPORT_LOG: "ERROR_REMOVE_REPORT_LOG",
  ERROR_REMOVE_INVOICE: "ERROR_REMOVE_INVOICE",
  ERROR_UPDATE_PROJECT: "ERROR_UPDATE_PROJECT",
  ERROR_DOWNLOADING_INVOICE: "ERROR_DOWNLOADING_INVOICE",
  ERROR_REMOVE_LIGHTHOUSE_REPORT: "ERROR_REMOVE_LIGHTHOUSE_REPORT",
  ERROR_REMOVE_SERP_REPORT: "ERROR_REMOVE_SERP_REPORT",
  ERROR_ON_DEMAND_SERP_REPORT: "ERROR_ON_DEMAND_SERP_REPORT",
  ERROR_REPARSE_SERP_REPORT: "ERROR_REPARSE_SERP_REPORT",
  ERROR_CREATE_AUTHOR: "ERROR_CREATE_AUTHOR",
  ERROR_REMOVE_AUTHOR: "ERROR_REMOVE_AUTHOR",
  ERROR_UPDATE_AUTHOR: "ERROR_UPDATE_AUTHOR",
  ERROR_CREATE_POST: "ERROR_CREATE_POST",
  ERROR_UPDATE_POST: "ERROR_UPDATE_POST",
  ERROR_REMOVE_POST: "ERROR_REMOVE_POST",
  ERROR_ON_DEMAND_SEO_REPORT: "ERROR_ON_DEMAND_SEO_REPORT",
  //
  SUCCESS_CREATE_USER: "SUCCESS_CREATE_USER",
  SUCCESS_REMOVE_USER: "SUCCESS_REMOVE_USER",
  SUCCESS_UPDATE_USER: "SUCCESS_UPDATE_USER",
  SUCCESS_CREATE_PROJECT: "SUCCESS_CREATE_PROJECT",
  SUCCESS_REMOVE_PROJECT: "SUCCESS_REMOVE_PROJECT",
  SUCCESS_REMOVE_REPORT_LOG: "SUCCESS_REMOVE_REPORT_LOG",
  SUCCESS_REMOVE_INVOICE: "SUCCESS_REMOVE_INVOICE",
  SUCCESS_DOWNLOADING_INVOICE: "SUCCESS_DOWNLOADING_INVOICE",
  SUCCESS_UPDATE_PROJECT: "SUCCESS_UPDATE_PROJECT",
  SUCCESS_REMOVE_LIGHTHOUSE_REPORT: "SUCCESS_REMOVE_LIGHTHOUSE_REPORT",
  SUCCESS_REMOVE_SERP_REPORT: "SUCCESS_REMOVE_SERP_REPORT",
  SUCCESS_CREATE_AUTHOR: "SUCCESS_CREATE_AUTHOR",
  SUCCESS_REMOVE_AUTHOR: "SUCCESS_REMOVE_AUTHOR",
  SUCCESS_UPDATE_AUTHOR: "SUCCESS_UPDATE_AUTHOR",
  SUCCESS_CREATE_POST: "SUCCESS_CREATE_POST",
  SUCCESS_UPDATE_POST: "SUCCESS_UPDATE_POST",
  SUCCESS_REMOVE_POST: "SUCCESS_REMOVE_POST",
  SUCCESS_ON_DEMAND_SEO_REPORT: "SUCCESS_ON_DEMAND_SEO_REPORT",
  SUCCESS_ON_DEMAND_SERP_REPORT: "SUCCESS_ON_DEMAND_SERP_REPORT",
  SUCCESS_UPDATE_PROMPT: "SUCCESS_UPDATE_PROMPT",
};

export default {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: "success",
    });
  },
  warning(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: "warning",
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: "error",
    });
  },
  info(msg, options = {}) {
    return toast.info(msg, {
      ...options,
      className: "info",
    });
  },
};
