import { useForm } from "react-hook-form";
import { useState } from "react";
import styled from "styled-components/macro";

import toast, { toastIds } from "utils/toast";

import { Button, ButtonGroup, TextInput } from "components/forms";
import { Box } from "components/utils";
import { Spinner } from "components/shared";
import { theme } from "utils/theme";
import { useUpdatePromptMutation } from "../../api/services/prompt";

const PromptForm = ({ prompt, isPromptLoading }) => {
  const [operationInProgress, setOperationInProgress] = useState(false);
  // image is loading
  const [isLoading, setLoading] = useState(false);

  const [updatePrompt] = useUpdatePromptMutation();


  const handleOperation = async ({ values }) => {
    setOperationInProgress(true);

    const promptToUpdate = {
      content: values?.content,
    };

    try {
      await updatePrompt({ uid: prompt?.uid, data: promptToUpdate }).unwrap();

      toast.success("Prompt updated successfully.", {
        toastId: toastIds.SUCCESS_UPDATE_POST,
      });
    } catch (error) {
      toast.error("An error occurred while updating prompt. Please try again.", {
        toastId: toastIds.ERROR_UPDATE_POST,
      });
    }

    setOperationInProgress(false);
  };

  const { register, handleSubmit, errors: formErrors, setError, clearErrors, control } = useForm();

  const fields = [
    {
      label: "Content",
      type: "textarea",
      name: "content",
      height: "1000px",
      fullWidth: true,
      defaultValue: prompt?.content,
      component: TextInput,
      validation: {
        required: "Content is required",
      },
    },
  ];

  return (
    <form onSubmit={handleSubmit((values) => handleOperation({ values }))}>
      <Box flex justifyRight margin="-40px 0 10px">
        <ButtonGroup>
          <Button
            type="submit"
            disabled={isLoading}
            loading={operationInProgress}
            variant={Button.Variants.ACTION}
          >
            Update
          </Button>
        </ButtonGroup>
      </Box>
      <Box flex position="relative">
        {isPromptLoading ? (
          <Disabler>
            <Spinner color={theme.colors.PRIMARY} />
          </Disabler>
        ) : (
          <>
            <FieldsWrapper>
              {fields.map(({ component: Component, validation, inputProps, ...fieldProps }) => (
                <Component
                  key={fieldProps?.name}
                  ref={register(validation)}
                  error={formErrors?.[fieldProps?.name]?.message}
                  {...inputProps}
                  {...fieldProps}
                />
              ))}
            </FieldsWrapper>
          </>
        )}
      </Box>
    </form>
  );
};

const FieldsWrapper = styled.div`
    margin-right: 20px;
    flex: 1;
`;

const Disabler = styled.div`
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${theme.colors.LOADING_OVERLAY};
    width: 100%;
    height: 60vh;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default PromptForm;
