import { forwardRef } from "react";
import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { ErrorMessage } from "components/forms";

const TextInput = forwardRef(
  (
    { value, label, id, name, type, error, big, fullWidth, disabled, autoFocus, markOnFocus, margin, height, ...rest },
    ref,
  ) => (
    <FormControl fullWidth={fullWidth} margin={margin}>
      {label && (
        <InputLabel htmlFor={id || name}>
          <span>{label}</span>
          <span>:</span>
        </InputLabel>
      )}
      <InputWrapper>
        <Input
          autoFocus={autoFocus}
          onFocus={(event) => {
            if (markOnFocus) {
              event.target.select();
            }
          }}
          error={error}
          big={big}
          label={label}
          id={id || name}
          name={name}
          disabled={disabled}
          type={type}
          as={type === "textarea" && "textarea"}
          height={height}
          ref={ref}
          defaultValue={value}
          {...rest}
        />
        <ErrorMessage>{error}</ErrorMessage>
      </InputWrapper>
    </FormControl>
  ),
);

const FormControl = styled.div`
  margin: ${({ margin }) => margin};
`;

const InputLabel = styled.label`
  font-family: ${theme.fonts.RUBIK};
  display: block;
  font-size: ${theme.sizes.INPUT_LABEL_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 1.69;
  color: ${theme.colors.INPUT_LABEL_TEXT};
  margin-bottom: 10px;
`;

const Input = styled.input`
  font-family: ${theme.fonts.RUBIK};
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 9px 14px;
  box-sizing: border-box;
  border: 2px solid transparent;
  height: ${({ height }) => height || "42px"};
  resize: none;
  border-radius: 9px;
  background-color: ${theme.colors.INPUT_BACKGROUND};
  font-size: ${theme.sizes.INPUT_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${({ disabled }) => (disabled ? theme.colors.TEXT_DISABLED : theme.colors.INPUT_TEXT)};
  line-height: 1.83;

  &:focus {
    outline: none;
    border: 2px solid ${theme.colors.INPUT_ACTIVE_BORDER};
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

export default TextInput;
