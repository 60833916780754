import * as React from "react";

function SvgComponent({ color = "#8794b2", ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.608 4.57" {...props}>
      <path
        d="M7.426 1.864L5.516.141a.55.55 0 00-.736.817l.842.76-5.073.01a.55.55 0 100 1.099h.002l5.085-.01-.879.795a.55.55 0 00.739.817L7.427 2.68a.55.55 0 000-.817z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
