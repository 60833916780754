import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="dashboard_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".dashboard_icon_st0{fill:#8794b2}"}</style>
      <path
        className="dashboard_icon_st0"
        d="M5.608 7.245H1.785c-.944 0-1.711-.768-1.711-1.71V1.71C.074.767.84 0 1.784 0h3.824C6.552 0 7.32.767 7.32 1.71v3.824c0 .943-.767 1.71-1.71 1.71zM1.785 1.409a.306.306 0 00-.302.301v3.824c0 .164.138.302.302.302h3.823a.306.306 0 00.302-.302V1.71a.306.306 0 00-.302-.301H1.785zM14.363 7.245h-3.824c-.943 0-1.71-.768-1.71-1.71V1.71c0-.943.767-1.71 1.71-1.71h3.824c.943 0 1.71.767 1.71 1.71v3.824c0 .943-.767 1.71-1.71 1.71zm-3.824-5.836a.306.306 0 00-.301.301v3.824c0 .164.138.302.301.302h3.824a.306.306 0 00.302-.302V1.71a.306.306 0 00-.302-.301h-3.824zM14.363 16h-3.824c-.943 0-1.71-.768-1.71-1.711v-3.824c0-.943.767-1.71 1.71-1.71h3.824c.943 0 1.71.767 1.71 1.71v3.824c0 .943-.767 1.71-1.71 1.71zm-3.824-5.837a.306.306 0 00-.301.302v3.824c0 .163.138.301.301.301h3.824a.306.306 0 00.302-.301v-3.824a.306.306 0 00-.302-.302h-3.824zM5.608 16H1.785C.84 16 .074 15.232.074 14.289v-3.824c0-.943.767-1.71 1.71-1.71h3.824c.944 0 1.711.767 1.711 1.71v3.824c0 .943-.767 1.71-1.71 1.71zm-3.823-5.837a.306.306 0 00-.302.302v3.824c0 .163.138.301.302.301h3.823a.306.306 0 00.302-.301v-3.824a.306.306 0 00-.302-.302H1.785z"
      />
    </svg>
  );
}

export default SvgComponent;
