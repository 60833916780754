import { useCallback, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components/macro";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import "react-day-picker/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/toast.css";

import router, { routes } from "utils/routes";
import { getUserIdFromToken } from "utils/auth";
import { GlobalStyle, theme } from "utils/theme";
import notifier, { toastIds } from "utils/toast";

import { authSelector, getAdmin, logout } from "Auth/redux/authSlice";

import { NoMatchPage } from "App/pages";

function App() {
  const dispatch = useDispatch();

  const { admin, token } = useSelector(authSelector);

  const fetchAdminData = useCallback(async () => {
    if (token && !admin) {
      const { uid } = getUserIdFromToken({ token });

      const adminDataRes = await dispatch(getAdmin({ uid }));

      if (!adminDataRes.payload) {
        notifier.error("An error occurred while fetching administrator data. Please try again shortly.", {
          toastId: toastIds.ERROR_FETCHING_ADMIN_DATA,
        });
      }
      if (adminDataRes?.payload?.role !== 0) {
        dispatch(logout());
      }
    }
  }, [dispatch, token, admin]);

  useEffect(() => {
    fetchAdminData();
  }, [dispatch, fetchAdminData]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to={routes.DASHBOARD} />} />
          {router.map(({ exact = true, component: Component, path, page: Page }) => (
            <Component exact={exact} key={path} path={path} component={Page} />
          ))}
          <Route component={NoMatchPage} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        className="toast-container"
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={5000}
        hideProgressBar
        pauseOnFocusLoss
        pauseOnHover
        newestOnTop={false}
        draggable
        closeOnClick
      />
    </ThemeProvider>
  );
}

export default App;
