import ReactDOM from "react-dom";
import React from "react";
import { Provider } from "react-redux";
import store from "utils/store";

import { QuoteComponent } from "components/editor-tools";

class QuoteTool {
  static get toolbox() {
    return {
      icon: '<svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg"><path d="M13.53 6.185l.027.025a1.109 1.109 0 0 1 0 1.568l-5.644 5.644a1.109 1.109 0 1 1-1.569-1.568l4.838-4.837L6.396 2.23A1.125 1.125 0 1 1 7.986.64l5.52 5.518.025.027zm-5.815 0l.026.025a1.109 1.109 0 0 1 0 1.568l-5.644 5.644a1.109 1.109 0 1 1-1.568-1.568l4.837-4.837L.58 2.23A1.125 1.125 0 0 1 2.171.64L7.69 6.158l.025.027z" /></svg>',
      title: "Quote",
    };
  }

  static get conversionConfig() {
    return {
      import: "text",

      export(data) {
        return data.header ? `${data.header} — ${data.text}` : data.text;
      },
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  constructor({ data, api, block }) {
    this.api = api;
    this.block = block;

    this.data = {
      img: data.img || { url: "", alt: "" },
      header: data.header || "",
      text: data.text || "",
    };
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("quote-tool");
    this.wrapper.onpaste = null;

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <QuoteComponent
            header={this.data.header}
            text={this.data.text}
            alt={this.data.img.alt}
            url={this.data.img.url}
            setData={this.setData}
            rerender={this.rerender}
          />
        </Provider>
      </React.StrictMode>,
      this.wrapper,
    );

    return this.wrapper;
  }

  setData = (key, value) => {
    if (key === "url" || key === "alt") {
      this.data.img[key] = value;
    } else {
      this.data[key] = value;
    }
  };

  rerender = () => {
    this.save();
    this.api.blocks.update(this.block.id, this.data);
  };

  save() {
    this.data.header = this.wrapper.querySelector(".header")?.innerHTML;
    this.data.text = this.wrapper.querySelector(".text")?.innerHTML;
    return this.data;
  }

  static get sanitize() {
    return {
      header: {
        br: true,
        div: {},
      },
      text: {
        br: true,
        div: {},
      },
    };
  }
}

export default QuoteTool;
