import React from "react";

const Icon = ({ className, color = "#9EADCF", width = "11px", height = "15px" }) => {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 13.1 18.9"
      enableBackground="new 0 0 13.1 18.9"
    >
      <path
        fill={color}
        d="M6.8,18.9C3.9,18.9,0,9.8,0,6.5C0,2.9,2.9,0,6.5,0c3.6,0,6.5,2.9,6.5,6.5C13.1,9.8,9.4,18.9,6.8,18.9z M6.5,1.5
	c-2.8,0-5,2.3-5,5c0,3.3,3.9,10.8,5.3,10.8c1.3-0.2,4.8-7.6,4.8-10.8C11.6,3.8,9.3,1.5,6.5,1.5z"
      />
      <path
        fill={color}
        d="M6.5,9.2c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4C7.9,4.3,9,5.4,9,6.7C9,8.1,7.9,9.2,6.5,9.2z M6.5,5.8
	C6,5.8,5.6,6.2,5.6,6.7S6,7.7,6.5,7.7s0.9-0.4,0.9-0.9S7,5.8,6.5,5.8z"
      />
    </svg>
  );
};

export default Icon;
