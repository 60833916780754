import * as React from "react";

const SvgComponent = ({ color = "#F89E79", ...props }) => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.55 12.062a.682.682 0 0 1-.156.128c-.03.02-.056.042-.088.056-.01.005-.017.014-.028.018l-5.453 2.088a.708.708 0 0 1-.67-.093.7.7 0 0 1-.278-.616l.466-5.823c.002-.023.014-.042.018-.064a.683.683 0 0 1 .103-.262c.011-.016.014-.036.027-.051L11.134.265A8.994 8.994 0 0 0 9 0a9 9 0 1 0 9 9 8.951 8.951 0 0 0-1-4.11l-5.415 7.143c-.01.013-.025.017-.035.03Z"
      fill={color}
    />
    <path
      d="m15.582 4.443.622-.82A9.02 9.02 0 0 0 12.55.73l-.734.933 3.766 2.78ZM12.15 3.65l-1.2-.886-3.916 4.98 1.269.95 3.848-5.044ZM9.423 9.534l1.467 1.099 3.846-5.074-1.459-1.078-3.854 5.053ZM6.361 12.648l3.232-1.238-2.956-2.214-.276 3.452Z"
      fill={color}
    />
    <path
      d="m15.582 4.443-3.766-2.78.734-.933a8.947 8.947 0 0 0-1.416-.465L5.491 7.443c-.013.015-.016.035-.026.051a.683.683 0 0 0-.104.262c-.004.022-.016.04-.018.064l-.466 5.823a.7.7 0 0 0 .948.71l5.453-2.089c.01-.004.017-.013.028-.018.032-.014.058-.037.088-.056a.682.682 0 0 0 .156-.128c.01-.012.026-.016.035-.029L17 4.89a8.984 8.984 0 0 0-.796-1.268l-.622.821Zm-4.632-1.68 1.2.887-3.847 5.045-1.269-.95 3.917-4.981Zm-4.589 9.885.276-3.452 2.956 2.214-3.232 1.238Zm4.53-2.015L9.422 9.534l3.854-5.053 1.46 1.078-3.847 5.074Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
