import { useEffect, useState } from "react";

const usePageLoading = () => {
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  return {
    initialLoading,
  };
};

export default usePageLoading;
