import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fileUpload } from "utils/api";

const SLICE_KEY = "files";

const INITIAL_STATE = {};

export const actionTypes = {
  UPLOAD_FILE: "files/uploadFile",
};

const endpoints = {
  UPLOAD_FILE: `/v1/files`,
};

export const uploadFile = createAsyncThunk(actionTypes.UPLOAD_FILE, async ({ file } = {}, thunkApi) =>
  fileUpload({
    actionType: actionTypes.UPLOAD_FILE,
    endpoint: endpoints.UPLOAD_FILE,
    file,
    thunkApi,
  }),
);

const authSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
});

const { reducer } = authSlice;

export default reducer;
