export const logLevels = {
  ALL: "all",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
  CRITICAL: "critical",
};

export const logTypes = {
  PROJECT: "project",
  USER: "user",
  SERP_REPORT: "serp_report",
  SEO_REPORT: "seo_report",
  REPORT_LOG: "report_log",
  LIGHTHOUSE_REPORT: "lighthouse_report",
};

export const planTypes = {
  NONE: "none",

  DEMO: "demo",
  TRIAL: "trial",

  SEODITY_PLAN_1: "seodity-plan-1",
  SEODITY_PLAN_2: "seodity-plan-2",
  SEODITY_PLAN_3: "seodity-plan-3",

  CUSTOM: "seodity-custom-pricing",

  APPSUMO_TIER1: "seodity_tier1",
  APPSUMO_TIER2: "seodity_tier2",
  APPSUMO_TIER3: "seodity_tier3",
  APPSUMO_TIER4: "seodity_tier4",
  APPSUMO_TIER5: "seodity_tier5",
};

export const paymentStatuses = {
  NONE: "none",
  PENDING: "pending",
  PAID: "paid",
  PAST_DUE: "past_due",
  FAILED: "failed",
};

export const planStatuses = {
  ACTIVE: "active",
  EXPIRED: "expired",
  CANCELLING: "cancelling",
  CANCELLED: "cancelled",

  NOT_PAYED: "not-payed",
  TRIAL_BEFORE_PAYMENT: "trial-before-payment",
  TRIAL_ENDED: "trial-ended",
};

export const reportStatuses = {
  PREPARING: "preparing",
  DONE: "done",
  STARTED: "started",
  FAILURE: "failure",
};
