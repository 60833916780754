import React, { useCallback, useState, forwardRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled, { css } from "styled-components/macro";

import { theme } from "utils/theme";
import { convertStorageToString } from "utils/formatters";

import { Spinner, CloseButton } from "components/shared";
import { UploadImage, JPG } from "components/icons";
import { useCreateBlogImageMutation } from "api/services/blog";

const Caption = styled.p`
  font-size: 12px;
  font-weight: ${theme.fontWeights.MEDIUM};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  max-width: 210px;
  margin-top: 20px;
  color: ${({ hasError }) => (hasError ? theme.colors.DANGER : theme.colors.FILE_INPUT_PLACEHOLDER_TEXT)};
`;

const FileWrapper = styled.div`
  margin-top: 20px;
  border: 1px solid ${theme.colors.FILE_INPUT_LIST_BORDER};
  border-radius: 9px;
  padding: 14px;
  display: flex;
  font-family: ${theme.fonts.RUBIK};
  position: relative;
`;

const InputWrapper = styled.div`
  min-height: ${({ height }) => height || "240px"};
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  ${({ currentImageUrl }) =>
    currentImageUrl &&
    css`
      background: url(${currentImageUrl});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `};
`;

const IconWrapper = styled.div``;

const OuterWrapper = styled.div`
  position: relative;
  border: 2px dashed ${theme.colors.IMAGE_INPUT_BORDER};
  margin-top: 30px;

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      cursor: default;
    `};

  &:focus-within {
    border: 2px dashed ${theme.colors.INPUT_ACTIVE_BORDER};
  }
`;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  display: block !important;
  opacity: 0;
`;

const FileName = styled.div`
  font-size: 12px;
  color: ${theme.colors.TEXT_TABLE_ENTRY};
  font-weight: ${theme.fontWeights.MEDIUM};
  margin-bottom: 7px;
  line-height: 1;
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 14px;
`;

const FileSize = styled.div`
  font-size: 11px;
  color: ${theme.colors.TEXT_LIGHT};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 1;
`;

const ImageInput = forwardRef(
  (
    {
      label = "Add photo",
      uploadErrorText = "Error occurred while uploading an image",
      fileErrorText = "Unaccepted file type",
      uploadingFileText = "Photo upload in progress...",
      icon: { component: IconComponent = UploadImage, width: iconWidth = 34, height: iconHeight = 34 } = {},
      name,
      error,
      isLoading,
      setLoading,
      setError,
      clearErrors,
      preview = null,
      setUrl,
      width,
      height,
    },
    ref,
  ) => {
    const [fileName, setFileName] = useState("");
    const [fileSize, setFileSize] = useState("");

    useEffect(() => {
      setFileName(preview ? preview.substring(preview.lastIndexOf("/") + 1) : "");
    }, [preview]);

    const [createBlogImage] = useCreateBlogImageMutation();

    const onDrop = useCallback(
      (acceptedFiles) => {
        if (acceptedFiles.length) {
          const file = acceptedFiles[0];
          setFileName(file.name);
          setFileSize(convertStorageToString({ bytes: file.size }));

          setLoading(true);
          clearErrors(name);
          setUrl(null);

          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const { url } = await createBlogImage({ body: reader.result, type: file.type }).unwrap();

              if (url) {
                setUrl(url);
              }
            } catch (err) {
              setError(name, { type: "manual", message: uploadErrorText });
            } finally {
              setLoading(false);
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          setError(name, { type: "manual", message: fileErrorText });
        }
      },
      [name, setUrl, setError, uploadErrorText],
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      multiple: false,
      accept: "image/jpeg,image/png",
    });

    return (
      <>
        <OuterWrapper {...getRootProps()} isLoading={isLoading} tabIndex={-1}>
          <Input {...getInputProps()} ref={ref} name={name} tabIndex={0} />
          <InputWrapper width={width} height={height} currentImageUrl={preview}>
            {!preview && (
              <>
                <IconWrapper>
                  {isLoading ? (
                    <Spinner color={theme.colors.FILE_INPUT_PLACEHOLDER_TEXT} />
                  ) : (
                    <IconComponent width={iconWidth} height={iconHeight} />
                  )}
                </IconWrapper>
                <Caption hasError={!!error} isLoading={isLoading}>
                  {!isLoading && !error && label}
                  {isLoading && uploadingFileText}
                  {error && error}
                </Caption>
              </>
            )}
          </InputWrapper>
        </OuterWrapper>
        {preview && (
          <FileWrapper>
            <JPG />
            <FileInfo>
              <FileName>{fileName}</FileName>
              <FileSize>{fileSize}</FileSize>
            </FileInfo>
            <CloseButton
              onClick={() => {
                setUrl("");
              }}
              width="10px"
              height="10px"
            />
          </FileWrapper>
        )}
      </>
    );
  },
);

export default ImageInput;
