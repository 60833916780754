import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { OptionButton } from "components/forms";
import { Menu, UserDetails } from "components/icons";

function UserDetailsLabel({ email }) {
  return email ? (
    <Wrapper>
      <IconWrapper>
        <UserDetails width="11px" height="13px" />
      </IconWrapper>
      <Email>{email}</Email>
      <ButtonWrapper>
        <OptionButton
          small
          icon={<Menu width="3px" height="12px" color="#4a4e59" />}
          color={theme.colors.BUTTON_OPTION_PRIMARY_BACKGROUND}
          onClick={() => {}}
        />
      </ButtonWrapper>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 12px;
`;

const Email = styled.div`
  font-size: 18px;
  line-height: 1.22;
  font-weight: ${theme.fontWeights.NORMAL};
  color: ${theme.colors.USER_DETAILS_LABEL_TEXT};
  margin-right: 12px;
`;

const ButtonWrapper = styled.div``;

export default UserDetailsLabel;
