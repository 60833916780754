import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods } from "utils/constants";

const SLICE_KEY = "reportLogs";

const reportLogsSchema = new schema.Entity("reportLogs", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_REPORT_LOGS: "reportLogs/getReportLogs",
  GET_PROJECT_REPORT_LOGS: "reportLogs/getProjectReportLogs",
  REMOVE_REPORT_LOG: "reportLogs/removeReportLog",
};

const endpoints = {
  GET_REPORT_LOGS: `/v1/report-logs`,
  GET_PROJECT_REPORT_LOGS: ({ id }) => `/v1/projects/${id}/report-logs`,
  REMOVE_REPORT_LOG: ({ id }) => `/v1/report-logs/${id}`,
};

export const reportLogsSelector = createSelector(
  ({ reportLogs }) => reportLogs,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const getReportLogs = createAsyncThunk(actionTypes.GET_REPORT_LOGS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_REPORT_LOGS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_REPORT_LOGS,
    thunkApi,
    params,
    schema: { report_logs: [reportLogsSchema] },
  }),
);

export const getProjectReportLogs = createAsyncThunk(
  actionTypes.GET_PROJECT_REPORT_LOGS,
  async ({ id, params } = {}, thunkApi) =>
    request({
      actionType: actionTypes.GET_PROJECT_REPORT_LOGS,
      method: httpMethods.GET,
      endpoint: endpoints.GET_PROJECT_REPORT_LOGS({ id }),
      thunkApi,
      params,
      schema: { report_logs: [reportLogsSchema] },
    }),
);

export const removeReportLog = createAsyncThunk(actionTypes.REMOVE_REPORT_LOG, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.REMOVE_REPORT_LOG,
    method: httpMethods.DELETE,
    endpoint: endpoints.REMOVE_REPORT_LOG({ id }),
    thunkApi,
  }),
);

const reportLogsSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getProjectReportLogs.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.report_logs;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.reportLogs;
    });
    builder.addCase(getProjectReportLogs.rejected, (state, { payload }) => {
      state.error = payload;
    });
    builder.addCase(getReportLogs.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.report_logs;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.reportLogs;
    });
    builder.addCase(getReportLogs.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = reportLogsSlice;

export default reducer;
