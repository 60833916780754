import { UserEmailIndicator } from "Projects/components";

function ProjectDetailsInfoTab({ project }) {
  return (
    <>
      <UserEmailIndicator user={project?.user} />
    </>
  );
}

export default ProjectDetailsInfoTab;
