import styled, { css } from "styled-components/macro";

function InputGroup({ children, grid }) {
  return <Wrapper grid={grid}>{children}</Wrapper>;
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  ${({ grid }) =>
    grid
      ? css`
          display: grid;
          grid-template-columns: ${grid};
          column-gap: 20px;
        `
      : css`
          align-items: center;
          display: flex;

          > div {
            width: 100%;
            flex-grow: 1;
          }

          > :not(:last-child) {
            margin-right: 20px;
          }
        `};
`;

export default InputGroup;
