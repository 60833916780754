import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="add_icon__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 32.508 32.022"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".add_icon__st1{fill:#249fd3}.add_icon__st2{fill:none;stroke:#249fd3;stroke-width:1.8;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        d="M19.64 32.022h-6.772C5.79 32.022 0 26.232 0 19.154v-6.286C0 5.79 5.79 0 12.868 0h6.773c7.077 0 12.867 5.79 12.867 12.868v6.286c0 7.077-5.79 12.868-12.867 12.868z"
        opacity={0.9}
        fill="#e0ecf6"
      />
      <path
        className="add_icon__st1"
        d="M23.255 17.319l-2.475-.012.01-2.474a.6.6 0 00-.596-.602h-.003a.6.6 0 00-.6.597l-.01 2.474-2.475-.011h-.003a.6.6 0 00-.003 1.2l2.475.011-.01 2.474a.6.6 0 00.596.603h.003a.6.6 0 00.6-.598l.01-2.474 2.475.012h.003a.6.6 0 00.003-1.2zM13.6 14.013c-1.48 0-2.685-1.257-2.685-2.803S12.12 8.407 13.6 8.407s2.683 1.258 2.683 2.803-1.204 2.803-2.683 2.803zm0-4.205c-.708 0-1.284.629-1.284 1.402s.576 1.402 1.284 1.402c.707 0 1.282-.628 1.282-1.402s-.575-1.402-1.282-1.402zM14.262 22.97h-1.745c-.957 0-1.623-.383-1.712-.966l-.828-2.8c-.819-.093-1.311-.467-1.311-1.024v-2.993c0-.644.66-1.045 1.721-1.045h1.556c.205 0 .4.09.533.247.06.047.412.183.976.183a.7.7 0 110 1.4c-.723 0-1.35-.156-1.76-.43h-1.305c-.126 0-.235.01-.321.024v2.235c.086.014.195.024.321.024h.119a.7.7 0 01.671.501l.946 3.198c.088.023.223.045.394.045h1.745c.185 0 .329-.026.415-.051a.7.7 0 011.307.35c0 .659-.692 1.101-1.722 1.101zm.321-1.103v0zm-2.388 0v0z"
      />
    </svg>
  );
}

export default SvgComponent;
