import NestedList from "@editorjs/nested-list";

class ListTool extends NestedList {
  static get pasteConfig() {
    return {
      tags: ["OL", "UL", "LI"],
    };
  }

  onPaste = (event) => {
    const list = event.detail.data;
    this.data = this.pasteHandler(list);
    this.nodes.wrapper.innerHTML = "";
    this.appendItems(this.data.items, this.nodes.wrapper);
  };

  pasteHandler = (element) => {
    const { tagName: tag } = element;
    let style;

    switch (tag) {
      case "OL":
        style = "ordered";
        break;
      case "UL":
      case "LI":
        style = "unordered";
        break;
      default:
        style = "unordered";
    }

    const data = {
      style,
      items: [],
    };

    if (tag === "LI") {
      data.items = [element.innerHTML];
    } else {
      const items = Array.from(element.querySelectorAll("LI"));

      data.items = items
        .map((li) => ({
          content: li.innerHTML,
          items: [],
        }))
        .filter(({ content }) => !!content);
    }

    return data;
  };
}

export default ListTool;
