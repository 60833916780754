import { useCallback } from "react";
import styled, { css } from "styled-components/macro";

import { theme } from "utils/theme";
import { useQueryParams } from "utils/hooks";
import { columnTypes, sortOrders } from "utils/constants";

import { Pagination, Spinner } from "components/shared";
import { Message } from "components/utils/Typography";
import { NoResults, SortAlphaAsc, SortAlphaDesc, SortNeutral, SortNumericAsc, SortNumericDesc } from "components/icons";
import { IconButton } from "components/forms";
import { Box } from "components/utils";

function Table({
  headers = [],
  rows = [],
  meta = {},
  isLoading,
  isRefetchLoading,
  emptyStateText = "No records available",
}) {
  const { setQuery } = useQueryParams();

  const headerColSpan = headers?.length + 1;
  const isEmpty = rows?.length === 0 && !isLoading;

  const renderHeaders = useCallback(
    () =>
      headers?.map(({ value: key, label, sortable, firstSortDir, minWidth, maxWidth, type: sortType }) => {
        const { sortDir, sortField } = meta;

        let SortIcon = sortable ? SortNeutral : "div";

        if (sortable && sortField === key) {
          if (sortType === columnTypes.ALPHA) {
            SortIcon =
              sortDir === sortOrders.ASCENDING
                ? SortAlphaAsc
                : sortDir === sortOrders.DESCENDING
                ? SortAlphaDesc
                : SortNeutral;
          } else {
            SortIcon =
              sortDir === sortOrders.ASCENDING
                ? SortNumericAsc
                : sortDir === sortOrders.DESCENDING
                ? SortNumericDesc
                : SortNeutral;
          }
        }

        return (
          <TableHeaderCell
            key={key}
            sortable={sortable}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onClick={() => {
              if (sortable) {
                if (firstSortDir) {
                  if (sortField === key) {
                    setQuery({
                      sortField: key,
                      sortDir: sortDir === sortOrders.DESCENDING ? sortOrders.ASCENDING : sortOrders.DESCENDING,
                    });
                  } else {
                    setQuery({
                      sortField: key,
                      sortDir: firstSortDir,
                    });
                  }
                } else {
                  setQuery({
                    sortField: key,
                    sortDir: sortDir === sortOrders.DESCENDING ? sortOrders.ASCENDING : sortOrders.DESCENDING,
                  });
                }
              }
            }}
          >
            <HeaderWrapper>
              <span>{label}</span>
              <span>&nbsp;&nbsp;</span>
              <span>
                {sortable && SortIcon && (
                  <IconButton
                    width="24px"
                    height="24px"
                    borderRadius="4px"
                    icon={
                      <SortIcon
                        width={!sortDir ? "11px" : "13px"}
                        height={!sortDir ? "9px" : "11px"}
                        color={theme.colors.SORT_ICON}
                      />
                    }
                  />
                )}
              </span>
            </HeaderWrapper>
          </TableHeaderCell>
        );
      }),
    [headers, meta],
  );

  const renderRows = useCallback(
    () =>
      rows?.map(({ options: { key: rowKey, onRowClick }, columns }) => {
        const cols = Object.entries(columns);

        return (
          <TableRow key={rowKey} hoverable={onRowClick}>
            {cols.map(([colKey, colValue]) => (
              <TableCell
                key={colKey}
                isActionsRow={colKey === "actions"}
                onClick={() => {
                  if (onRowClick && colKey !== "actions") {
                    onRowClick();
                  }
                }}
              >
                <ValueWrapper>{colValue}</ValueWrapper>
              </TableCell>
            ))}
          </TableRow>
        );
      }),
    [rows],
  );

  const renderLoadingState = useCallback(
    () => (
      <TableRow>
        <TableCell noPadding colSpan={headerColSpan}>
          <EmptyState>
            <Box width="110px" height="75px">
              <Spinner scale={0.35} color={theme.colors.NO_RESULTS_MESSAGE} />
            </Box>
            <Box mt="10px">
              <Message color={theme.colors.NO_RESULTS_MESSAGE}>Loading...</Message>
            </Box>
          </EmptyState>
        </TableCell>
      </TableRow>
    ),
    [],
  );

  const renderEmptyState = useCallback(
    () => (
      <TableRow>
        <TableCell noPadding colSpan={headerColSpan}>
          <EmptyState>
            <NoResults width="110px" height="75px" />
            <Box mt="10px">
              <Message color={theme.colors.NO_RESULTS_MESSAGE}>{emptyStateText}</Message>
            </Box>
          </EmptyState>
        </TableCell>
      </TableRow>
    ),
    [],
  );

  return (
    <div>
      <TableWrapper>
        {isRefetchLoading && !isLoading && (
          <Disabler>
            <Spinner color={theme.colors.PRIMARY} />
          </Disabler>
        )}
        <HtmlTable>
          <TableHead>
            <TableRow>{renderHeaders()}</TableRow>
          </TableHead>
          <TableBody>{isLoading ? renderLoadingState() : isEmpty ? renderEmptyState() : renderRows()}</TableBody>
        </HtmlTable>
      </TableWrapper>
      {Object.keys(meta).length ? (
        <TableFooter>
          <TableRow as="div">
            <TableHeaderCell as="div" colSpan={headerColSpan}>
              <Pagination
                itemsCount={meta?.all}
                currentPage={meta?.page}
                limit={meta?.limit}
                setPage={(page) => {
                  setQuery({
                    page,
                  });
                }}
              />
            </TableHeaderCell>
          </TableRow>
        </TableFooter>
      ) : null}
    </div>
  );
}

const EmptyState = styled.div`
  min-height: calc(${theme.sizes.TABLE_MINIMUM_CELL_HEIGHT} * 5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ValueWrapper = styled.div`
  display: flex;
  font-size: ${theme.sizes.TABLE_VALUE_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.TABLE_VALUE_TEXT};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${theme.sizes.TABLE_HEADER_LABEL};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.TABLE_HEADER_LABEL};

  span {
    text-align: left;
  }

  &:hover {
    svg * {
      fill: ${theme.colors.BUTTON_OPTION_HOVER};
    }
  }
`;

const TableWrapper = styled.div`
  position: relative;
`;

const HtmlTable = styled.table`
  width: 100%;
`;

const TableHead = styled.thead``;

const TableBody = styled.tbody``;

const TableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TableCell = styled.td`
  min-height: ${theme.sizes.TABLE_MINIMUM_CELL_HEIGHT};
  height: ${theme.sizes.TABLE_MINIMUM_CELL_HEIGHT};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  vertical-align: middle;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `};

  ${({ isActionsRow }) =>
    isActionsRow &&
    css`
      width: 60px;
    `}
`;

const TableRow = styled.tr`
  & {
    ${TableCell}:last-child {
      padding-right: 10px;
    }
  }

  &:nth-child(even) {
    ${TableCell} {
      background-color: ${theme.colors.TABLE_CELL_BACKGROUND_EVEN};
    }
  }
  &:nth-child(odd) {
    ${TableCell} {
      background-color: ${theme.colors.TABLE_CELL_BACKGROUND_ODD};
    }
  }

  &:hover {
    ${({ hoverable }) =>
      hoverable &&
      css`
        cursor: pointer;

        ${TableCell} {
          background-color: ${theme.colors.TABLE_ROW_HOVER};
        }
      `};
  }
`;

const TableHeaderCell = styled.th`
  padding-top: 16px;
  padding-bottom: 18px;
  padding-left: 20px;
  vertical-align: middle;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: ${maxWidth};
    `};

  ${({ sortable }) =>
    sortable &&
    css`
      cursor: pointer;
    `};
`;

const Disabler = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${theme.colors.LOADING_OVERLAY};
  width: 100%;
  height: 100%;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Table;
