import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg
      id="sort_neutral_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 10.613 7.92"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.sort_neutral_icon_st0{fill:${color}}`}</style>
      <path
        className="sort_neutral_icon_st0"
        d="M10.414 4.969a.55.55 0 00-.774.073l-.658.793.004-4.94a.55.55 0 10-1.1-.001l-.003 5.058-.799-.883a.55.55 0 10-.815.739l1.748 1.931a.55.55 0 00.408.18h.012a.547.547 0 00.41-.198l1.64-1.978a.55.55 0 00-.073-.774zM2.596.18C2.49.064 2.36.012 2.176 0a.55.55 0 00-.41.199L.125 2.177a.55.55 0 10.847.7l.655-.788-.02 5.032a.565.565 0 00.55.552.55.55 0 00.55-.548l.02-5.16.801.886a.551.551 0 00.816-.739L2.596.181z"
      />
    </svg>
  );
}

export default SvgComponent;
