import { createGlobalStyle } from "styled-components/macro";
import reset from "styled-reset";

export const breakpoints = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  XXL: "xxl",
  XXXL: "xxxl",
};

export const theme = {
  colors: {
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    //
    PRIMARY: "#249FD3",
    SECONDARY: "#A852C4",
    DANGER: "#FA354C",
    //
    MAIN_BACKGROUND: "#FFFFFF",
    //
    TEXT_LIGHT: "#7B8199",
    TEXT_MEDIUM: "#565D6B",
    TEXT_DARK: "#24262B",
    //
    TEXT_DISABLED: "#999FB8",
    TEXT_TABLE_ENTRY: "#4E5053",
    TEXT_LEGEND_LABEL: "#838BA9",
    //
    TOAST_DANGER: "#F8788A",
    TOAST_WARNING: "#F89F78",
    TOAST_SUCCESS: "#40C1D4",
    //
    TOOLTIP_BACKGROUND: "#40C1D4",
    NAVLINK_ACTIVE_BACKGROUND: "#EFF1F9",
    NAVLINK_ACTIVE_ICON: "#4a4e59",
    ACTIVE_TAB_INDICATOR: "#3E4043",
    NAVBAR_BOTTOM_LINE: "#8794B2",
    SUMMARY_ITEM_SEPARATOR: "#ebebeb",
    CLOSE_BUTTON: "#8594B0",
    //
    CALENDAR_BACKGROUND: "#40C1D4",
    CALENDAR_DAY_NORMAL_TEXT: "#FFFFFF",
    CALENDAR_DAY_DISABLED_TEXT: "#2887A8",
    CALENDAR_DAY_SELECTED_TEXT: "#FFFFFF",
    CALENDAR_DAY_SELECTED_BACKGROUND: "#71D1DF",
    CALENDAR_DAY_SELECTED_RANGE_TEXT: "#FFFFFF",
    CALENDAR_DAY_SELECTED_RANGE_BACKGROUND: "rgba(240, 234, 246, 0.5)",
    CALENDAR_DAY_LABEL: "#0B6790",
    CALENDAR_SELECTED_DAY_BACKGROUND: "#0B6790",
    CALENDAR_MONTH_LABEL: "#24262B",
    CALENDAR_YEAR_LABEL: "#FFFFFF",
    CALENDAR_PREVIOUS_MONTH_LABEL: "#0B6798",
    CALENDAR_NEXT_MONTH_LABEL: "#FFFFFF",
    CALENDAR_TIMEFRAME_VALUE_TEXT: "#4E5053",
    //
    BUTTON_PRIMARY_TEXT: "#FFFFFF",
    BUTTON_PRIMARY_BACKGROUND: "#40C1D4",
    BUTTON_PRIMARY_BACKGROUND_HOVER: "#c4e5f3",
    //
    BUTTON_SECONDARY_TEXT: "#2783b7",
    BUTTON_SECONDARY_BACKGROUND: "#E0ECF6",
    BUTTON_SECONDARY_BACKGROUND_HOVER: "#c4e5f3",
    //
    BUTTON_TERTIARY_TEXT: "#FFFFFF",
    BUTTON_TERTIARY_TEXT_DISABLED: "#a1e1ea",
    BUTTON_TERTIARY_BACKGROUND: "#0A6B8C",
    BUTTON_TERTIARY_BACKGROUND_DISABLED: "#32abc2",
    BUTTON_TERTIARY_BACKGROUND_HOVER: "#1482A7",
    //
    BUTTON_DANGER_TEXT: "#db4b67",
    BUTTON_DANGER_BACKGROUND: "#f9e8ec",
    BUTTON_DANGER_BACKGROUND_HOVER: "#f8d8df",
    //
    BUTTON_OPTION_HOVER: "#249fd3",
    //
    BUTTON_OPTION_PRIMARY_TEXT: "#4A4E59",
    BUTTON_OPTION_PRIMARY_BACKGROUND: "#EFF1FD",
    //
    BUTTON_OPTION_SECONDARY_TEXT: "#FFFFFF",
    BUTTON_OPTION_SECONDARY_BACKGROUND: "#06BAD5",
    //
    INPUT_BACKGROUND: "#F7F8FD",
    INPUT_ACTIVE_BORDER: "#06BAD5",
    INPUT_INACTIVE_BORDER: "#cdd0e3",
    INPUT_TEXT: "#4E5053",
    INPUT_LABEL_TEXT: "#24262B",
    INPUT_GROUP_LABEL_TEXT: "#24262B",
    SEARCH_INPUT_BUTTON_SEPARATOR: "#E1E4F1",
    SELECT_INPUT_ARROW_CLOSED_BACKGROUND: "#24262B",
    SELECT_INPUT_ARROW_OPEN_BACKGROUND: "#249fd3",
    SELECT_INPUT_MENU_ARROW_BACKGROUND: "#0d8fa3",
    SELECT_INPUT_MENU_ITEM_HOVER_BACKGROUND: "rgba(255,255,255,0.26)",
    //
    BREADCRUMB_TEXT: "#565D6B",
    ADMIN_EMAIL_LABEL_TEXT: "#565D6B",
    LOGO_TEXT: "#7B8199",
    PAGE_HEADING_TEXT: "#24262B",
    SUMMARY_VALUE_TEXT: "#24262B",
    USER_DETAILS_LABEL_TEXT: "#24262B",
    SUMMARY_CAPTION_TEXT: "#7B8199",
    DIFF_POSITIVE: "#249fd3",
    DIFF_NEGATIVE: "#fa354c",
    DIFF_NEUTRAL: "#7B8199",
    INACTIVE_TAB_TEXT: "#565D6B",
    ACTIVE_TAB_TEXT: "#24262B",
    //
    CHART_AREA_POSITIVE: "#e8f9fc",
    CHART_LINE_POSITIVE: "#129cea",
    CHART_AREA_NEGATIVE: "#fff2f8",
    CHART_LINE_NEGATIVE: "#f77f9c",
    //
    TABLE_HEADER_LABEL: "#24262b",
    TABLE_VALUE_TEXT: "#4e5053",
    TABLE_CELL_BACKGROUND_ODD: "#f7f8fd",
    TABLE_CELL_BACKGROUND_EVEN: "#ffffff",
    TABLE_PAGINATION_DISABLED_TEXT: "#999fb8",
    TABLE_PAGINATION_ACTIVE_TEXT: "#24262b",
    TABLE_ROW_HOVER: "#f6f0fc",
    SORT_ICON: "#4a4e59",
    SORT_ICON_HOVER: "#c4e5f3",
    LOADING_OVERLAY: "rgba(255, 255, 255, 0.8)",
    //
    DEFAULT_BADGE_BACKGROUND: "#565D6B",
    DEFAULT_BADGE_TEXT: "#ffffff",
    LOG_LEVEL_ERROR_BACKGROUND: "#fbe5e8",
    LOG_LEVEL_ERROR_TEXT: "#d55564",
    LOG_LEVEL_CRITICAL_BACKGROUND: "#f17379",
    LOG_LEVEL_CRITICAL_TEXT: "#ffffff",
    LOG_LEVEL_INFO_BACKGROUND: "#9ac892",
    LOG_LEVEL_INFO_TEXT: "#ffffff",
    LOG_LEVEL_WARNING_BACKGROUND: "#fae6c9",
    LOG_LEVEL_WARNING_TEXT: "#bc783b",
    //
    TIME_VALUE_TEXT: "#a852c4",
    //
    MODAL_BACKDROP: "#ffffffbb",
    MODAL_TITLE: "#24262b",
    //
    ADVANCED_FILTERS_SEPARATOR: "#e7eaf0",
    COUNTRY_ISO_CODE: "#495a74",
    NO_RESULTS_MESSAGE: "#095780",
    FILE_INPUT_PLACEHOLDER_TEXT: "#6d7c9b",
    FILE_INPUT_LIST_BORDER: "#e7e9f3",
    FILE_INPUT_FILE_NAME: "#4e5053",
    FILE_INPUT_FILE_SIZE: "#7b8199",
    IMAGE_INPUT_BORDER: "#D2DAEC",
    BLOG_TEXT: "#000C2D",
    LINK_BLACK: "#161515",
    BLOG_LINK: "#EA4D3A",
    YELLOW: "#FFFF00",
    BLOG_QUOTE: "#FEF8F7",
    BLOG_QUOTE_BORDER: "#EC6F5E",
  },
  breakpoints: {
    [breakpoints.XS]: "320px",
    [breakpoints.SM]: "576px",
    [breakpoints.MD]: "768px",
    [breakpoints.LG]: "992px",
    [breakpoints.XL]: "1200px",
    [breakpoints.XXL]: "1600px",
    [breakpoints.XXXL]: "1920px",
  },
  fonts: {
    RUBIK: "Rubik",
    MERRIWEATHER: "Merriweather",
  },
  fontWeights: {
    NORMAL: "normal",
    MEDIUM: 500,
    BOLD: "bold",
  },
  sizes: {
    NAVBAR_HEIGHT: "70px",
    BREADCRUMBS_HEIGHT: "36px",
    CONTAINER_WIDTH: "1280px",
    //
    CONTENT_PADDING: "40px",
    NAVBAR_PADDING: "0 40px",
    //
    BREADCRUMB_TEXT: "11px",
    ADMIN_EMAIL_LABEL_TEXT: "12px",
    INPUT_TEXT: "12px",
    INPUT_LABEL_TEXT: "13px",
    INPUT_GROUP_LABEL_TEXT: "13px",
    PAGE_HEADING_TEXT: "24px",
    CALENDAR_MONTH_TEXT: "18px",
    CALENDAR_YEAR_TEXT: "11px",
    CALENDAR_DAY_TEXT: "11px",
    CALENDAR_MONTH_CHANGE_BUTTON_TEXT: "12px",
    TABLE_MINIMUM_CELL_HEIGHT: "48px",
    TABLE_HEADER_LABEL: "13px",
    TABLE_VALUE_TEXT: "12px",
    PAGINATION_NUMBER: "12px",
    PAGINATION_TEXT: "12px",
    MODAL_TITLE: "16px",
    ADVANCED_FILTER_LABEL: "12px",
    DOMAIN_NAME: "18px",
    DOMAIN_NAME_SMALL: "12px",
  },
};

export const GlobalStyle = createGlobalStyle`
    ${reset};

    * {
      box-sizing: border-box;
    }
  
    body, button, input, textarea {
      font-family: ${theme.fonts.RUBIK}, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    }

    body {
      background-color: ${theme.colors.MAIN_BACKGROUND};
    }
  `;
