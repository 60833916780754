import styled from "styled-components/macro";

import { theme } from "utils/theme";

function BorderLine() {
  return <Wrapper />;
}

const Wrapper = styled.div`
  height: 1px;
  background-color: ${theme.colors.NAVBAR_BOTTOM_LINE};
  opacity: 0.2;
`;

export default BorderLine;
