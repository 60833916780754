import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["Products"],
});

const appApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query({
      query: () => `/v1/app/products`,
      providesTags: "Products",
    }),
  }),
});

export const { useGetProductsQuery } = appApi;
