import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { columnTypes, modalKeys, viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";
import { useModal, usePageLoading, useQueryParams } from "utils/hooks";
import { getPaginationParams, getMetaParams } from "utils/pagination";
import toast, { toastIds } from "utils/toast";

import {
  ConfirmationModal,
  DateTimeValue,
  Drawer,
  JsonDisplay,
  MenuDropdown,
  NullableValue,
  SummaryValue,
  Table,
  TopPanel,
} from "components/shared";
import { Box } from "components/utils";
import { Remove } from "components/icons";
import { AppTemplate } from "components/templates";

import { pendingActionsSelector } from "App/redux/appSlice";
import { actionTypes, getReportLogs, reportLogsSelector, removeReportLog } from "ReportLogs/redux/reportLogsSlice";

function ReportLogsPage() {
  const dispatch = useDispatch();

  const { initialLoading } = usePageLoading();
  const { params } = useQueryParams();

  const { ids: reportLogIds, entities: reportLogsEntities, meta: reportLogsMeta } = useSelector(reportLogsSelector);
  const loadingReportLogs = useSelector((state) =>
    pendingActionsSelector({ state, actions: actionTypes.GET_REPORT_LOGS }),
  );

  const [reportLogToRemoveId, setReportLogToRemoveId] = useState(null);
  const { isOpen: openedJsonId, setOpen: setOpenedJsonId } = useModal();

  const route = getRouteByKey({ viewKey: viewKeys.REPORT_LOGS });

  useEffect(() => {
    dispatch(
      getReportLogs({
        params: {
          ...getPaginationParams({ params }),
        },
      }),
    );
  }, [dispatch, params]);

  const TopPanelComponent = useCallback(
    () => (
      <TopPanel
        title={route?.label}
        leftSection={<Box />}
        rightSection={
          <Box>
            <SummaryValue value={reportLogsMeta?.all} caption="ReportLogs count" />
          </Box>
        }
      />
    ),
    [reportLogsMeta, params],
  );

  const headers = useMemo(
    () => [
      { label: "UID", value: "uid", type: columnTypes.ALPHA, sortable: true },
      { label: "Name", value: "name", type: columnTypes.ALPHA, sortable: true },
      { label: "Status", value: "status", type: columnTypes.ALPHA, sortable: true },
      { label: "Type", value: "type", type: columnTypes.ALPHA, sortable: true },
      { label: "Retry Count", value: "retry_count", type: columnTypes.ALPHA, sortable: true },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true, minWidth: "135px" },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      reportLogIds?.map((id) => {
        const reportLog = reportLogsEntities?.[id];

        return {
          options: {
            key: id,
            onRowClick: () => {
              setOpenedJsonId(id);
            },
          },

          columns: {
            uid: <NullableValue>{reportLog?.uid}</NullableValue>,
            name: <NullableValue>{reportLog?.name}</NullableValue>,
            status: <NullableValue>{reportLog?.status}</NullableValue>,
            type: <NullableValue>{reportLog?.type}</NullableValue>,
            retry_count: <NullableValue>{reportLog?.retry_count}</NullableValue>,
            created_at: <DateTimeValue date={reportLog?.created_at} />,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "JSON",
                      value: "json",
                      onClick: () => {
                        setOpenedJsonId(id);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setReportLogToRemoveId(id);
                      },
                    },
                  ]}
                />
                <Drawer
                  isOpen={openedJsonId === id}
                  onRequestClose={() => {
                    setOpenedJsonId(null);
                  }}
                >
                  <JsonDisplay json={reportLog} />
                </Drawer>
              </>
            ),
          },
        };
      }),
    [reportLogIds, openedJsonId],
  );

  const handleReportLogRemove = async () => {
    if (reportLogToRemoveId) {
      try {
        const rs = await dispatch(removeReportLog({ id: reportLogToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("ReportLog removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_REPORT_LOG,
          });

          dispatch(
            getReportLogs({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error("An error occurred while removing reportLog. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_REPORT_LOG,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing reportLog. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_REPORT_LOG,
        });
      }
    }

    setReportLogToRemoveId(null);
  };

  return (
    <>
      <AppTemplate route={route} TopPanelComponent={TopPanelComponent}>
        <Table
          isLoading={!reportLogIds}
          isRefetchLoading={loadingReportLogs || initialLoading}
          headers={headers}
          rows={rows}
          meta={getMetaParams({ meta: reportLogsMeta, queryParams: params })}
          emptyStateText="No reportLogs available"
        />
      </AppTemplate>
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected reportLog?"
        id={modalKeys.UI_ID_REMOVE_REPORT_LOG_MODAL}
        isOpen={reportLogToRemoveId}
        onConfirm={handleReportLogRemove}
        onCancel={() => {
          setReportLogToRemoveId(null);
        }}
      />
    </>
  );
}

export default ReportLogsPage;
