import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";

import { routes } from "utils/routes";
import { httpStatuses } from "utils/constants";

import { NavLogo } from "components/shared";
import { Button, TextInput, ErrorMessage } from "components/forms";

import { login } from "Auth/redux/authSlice";

function LoginForm() {
  const [operationInProgress, setOperationInProgress] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors: formErrors } = useForm();

  const [loginError, setLoginError] = useState(null);

  const handleLogin = async (values) => {
    try {
      setOperationInProgress(true);
      setLoginError(null);

      const loginRs = await dispatch(
        login({
          username: values?.username,
          password: values?.password,
        }),
      );

      if (loginRs?.payload?.code === httpStatuses.UNAUTHORIZED) {
        setLoginError("Invalid credentials");
        setOperationInProgress(false);
      } else {
        history.push(routes.DASHBOARD);
      }
    } catch (error) {
      setOperationInProgress(false);
    }
  };

  const fields = [
    {
      label: "E-mail",
      type: "email",
      name: "username",
      component: TextInput,
      validation: {
        required: "E-mail is required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "Invalid e-mail address",
        },
      },
      autoFocus: true,
    },
    {
      label: "Password",
      type: "password",
      name: "password",
      component: TextInput,
      validation: { required: "Password is required" },
    },
  ];

  return (
    <OuterWrapper>
      <InnerWrapper>
        <LogoWrapper>
          <NavLogo noLink />
        </LogoWrapper>
        <form onSubmit={handleSubmit(handleLogin)}>
          {fields.map(({ component: Component, validation, inputProps, ...fieldProps }) => (
            <Component
              key={fieldProps?.name}
              ref={register(validation)}
              error={formErrors?.[fieldProps?.name]?.message}
              {...inputProps}
              {...fieldProps}
            />
          ))}
          <ErrorMessage withMargin>{loginError}</ErrorMessage>
          <Button loading={operationInProgress} type="submit" variant={Button.Variants.SECONDARY}>
            Log in
          </Button>
        </form>
      </InnerWrapper>
    </OuterWrapper>
  );
}

const OuterWrapper = styled.div`
  position: relative;
  height: 100vh;
`;

const InnerWrapper = styled.div`
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LogoWrapper = styled.div`
  padding-bottom: 40px;
`;

export default LoginForm;
