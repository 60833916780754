import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg
      id="filter_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 17.236 15.109"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.filter_icon_st0{fill:${color}}`}</style>
      <path
        className="filter_icon_st0"
        d="M3.289 3.362V0H2.047v3.362A2.668 2.668 0 000 5.95c0 1.257.876 2.307 2.047 2.589v6.57h1.242v-6.57A2.668 2.668 0 005.335 5.95 2.668 2.668 0 003.29 3.362zm-.621 3.956A1.37 1.37 0 011.3 5.95a1.37 1.37 0 012.736 0 1.37 1.37 0 01-1.368 1.368zM9.24 7.76V0H7.996v7.76c-1.171.282-2.047 1.332-2.047 2.588s.876 2.306 2.047 2.588v2.173H9.24v-2.173c1.171-.282 2.047-1.332 2.047-2.588S10.41 8.042 9.24 7.761zm-.622 3.956c-.754 0-1.368-.614-1.368-1.368a1.37 1.37 0 012.736 0 1.37 1.37 0 01-1.368 1.368zM17.236 5.588A2.668 2.668 0 0015.19 3V0h-1.241v3A2.668 2.668 0 0011.9 5.588c0 1.257.876 2.307 2.047 2.588v6.933h1.241V8.176a2.668 2.668 0 002.047-2.588zm-2.668 1.368a1.37 1.37 0 01-1.367-1.368 1.37 1.37 0 012.736 0 1.37 1.37 0 01-1.369 1.368z"
      />
    </svg>
  );
}

export default SvgComponent;
