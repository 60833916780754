import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <path
        d="M9 0a9 9 0 100 18A9 9 0 009 0zm3.366 10.953l-1.414 1.414L9 10.414l-1.952 1.953-1.414-1.414L7.586 9 5.634 7.047l1.414-1.414L9 7.586l1.952-1.953 1.414 1.414L10.414 9l1.952 1.953z"
        fill="#fa354c"
      />
    </svg>
  );
}

export default SvgComponent;
