export const SEODITY_AUTH_TOKEN_ADMIN_KEY = "seodity-auth-admin";
export const REDUX_PERSIST_LS_KEY = "seodity-state";

export const UI_ID_ADVANCED_FILTERS_FORM = "filters";
export const UI_ID_DATE_PICKER_TIMEFRAME_INPUT = "tf-picker-input";

export const DEFAULT_PAGINATION_LIMIT = 15;

export const httpStatuses = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
};

export const httpMethods = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const viewKeys = {
  DASHBOARD: "view-dashboard",
  LOGIN: "view-login",
  PROJECTS: "view-projects",
  USERS: "view-users",
  LOGS: "view-logs",
  USER_DETAILS: "view-user-details",
  PROJECT_DETAILS: "view-project-details",
  BLOG: "view-blog",
  BLOG_CREATE: "view-blog-create",
  BLOG_EDIT: "view-blog-edit",
  SEO_REPORTS: "seo-reports",
  LIGHTHOUSE_REPORTS: "lighthouse-reports",
  SERP_REPORTS: "serp-reports",
  REPORT_LOGS: "report-logs",
  PROMPTS: "prompts",
  PROMPT_EDIT: "prompt-edit",
};

export const modalKeys = {
  UI_ID_ADVANCED_FILTERS_MODAL: "advanced-filters-modal",
  UI_ID_CREATE_PROJECT_MODAL: "create-project-modal",
  UI_ID_REMOVE_PROJECT_MODAL: "remove-project-modal",
  UI_ID_CREATE_USER_MODAL: "create-user-modal",
  UI_ID_CREATE_ADMIN_MODAL: "create-admin-modal",
  UI_ID_ACTIVATE_USER_MODAL: "activate-user-modal",
  UI_ID_REMOVE_USER_MODAL: "remove-user-modal",
  UI_ID_USER_DETAILS_JSON_MODAL: "user-details-json-modal",
  UI_ID_PROJECT_DETAILS_JSON_MODAL: "project-details-json-modal",
  UI_ID_REMOVE_REPORT_LOG_MODAL: "remove-report-log-modal",
  UI_ID_REMOVE_INVOICE_MODAL: "remove-invoice-modal",
  UI_ID_REMOVE_KEYWORD_MODAL: "remove-keyword-modal",
  UI_ID_CREATE_INVOICE_MODAL: "create-invoice-modal",
  UI_ID_REMOVE_REPORT_MODAL: "remove-report-modal",
  UI_ID_ON_DEMAND_REPORT_MODAL: "on-demand-report-modal",
  UI_ID_REPARSE_REPORT_MODAL: "reparse-report-modal",
  UI_ID_CREATE_AUTHOR_MODAL: "create-author-modal",
  UI_ID_UPDATE_AUTHOR_MODAL: "update-author-modal",
  UI_ID_REMOVE_AUTHOR_MODAL: "remove-author-modal",
  UI_ID_REMOVE_POST_MODAL: "remove-post-modal",
  UI_ID_ON_DEMAND_SEO_REPORT_MODAL: "on-demand-seo-report-modal",
  UI_ID_ON_DEMAND_SERP_REPORT_MODAL: "on-demand-serp-report-modal",
};

export const tabKeys = {
  USER_DETAILS_PROJECTS: "projects",
  USER_DETAILS_RESOURCES: "resources",
  USER_DETAILS_ACTIVITY: "activity",
  USER_DETAILS_INVOICES: "invoices",
  USER_DETAILS_SETTINGS: "settings",
  //
  PROJECT_DETAILS_COMPETITION: "competition",
  PROJECT_DETAILS_RESOURCES: "resources",
  PROJECT_DETAILS_KEYWORDS: "keywords",
  PROJECT_DETAILS_REPORT_LOGS: "report-logs",
  PROJECT_DETAILS_INFO: "info",
  PROJECT_DETAILS_SETTINGS: "settings",

  BLOG_POSTS: "posts",
  BLOG_AUTHORS: "authors",

  PROMPTS: "prompts",
};

export const dateFormats = {
  HOURS_MINUTES: "HH:mm",
  YYYY_MM_DD: "yyyy-MM-dd",
};

export const weekdays = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
};

export const sortOrders = {
  OFF: "off",
  ASCENDING: "asc",
  DESCENDING: "desc",
};

export const columnTypes = {
  ALPHA: "alpha",
  NUMERIC: "numeric",
};

export const toggleTypes = {
  ON: "on",
  OFF: "off",
};

export const filterComparators = {
  CONTAINS: "contains",
  NOT_CONTAINS: "not_contains",
  EQUALS: "eq",
  NOT_EQUALS: "neq",
  LESS_THAN: "lt",
  GREATER_THAN: "gt",
};

export const operationTypes = {
  CONFIRM: "confirm",
  CANCEL: "cancel",
  DRAFT: "draft",
};

export const postStatuses = {
  PUBLISHED: "published",
  DRAFT: "draft",
};
