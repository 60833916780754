import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { CountryIndicator } from "components/shared";
import { DeviceDesktop, DeviceMobile, Region } from "components/icons";

function KeywordValue({ isoCode, location, isMobile, keyword, hideIcons }) {
  const deviceLabel = isMobile ? "Mobile" : "Desktop";

  return (
    keyword && (
      <Wrapper>
        <CountryIndicator isoCode={isoCode} />
        {!hideIcons && (
          <>
            <LocationWrapper title={location}>{location && <Region />}</LocationWrapper>
            <DeviceIconWrapper title={deviceLabel}>{isMobile ? <DeviceMobile /> : <DeviceDesktop />}</DeviceIconWrapper>
          </>
        )}
        <KeywordWrapper>{keyword}</KeywordWrapper>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 10px;
  }
`;

const KeywordWrapper = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.DOMAIN_NAME_SMALL};
  line-height: 1.22;
  color: ${theme.colors.TEXT_TABLE_ENTRY};
`;

const LocationWrapper = styled.div``;

const DeviceIconWrapper = styled.div`
  width: 15px;
`;

export default KeywordValue;
