import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";

import { ImageTool, ListTool, QuoteTool } from "components/editor-tools";

import { UnderlineInlineTool } from "editorjs-inline-tool";

export const EDITOR_TOOLS = {
  embed: Embed,
  table: Table,
  marker: Marker,
  nestedList: { class: ListTool, inlineToolbar: true },
  warning: Warning,
  code: Code,
  raw: Raw,
  header: { class: Header, inlineToolbar: true },
  quote: { class: QuoteTool, inlineToolbar: true },
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  image: { class: ImageTool, inlineToolbar: true },
  underline: UnderlineInlineTool,
};
