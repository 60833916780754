import styled, { css } from "styled-components/macro";

import { theme } from "utils/theme";

import { Spinner } from "components/shared";

function Button({
  width,
  children,
  onClick = () => {},
  height = "34px",
  loading = false,
  padding = "15px 15px",
  disabled = false,
  type = "button",
  variant = Button.Variants.PRIMARY,
  spinnerColor = Button.Variants.WHITE,
  ...props
}) {
  return (
    <Wrapper
      $width={width}
      $height={height}
      padding={padding}
      onClick={onClick}
      $loading={loading}
      disabled={disabled}
      variant={variant}
      type={type}
      {...props}
    >
      {loading && (
        <SpinnerWrapper>
          <Spinner scale={0.14} color={spinnerColor} />
        </SpinnerWrapper>
      )}
      <Content $loading={loading}>
        <span>{children}</span>
      </Content>
    </Wrapper>
  );
}

Button.Variants = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  DANGER: "danger",
  ACTION: "action",
};

const SpinnerWrapper = styled.div`
  position: absolute;
`;

const Content = styled.span`
  visibility: ${({ $loading }) => ($loading ? "hidden" : "visible")};
`;

const baseButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  border: 0;
  appearance: none;
  font-family: ${theme.fonts.RUBIK};
  font-weight: ${theme.fontWeights.MEDIUM};
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  font-size: 12px;
  padding: ${({ padding }) => padding};
  cursor: pointer;
  min-height: ${({ $height }) => $height};
  min-width: ${({ $width }) => $width};
  max-height: ${({ $height }) => $height};
  white-space: nowrap;
  transition: all 0.3s;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    border: none;
    pointer-events: none;
  }
`;

const primaryButtonStyles = css`
  color: ${theme.colors.BUTTON_PRIMARY_TEXT};
  background-color: ${theme.colors.BUTTON_PRIMARY_BACKGROUND};

  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.BUTTON_PRIMARY_TEXT};
    background-color: ${theme.colors.BUTTON_PRIMARY_BACKGROUND_HOVER};
  }

  &:disabled {
    color: ${theme.colors.TEXT_DISABLED};
    background-color: unset;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      &:disabled {
        background-color: ${theme.colors.BUTTON_PRIMARY_BACKGROUND_HOVER}55;
      }

      background-color: ${theme.colors.BUTTON_PRIMARY_BACKGROUND_HOVER};
      pointer-events: none;

      .spinner-segment {
        border-color: ${theme.colors.BUTTON_PRIMARY_TEXT} transparent ${theme.colors.BUTTON_PRIMARY_TEXT} transparent !important;
      }
    `};
`;

const secondaryButtonStyles = css`
  color: ${theme.colors.BUTTON_SECONDARY_TEXT};
  background-color: ${theme.colors.BUTTON_SECONDARY_BACKGROUND};

  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.BUTTON_SECONDARY_TEXT};
    background-color: ${theme.colors.BUTTON_SECONDARY_BACKGROUND_HOVER};
  }

  &:disabled {
    color: ${theme.colors.TEXT_DISABLED};
    background-color: unset;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      &:disabled {
        background-color: ${theme.colors.BUTTON_SECONDARY_BACKGROUND_HOVER}55;
      }

      background-color: ${theme.colors.BUTTON_SECONDARY_BACKGROUND_HOVER};
      pointer-events: none;

      .spinner-segment {
        border-color: ${theme.colors.BUTTON_SECONDARY_TEXT} transparent ${theme.colors.BUTTON_SECONDARY_TEXT}
          transparent !important;
      }
    `};
`;

const tertiaryButtonStyles = css`
  color: ${theme.colors.BUTTON_TERTIARY_TEXT};
  background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND};

  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.BUTTON_TERTIARY_TEXT};
    background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND_HOVER};
  }

  &:disabled {
    color: ${theme.colors.BUTTON_TERTIARY_TEXT_DISABLED};
    background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND_DISABLED};
  }

  ${({ $loading }) =>
    $loading &&
    css`
      &:disabled {
        background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND_DISABLED};
        color: ${theme.colors.BUTTON_TERTIARY_TEXT_DISABLED};
      }

      background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND_DISABLED};
      pointer-events: none;

      .spinner-segment {
        border-color: ${theme.colors.BUTTON_TERTIARY_TEXT} transparent ${theme.colors.BUTTON_TERTIARY_TEXT} transparent !important;
      }
    `};
`;

const dangerButtonStyles = css`
  color: ${theme.colors.BUTTON_DANGER_TEXT};
  background-color: ${theme.colors.BUTTON_DANGER_BACKGROUND};

  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.BUTTON_DANGER_TEXT};
    background-color: ${theme.colors.BUTTON_DANGER_BACKGROUND_HOVER};
  }

  &:disabled {
    color: ${theme.colors.TEXT_DISABLED};
    background-color: unset;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      &:disabled {
        background-color: ${theme.colors.BUTTON_DANGER_BACKGROUND_HOVER}55;
      }

      background-color: ${theme.colors.BUTTON_DANGER_BACKGROUND_HOVER};
      pointer-events: none;

      .spinner-segment {
        border-color: ${theme.colors.BUTTON_DANGER_TEXT} transparent ${theme.colors.BUTTON_DANGER_TEXT} transparent !important;
      }
    `};
`;

const actionButtonStyles = css`
  color: ${theme.colors.WHITE};
  background-color: ${theme.colors.BUTTON_OPTION_SECONDARY_BACKGROUND};

  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.WHITE};
    background-color: ${theme.colors.BUTTON_OPTION_SECONDARY_BACKGROUND};
  }

  &:disabled {
    color: ${theme.colors.TEXT_DISABLED};
    background-color: unset;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      &:disabled {
        background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND_DISABLED};
        color: ${theme.colors.BUTTON_TERTIARY_TEXT_DISABLED};
      }

      background-color: ${theme.colors.BUTTON_TERTIARY_BACKGROUND_DISABLED};
      pointer-events: none;

      .spinner-segment {
        border-color: ${theme.colors.BUTTON_OPTION_SECONDARY_BACKGROUND} transparent ${theme.colors.WHITE} transparent !important;
      }
    `};
`;

const Wrapper = styled.button`
  ${baseButtonStyles};

  ${({ $loading }) =>
    $loading &&
    css`
      transition: none;
      pointer-events: none;
      cursor: default;
      padding-top: 0;
      padding-bottom: 0;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: default;
    `};

  &:focus {
    outline: none;
  }

  ${({ variant }) => {
    switch (variant) {
      case Button.Variants.PRIMARY:
      default:
        return primaryButtonStyles;
      case Button.Variants.SECONDARY:
        return secondaryButtonStyles;
      case Button.Variants.TERTIARY:
        return tertiaryButtonStyles;
      case Button.Variants.DANGER:
        return dangerButtonStyles;
      case Button.Variants.ACTION:
        return actionButtonStyles;
    }
  }}
`;

export default Button;
