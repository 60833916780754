import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["Account"],
});

const accountApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getAccount: build.query({
      query: (uid) => `/v1/accounts/${uid}`,
      providesTags: (result, error, uid) => [{ type: "Account", id: uid }],
    }),

    updateAccount: build.mutation({
      query(data) {
        return {
          url: `/v1/accounts/${data.uid}`,
          method: "PATCH",
          body: data.data,
        };
      },
      invalidatesTags: () => [{ type: "Account" }],
    }),
  }),
});

export const { useGetAccountQuery, useUpdateAccountMutation } = accountApi;
