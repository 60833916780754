import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { modalKeys, viewKeys, columnTypes } from "utils/constants";
import { getRouteByKey } from "utils/routes";
import { useQueryParams, useModal, usePageLoading } from "utils/hooks";
import { getMetaParams, getPaginationParams } from "utils/pagination";
import { logLevels } from "utils/entities";
import { logLevelsOptions } from "utils/options";
import { filterConfig } from "utils/filters";
import { theme } from "utils/theme";

import { AppTemplate } from "components/templates";
import {
  NullableValue,
  Table,
  TopPanel,
  Badge,
  DateTimeValue,
  JsonDisplay,
  Drawer,
  Modal,
  SummaryValue,
  AdvancedFiltersForm,
  AdvancedFiltersDisplay,
  OptionsBar,
} from "components/shared";
import { DateRangePicker, IconButton, SearchInput, SelectInput } from "components/forms";
import { Box } from "components/utils";
import { Info } from "components/icons";

import { actionTypes, getLogs, logsSelector } from "Logs/redux/logsSlice";
import { pendingActionsSelector } from "App/redux/appSlice";

function LogsPage() {
  const { initialLoading } = usePageLoading();

  const route = getRouteByKey({ viewKey: viewKeys.LOGS });

  const dispatch = useDispatch();

  const { params, setQuery } = useQueryParams();

  const { ids: logIds, entities: logsEntities, meta: logsMeta } = useSelector(logsSelector);
  const loadingLogs = useSelector((state) => pendingActionsSelector({ state, actions: actionTypes.GET_LOGS }));

  useEffect(() => {
    dispatch(
      getLogs({
        params: {
          accountUID: params?.userUID,
          type: params?.type,
          level: params?.level,
          //
          fromDate: params?.fromDate,
          toDate: params?.toDate,
          //
          ...getPaginationParams({ params }),
        },
      }),
    );
  }, [dispatch, params]);

  const OptionsComponent = () => (
    <Box width="100%" flex alignCenter justifyBetween>
      <div>
        <AdvancedFiltersDisplay filterConfig={filterConfig} viewKey={viewKeys.LOGS} />
      </div>
    </Box>
  );

  const { isOpen: openedAdvancedFiltersId, setOpen: setOpenedAdvancedFiltersId } = useModal();

  const TopPanelComponent = useCallback(
    () => (
      <TopPanel
        title={route?.label}
        leftSection={
          <Box flex>
            <Box mr="20px">
              <SearchInput
                width="370px"
                onButtonClick={() => {
                  setOpenedAdvancedFiltersId(modalKeys.UI_ID_ADVANCED_FILTERS_MODAL);
                }}
              />
            </Box>
            <Box>
              <DateRangePicker
                value={{
                  from: params?.fromDate,
                  to: params?.toDate,
                }}
                onReset={() => {
                  setQuery({
                    fromDate: null,
                    toDate: null,
                  });
                }}
                onChange={(timeframe) => {
                  setQuery({
                    fromDate: timeframe?.from,
                    toDate: timeframe?.to,
                  });
                }}
              />
            </Box>
          </Box>
        }
        rightSection={
          <Box>
            <SummaryValue value={logsMeta?.all} caption="Logs count" />
          </Box>
        }
      />
    ),
    [logsMeta, params],
  );

  const headers = useMemo(
    () => [
      { label: "Level", value: "level", sortable: true, type: columnTypes.ALPHA },
      { label: "Type", value: "type", sortable: true, type: columnTypes.ALPHA },
      { label: "Created at", value: "created_at", sortable: true, type: columnTypes.ALPHA },
      { label: "Content", value: "content", sortable: true, type: columnTypes.ALPHA },
      { label: "User UID", value: "user_uid", sortable: false, type: columnTypes.ALPHA },
      { label: "Object UID", value: "object_uid", sortable: false, sortType: columnTypes.ALPHA },
    ],
    [],
  );

  const { isOpen: openedJsonId, setOpen: setOpenedJsonId } = useModal();

  const rows = useMemo(() => {
    return logIds?.map((id) => {
      const log = logsEntities?.[id];

      return {
        options: {
          key: id,
          onRowClick: () => {
            setOpenedJsonId(id);
          },
        },
        columns: {
          level: <Badge parameter={log?.level} />,
          type: <Badge parameter={log?.type} />,
          created_at: <DateTimeValue date={log?.created_at} />,
          content: <NullableValue>{log?.content}</NullableValue>,
          user_uid: <NullableValue>{log?.user_uid}</NullableValue>,
          object_uid: <NullableValue>{log?.object_uid}</NullableValue>,
          actions: (
            <>
              <IconButton
                color={theme.colors.BUTTON_SECONDARY_BACKGROUND}
                colorHover={theme.colors.BUTTON_SECONDARY_BACKGROUND_HOVER}
                width="20px"
                height="20px"
                borderRadius="6px"
                icon={<Info width="9px" height="9px" />}
                onClick={() => {
                  setOpenedJsonId(id);
                }}
              />
              <Drawer
                isOpen={openedJsonId === id}
                onRequestClose={() => {
                  setOpenedJsonId(null);
                }}
              >
                <JsonDisplay json={log?.value} />
              </Drawer>
            </>
          ),
        },
      };
    });
  }, [logIds, openedJsonId]);

  const RightSideComponent = () => (
    <SelectInput
      width="120px"
      onChange={({ value: level }) => {
        setQuery({
          level: level === logLevels.ALL ? null : level,
          page: 1,
        });
      }}
      value={params?.level}
      options={logLevelsOptions}
    />
  );

  return (
    <>
      <AppTemplate route={route} OptionsComponent={OptionsComponent} TopPanelComponent={TopPanelComponent}>
        <OptionsBar RightSideComponent={RightSideComponent} />
        <Table
          isLoading={!logIds}
          isRefetchLoading={loadingLogs || initialLoading}
          headers={headers}
          rows={rows}
          meta={getMetaParams({ meta: logsMeta, queryParams: params })}
          emptyStateText="No logs available"
        />
      </AppTemplate>
      <Modal
        title="Sorting and filtering"
        id={modalKeys.UI_ID_ADVANCED_FILTERS_MODAL}
        isOpen={openedAdvancedFiltersId === modalKeys.UI_ID_ADVANCED_FILTERS_MODAL}
        onRequestClose={() => {
          setOpenedAdvancedFiltersId(null);
        }}
      >
        <AdvancedFiltersForm
          filterConfig={filterConfig}
          viewKey={viewKeys.LOGS}
          onConfirm={() => {
            setOpenedAdvancedFiltersId(null);
          }}
          onCancel={() => {
            setOpenedAdvancedFiltersId(null);
          }}
        />
      </Modal>
    </>
  );
}

export default LogsPage;
