import { useForm } from "react-hook-form";
import { useState } from "react";

import { operationTypes } from "utils/constants";

import { Button, ButtonGroup, TextInput } from "components/forms";
import { Box } from "components/utils";

function CreateUserForm({ onConfirm, onCancel }) {
  const [operationInProgress, setOperationInProgress] = useState(false);

  const handleOperation = async ({ values, type } = {}) => {
    await setOperationInProgress(true);

    if (type === operationTypes.CANCEL) {
      await onCancel();
    } else {
      await onConfirm(values);
    }

    setOperationInProgress(false);
  };

  const { register, handleSubmit, errors: formErrors } = useForm();

  const fields = [
    {
      label: "E-mail",
      type: "email",
      name: "username",
      component: TextInput,
      validation: {
        required: "E-mail is required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "Invalid e-mail address",
        },
      },
      autoFocus: true,
    },
  ];

  return (
    <form onSubmit={handleSubmit((values) => handleOperation({ values, type: operationTypes.CONFIRM }))}>
      {fields.map(({ component: Component, validation, inputProps, ...fieldProps }) => (
        <Component
          key={fieldProps?.name}
          ref={register(validation)}
          error={formErrors?.[fieldProps?.name]?.message}
          {...inputProps}
          {...fieldProps}
        />
      ))}
      <Box flex justifyCenter margin="20px 0 0">
        <ButtonGroup>
          <Button
            type="button"
            loading={operationInProgress}
            variant={Button.Variants.SECONDARY}
            onClick={() => handleOperation({ type: operationTypes.CANCEL })}
          >
            Cancel
          </Button>
          <Button type="submit" loading={operationInProgress} variant={Button.Variants.DANGER}>
            Add user
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
}

export default CreateUserForm;
