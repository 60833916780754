import { createSlice } from "@reduxjs/toolkit";
import { viewKeys } from "utils/constants";

const SLICE_KEY = "filters";

const INITIAL_STATE = {
  [viewKeys.LOGS]: {},
};

const filtersSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    setFilter: (state, { payload: { viewKey, filters } = {} }) => {
      state[viewKey] = filters;
    },
    removeSingleFilter: (state, { payload: { viewKey, filter } = {} }) => {
      state[viewKey].filters = state[viewKey].filters.filter((f) => f.column.value !== filter.column.value);
    },
  },
});

export const filtersSelector = ({ state, viewKey }) => state?.filters?.[viewKey] || {};

const { reducer } = filtersSlice;

export const { setFilter, removeSingleFilter } = filtersSlice.actions;

export default reducer;
