import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M12.74 20H7.26A7.26 7.26 0 010 12.74V7.26A7.26 7.26 0 017.26 0h5.48A7.26 7.26 0 0120 7.26v5.48A7.26 7.26 0 0112.74 20z"
        opacity={0.11}
        fill="#6c7897"
      />
      <path
        d="M14.62 8.702a4.773 4.773 0 00-2.271-2.887A4.767 4.767 0 008.7 5.38a4.764 4.764 0 00-2.886 2.272 4.766 4.766 0 00-.434 3.646 4.767 4.767 0 002.27 2.887 4.773 4.773 0 003.648.436 4.77 4.77 0 002.886-2.273 4.764 4.764 0 00.435-3.646zM9.134 6.92a3.204 3.204 0 013.945 2.214c.2.714.136 1.453-.155 2.124L7.948 7.766c-.047-.033-.1-.047-.151-.066.374-.36.823-.635 1.337-.78zm1.731 6.16a3.172 3.172 0 01-2.43-.29 3.178 3.178 0 01-1.514-1.925 3.162 3.162 0 01.168-2.153c.035.041.065.085.112.118l4.978 3.493a3.16 3.16 0 01-1.314.757z"
        fill="#6c7897"
      />
    </svg>
  );
}

export default SvgComponent;
