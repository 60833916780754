import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.919 10" {...props}>
      <path
        d="M5.961 3.445L7.92.617H1.284L1.29 0H0v10h1.17l.043-3.585h6.672l-1.924-2.97zm.05-1.828l-.968 1.398H1.255l.017-1.398h4.74zM1.242 4.015H5.14l.906 1.4h-4.82l.017-1.4z"
        fill="#ab76b3"
      />
    </svg>
  );
}

export default SvgComponent;
