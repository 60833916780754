import { theme } from "utils/theme";

import { Spinner } from "components/shared";

function LoadableValue({
  children,
  component: Component = "div",
  spinnerProps = {
    color: theme.colors.PRIMARY,
    scale: "0.13",
    alignLeft: false,
  },
}) {
  return <Component>{!children ? <Spinner {...spinnerProps} /> : children}</Component>;
}

export default LoadableValue;
