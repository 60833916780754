import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Spinner, Placeholder } from "components/shared";

function IconButton({
  onClick = () => {},
  type = "button",
  height,
  width,
  color,
  colorHover,
  loading = false,
  disabled = false,
  borderRadius = "14px",
  icon = <Placeholder width="20px" height="20px" />,
  ...props
}) {
  return (
    <Wrapper
      borderRadius={borderRadius}
      color={color}
      colorHover={colorHover}
      $width={width}
      $height={height}
      onClick={onClick}
      $loading={loading}
      disabled={disabled}
      type={type}
      {...props}
    >
      {loading ? <Spinner color={theme.colors.WHITE} /> : icon}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0;
  padding: 0;
  border-radius: ${({ borderRadius }) => borderRadius};
  appearance: none;
  cursor: pointer;
  min-height: ${({ $height }) => $height};
  max-height: ${({ $height }) => $height};
  min-width: ${({ $width }) => $width};
  max-width: ${({ $width }) => $width};
  white-space: nowrap;
  transition: all 0.3s;
  background-color: ${({ color }) => color};
  color: ${theme.colors.BUTTON_OPTION_SECONDARY_TEXT};

  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: ${({ colorHover }) => colorHover};
  }

  &:disabled {
    border: none;
    pointer-events: none;
  }
`;

export default IconButton;
