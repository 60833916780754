import { EditUserForm } from "Users/components";

function UserDetailsSettingsTab({ user }) {
  const currentUser = { ...user };
  if (user) {
    currentUser.email = user?.email;
    currentUser.is_active = user?.account.is_active;
    currentUser.active_until = user?.account.active_until;
    currentUser.resources_used = user?.account.resources_used;
    currentUser.resources_limits = user?.account.resources_limits;
    currentUser.subscriptions = user?.account.subscriptions;
    currentUser.plan_id = user?.account.plan_id;
  }
  return <EditUserForm initialValues={currentUser} />;
}

export default UserDetailsSettingsTab;
