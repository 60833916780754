/* eslint-disable react/destructuring-assignment */
import { forwardRef, useRef } from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import Creatable from "react-select/creatable";
import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Arrow, ArrowDropdown, Close, NoResults } from "components/icons";
import { Box } from "components/utils";
import { Message } from "components/utils/Typography";
import { Spinner } from "components/shared";

const controlStyles = {
  display: "flex",
  width: "100%",
  fontFamily: theme.fonts.RUBIK,
  fontSize: "12px",
  fontWeight: theme.fontWeights.MEDIUM,
  color: theme.colors.INPUT_LABEL_TEXT,
};

const baseStyles = {
  singleValue: (_, { isDisabled }) => ({
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
    color: isDisabled ? theme.colors.TEXT_DISABLED : theme.colors.INPUT_LABEL_TEXT,
  }),
  input: () => ({
    fontWeight: 600,
  }),
  container: (_, { isDisabled, selectProps }) => {
    const isFullWidth = selectProps.width === "100%";

    return {
      flexGrow: isFullWidth ? 1 : "unset",
      width: isFullWidth ? "unset" : selectProps.width,
      maxWidth: isFullWidth ? "unset" : selectProps.width,
      minWidth: isFullWidth ? "unset" : selectProps.width,
      cursor: isDisabled ? "default" : "pointer",
    };
  },
  control: () => ({
    ...controlStyles,
  }),
  indicatorsContainer: () => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "7px",
  }),
  indicatorSeparator: () => {},
  indicatorContainer: () => {},
  menu: (_, { selectProps }) => {
    const inverted = selectProps?.inverted;
    const big = selectProps?.big;

    const positioningProps = inverted
      ? {
          bottom: big ? "46px" : "40px",
          right: "-10px",
        }
      : { top: big ? "46px" : "40px", right: "-10px" };

    return {
      display: selectProps.options.length > 0 || selectProps.async ? "block" : "none",
      position: "absolute",
      zIndex: 9999,
      minWidth: `calc(${selectProps.selectWidth} + 20px)`,
      width: `calc(${selectProps.selectWidth} + 20px)`,
      ...positioningProps,
    };
  },
  menuList: (_, { selectProps }) => ({
    height: `${selectProps.menuHeight || "auto"}`,
    maxHeight: `${selectProps.menuHeight || "256px"}`,
    overflowY: "auto",
    padding: "10px 0",
    borderRadius: "9px",
    backgroundColor: theme.colors.TOOLTIP_BACKGROUND,
  }),
  option: (_, { isFocused }) => ({
    width: "100%",
    padding: isFocused ? "8px 20px 8px 40px" : "8px 20px",
    marginLeft: "0",
    cursor: "pointer",
    fontFamily: theme.fonts.RUBIK,
    fontSize: "12px",
    fontWeight: theme.fontWeights.MEDIUM,
    color: theme.colors.WHITE,
    backgroundColor: isFocused ? theme.colors.SELECT_INPUT_MENU_ITEM_HOVER_BACKGROUND : theme.colors.TOOLTIP_BACKGROUND,
    textAlign: "left",
  }),
  noOptionsMessage: (_, { selectProps }) => ({
    ...controlStyles,
    minWidth: `${selectProps.selectWidth}`,
    width: `${selectProps.selectWidth}`,
  }),
  loadingMessage: (_, { selectProps }) => ({
    ...controlStyles,
    minWidth: `${selectProps.selectWidth}`,
    width: `${selectProps.selectWidth}`,
  }),
  valueContainer: () => ({
    padding: "6px 14px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  }),
  placeholder: (_, { isFocused, selectProps, isDisabled }) => ({
    display: isFocused && selectProps?.isSearchable ? "none" : "block",
    color: isDisabled && theme.colors.TEXT_DISABLED,
  }),
};

const styles = {
  ...baseStyles,
  valueContainer: (_, { selectProps }) => ({
    padding: selectProps?.label ? "6px 16px" : "6px 10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  }),
  indicatorsContainer: (_, { selectProps }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: selectProps?.label ? "6px" : "0",
  }),
  control: (_, { selectProps, isFocused }) => ({
    ...controlStyles,
    height: selectProps?.big ? "42px" : "36px",
    borderRadius: "9px",
    backgroundColor: selectProps?.label ? "#f7f8fd" : "transparent",
    border: `2px solid ${isFocused && selectProps?.async ? theme.colors.INPUT_ACTIVE_BORDER : "transparent"}`,
  }),
  container: (_, { isDisabled, selectProps }) => {
    const isFullWidth = selectProps.width === "100%";

    return {
      flexGrow: isFullWidth ? 1 : "unset",
      width: isFullWidth ? "unset" : selectProps.width,
      maxWidth: isFullWidth ? "unset" : selectProps.width,
      minWidth: isFullWidth ? "unset" : selectProps.width,
      cursor: isDisabled ? "default" : "pointer",
      pointerEvents: isDisabled ? "none" : "auto",
      position: "relative",
    };
  },
  noOptionsMessage: () => ({ ...controlStyles, color: theme.colors.WHITE, cursor: "default" }),
};

export const DropdownIndicator = ({ selectProps, ...props }) => {
  const iconColor = selectProps?.menuIsOpen
    ? theme.colors.SELECT_INPUT_ARROW_OPEN_BACKGROUND
    : selectProps?.isDisabled
    ? theme.colors.TEXT_DISABLED
    : theme.colors.SELECT_INPUT_ARROW_CLOSED_BACKGROUND;

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator selectProps={selectProps} {...props}>
        {selectProps?.inverted ? (
          <ArrowDropdownInverted width="5px" height="8px" color={iconColor} />
        ) : (
          <ArrowDropdown width="5px" height="8px" color={iconColor} />
        )}
      </components.DropdownIndicator>
    )
  );
};

const ArrowUp = styled.div`
  position: absolute;
  right: ${({ label }) => (label ? "11px" : "5px")};
  top: ${({ big }) => (big ? "41px" : "35px")};
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${theme.colors.SELECT_INPUT_MENU_ARROW_BACKGROUND};
`;

const ArrowDown = styled(ArrowUp)`
  transform: rotate(180deg);
  bottom: ${({ big }) => (big ? "41px" : "35px")};
  top: unset;
`;

const FocusedItemWrapper = styled.div`
  width: 10px;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  margin-left: 20px;
  pointer-events: none;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const NoOptionsMessageWrapper = styled.div`
  padding-left: 20px;
  cursor: default;
  color: ${theme.colors.WHITE};
`;

export const Menu = ({ ...props }) => {
  const { inverted, label, async, isSearchable, inputValue, big } = props?.selectProps;

  if (async && isSearchable && !inputValue) {
    return components.Menu && null;
  }

  return (
    components.Menu && (
      <>
        {inverted ? (
          <div>
            <components.Menu {...props} />
            <ArrowDown big={big} />
          </div>
        ) : (
          <div>
            <ArrowUp big={big} label={label} />
            <components.Menu {...props} />
          </div>
        )}
      </>
    )
  );
};

const LoadingIndicator = (props) => <Spinner {...props} color={theme.colors.PRIMARY} scale={0.14} />;

const ClearIndicator = (props) =>
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <Close width={10} height={10} />
    </components.ClearIndicator>
  );

export const Option = ({ ...props }) => {
  const { isFocused } = props;

  return (
    components.Option && (
      <OptionWrapper>
        {isFocused && (
          <FocusedItemWrapper>
            <Arrow width="8px" height="5px" color={theme.colors.WHITE} />
          </FocusedItemWrapper>
        )}
        <components.Option {...props} />
      </OptionWrapper>
    )
  );
};

export const NoOptionsMessage = ({ ...props }) =>
  components.NoOptionsMessage && (
    <NoOptionsMessageWrapper>
      <components.NoOptionsMessage {...props} />
    </NoOptionsMessageWrapper>
  );

const NoOptionsMessageBigWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: auto;
  padding: 40px 0;
  flex-direction: column;
`;

export const LoadingMessageAsync = ({ ...props }) =>
  components.LoadingMessage && (
    <components.LoadingMessage {...props}>
      <NoOptionsMessageBigWrapper>
        <Box height="75px">
          <Spinner scale={0.35} color={theme.colors.NO_RESULTS_MESSAGE} />
        </Box>
        <Box mt="10px">
          <Message color={theme.colors.NO_RESULTS_MESSAGE}>Loading...</Message>
        </Box>
      </NoOptionsMessageBigWrapper>
    </components.LoadingMessage>
  );

export const NoOptionsMessageAsync = ({ ...props }) => {
  const { isSearchable, inputValue } = props?.selectProps;

  if (isSearchable && !inputValue) {
    return components.NoOptionsMessage && null;
  }

  return (
    components.NoOptionsMessage && (
      <components.NoOptionsMessage {...props}>
        <NoOptionsMessageBigWrapper>
          <NoResults width="110px" height="75px" />
          <Box mt="10px">
            <Message color={theme.colors.NO_RESULTS_MESSAGE}>No records available</Message>
          </Box>
        </NoOptionsMessageBigWrapper>
      </components.NoOptionsMessage>
    )
  );
};

const SelectInput = forwardRef(
  (
    {
      input,
      label,
      name,
      control,
      width,
      value,
      options = [],
      onChange,
      disabled,
      big = true,
      nullable = false,
      searchable = false,
      clearable = false,
      async = false,
      creatable = false,
      ...selectProps
    },
    // eslint-disable-next-line no-unused-vars
    ref,
  ) => {
    const selectRef = useRef();

    const defaultValue = typeof value === "string" ? options?.find((op) => op.value === value) : value;

    const InputComponent = async ? AsyncSelect : creatable ? Creatable : Select;

    if (control) {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={(props) => (
            <FormControl>
              {label && (
                <Label htmlFor={name}>
                  <span>{label}</span>
                  <span>:</span>
                </Label>
              )}
              <InputComponent
                async={async}
                big={big}
                ref={selectRef}
                name={name}
                defaultValue={defaultValue || (nullable ? null : options?.[0])}
                selectWidth={width}
                isSearchable={searchable || async || creatable}
                isClearable={clearable || async}
                isDisabled={disabled}
                width={width}
                options={options}
                styles={styles}
                components={{
                  DropdownIndicator,
                  Menu,
                  Option,
                  NoOptionsMessage: async ? NoOptionsMessageAsync : NoOptionsMessage,
                  LoadingMessage: async && LoadingMessageAsync,
                  LoadingIndicator,
                  ClearIndicator,
                }}
                {...props}
                {...selectProps}
                label={label}
                onChange={async (e) => {
                  if (props) {
                    await props.onChange(e);
                  }

                  if (onChange) {
                    onChange(e);
                  }
                }}
              />
            </FormControl>
          )}
        />
      );
    }

    return (
      <InputComponent
        {...input}
        {...selectProps}
        async={async}
        ref={selectRef}
        defaultValue={defaultValue || (nullable ? null : options?.[0])}
        selectWidth={width}
        isSearchable={searchable || async}
        isClearable={false}
        isDisabled={disabled}
        width={width}
        options={options}
        styles={styles}
        components={{
          DropdownIndicator,
          Menu,
          Option,
          NoOptionsMessage: async ? NoOptionsMessageAsync : NoOptionsMessage,
          LoadingMessage: async && LoadingMessageAsync,
          LoadingIndicator,
          ClearIndicator,
        }}
        onChange={async (e) => {
          if (input) {
            await input.onChange(e);
          }

          if (onChange) {
            onChange(e);
          }
        }}
      />
    );
  },
);

const FormControl = styled.div`
  margin-bottom: 18px;
`;

const ArrowDropdownInverted = styled(ArrowDropdown)`
  transform: rotate(180deg);
`;

const Label = styled.label`
  font-family: ${theme.fonts.RUBIK};
  display: block;
  font-size: ${theme.sizes.INPUT_LABEL_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 1.69;
  color: ${theme.colors.INPUT_LABEL_TEXT};
  margin-bottom: 10px;
`;

export default SelectInput;
