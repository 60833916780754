import { useMemo} from "react";
import { useHistory } from "react-router";

import { columnTypes} from "utils/constants";
import { useQueryParams } from "utils/hooks";
import { getMetaParams } from "utils/pagination";
import { routes } from "utils/routes";

import { NullableValue, Table, MenuDropdown} from "components/shared";

import { parseRoute } from "utils/formatters";

const PromptsTab = ({ prompts, isLoading, isFetching }) => {
  const { params } = useQueryParams();
  const history = useHistory();

  const headers = useMemo(
    () => [
      { label: "UID", value: "uid", type: columnTypes.ALPHA, sortable: true, maxWidth: "400px" },
      { label: "Type", value: "type", type: columnTypes.ALPHA, sortable: true, maxWidth: "150px" },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      prompts?.prompts?.map((prompt) => {
        return {
          options: {
            key: prompt?.uid,
            onRowClick: () => {
              history.push(
                parseRoute({
                  route: routes.PROMPT_EDIT,
                  params: {
                    id: prompt?.uid,
                  },
                }),
              );
            },
          },
          columns: {
            uid: <NullableValue>{prompt?.uid}</NullableValue>,
            type: <NullableValue>{prompt?.prompt_type}</NullableValue>,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "Edit",
                      value: "edit",
                      onClick: () => {
                        history.push(
                          parseRoute({
                            route: routes.PROMPT_EDIT,
                            params: {
                              id: prompt?.uid,
                            },
                          }),
                        );
                      },
                    },
                  ]}
                />
              </>
            ),
          },
        };
      }),
    [prompts],
  );


  return (
    <>
      <Table
        isLoading={isLoading}
        isRefetchLoading={isFetching}
        headers={headers}
        rows={rows}
        meta={getMetaParams({ meta: prompts?.meta, queryParams: params })}
        emptyStateText="No prompts available"
      />
    </>
  );
};

export default PromptsTab;
