import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="remove_icon__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 32.508 32.022"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".remove_icon__st1{fill:#db4b67}"}</style>
      <path
        d="M19.64 32.022h-6.772C5.79 32.022 0 26.232 0 19.154v-6.286C0 5.79 5.79 0 12.868 0h6.773c7.077 0 12.867 5.79 12.867 12.868v6.286c0 7.077-5.79 12.868-12.867 12.868z"
        fill="#f9e8ec"
      />
      <path
        className="remove_icon__st1"
        d="M14.476 15a.55.55 0 00-.55.55v3.947a.55.55 0 101.1 0V15.55a.55.55 0 00-.55-.55zM17.015 15.55v3.947a.55.55 0 101.1 0V15.55a.55.55 0 10-1.1 0z"
      />
      <path
        className="remove_icon__st1"
        d="M22.2 11.588H19.102c.005-.044.026-.083.026-.128v-1.043c0-.678-.538-1.228-1.2-1.228h-3.817c-.661 0-1.2.55-1.2 1.228v1.043c0 .045.021.084.026.128H9.842a.55.55 0 100 1.1h1.029c-.01.06-.038.115-.038.178v9.357c0 .705.597 1.278 1.33 1.278h7.845c.733 0 1.329-.573 1.329-1.278v-9.357c0-.063-.029-.117-.038-.178h.9a.55.55 0 100-1.1zm-8.188-.128v-1.043c0-.07.046-.129.1-.129h3.817c.054 0 .1.06.1.129v1.043c0 .069-.046.128-.1.128h-3.817c-.054 0-.1-.06-.1-.128zm6.225 1.406v9.357c0 .097-.105.178-.23.178h-7.844c-.125 0-.23-.081-.23-.178v-9.357c0-.096.105-.178.23-.178H20.008c.124 0 .23.082.23.178z"
      />
    </svg>
  );
}

export default SvgComponent;
