import * as React from "react";

const SvgComponent = ({ color = "#EB4537", ...props }) => (
  <svg width={92} height={74} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m20.61 0 12.837 10.138c-6.077 6.31-10.132 12.221-12.16 17.738-2.027 5.521-3.044 11.099-3.044 16.726l-8.11-5.744h24.67V74H0V49.33c0-9.233 1.517-17.849 4.56-25.848C7.6 15.493 12.95 7.66 20.61 0Zm56.767 0 12.838 10.138c-6.082 6.31-10.138 12.221-12.165 17.738-2.028 5.521-3.039 11.099-3.039 16.726l-8.11-5.744h24.664V74H56.762V49.33c0-9.233 1.523-17.849 4.561-25.848C64.367 15.493 69.717 7.66 77.377 0Z"
      fill={color}
    />
  </svg>
);

export default SvgComponent;
