import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["BlogAuthors", "BlogAuthor"],
});

const blogAuthorApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getBlogAuthors: build.query({
      query: ({ limit, offset, sortDir, sortField }) => ({
        url: "/v1/blog-authors",
        method: "GET",
        params: {
          limit,
          offset,
          sortDir,
          sortField,
        },
      }),
      providesTags: ["BlogAuthors"],
    }),
    getBlogAuthor: build.query({
      query: (uid) => `/v1/blog-authors/${uid}`,
      providesTags: (uid) => [{ type: "BlogAuthor", id: uid }],
    }),
    updateBlogAuthor: build.mutation({
      query({ uid, data }) {
        return {
          url: `/v1/blog-authors/${uid}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (uid) => [{ type: "BlogAuthor", id: uid }, { type: "BlogAuthors" }],
    }),
    createBlogAuthor: build.mutation({
      query(data) {
        return {
          url: `/v1/blog-authors`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (uid) => [{ type: "BlogAuthor", id: uid }, { type: "BlogAuthors" }],
    }),
    deleteBlogAuthor: build.mutation({
      query(uid) {
        return { url: `/v1/blog-authors/${uid}`, method: "DELETE" };
      },
      invalidatesTags: (uid) => [{ type: "BlogAuthor", id: uid }, { type: "BlogAuthors" }],
    }),
  }),
});

export const {
  useGetBlogAuthorsQuery,
  useGetBlogAuthorQuery,
  useUpdateBlogAuthorMutation,
  useCreateBlogAuthorMutation,
  useDeleteBlogAuthorMutation,
} = blogAuthorApi;
