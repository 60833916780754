import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.635 6.377" {...props}>
      <path
        fill="#249fd3"
        d="M8.281 2.446L5.197 3.83l-3.16-2.567 1.651-.142L3.595 0 .015.307l.004.056L0 .365.346 4.04l1.115-.137-.158-1.69 3.711 3.014L7.62 4.058l.897 2.32 1.119-.434z"
      />
    </svg>
  );
}

export default SvgComponent;
