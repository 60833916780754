import { useMemo, useState } from "react";
import { css } from "styled-components/macro";
import { useHistory } from "react-router";

import { columnTypes, modalKeys, postStatuses } from "utils/constants";
import { useQueryParams } from "utils/hooks";
import { getMetaParams } from "utils/pagination";
import toast, { toastIds } from "utils/toast";
import { routes } from "utils/routes";

import { Button } from "components/forms";
import { NullableValue, Table, MenuDropdown, ConfirmationModal } from "components/shared";
import { Remove, Success, Draft } from "components/icons";
import { Box } from "components/utils";

import { parseRoute } from "utils/formatters";
import { useDeleteBlogPostMutation, useUpdateBlogPostMutation } from "api/services/blogPost";
import { format, parseISO } from "date-fns";

const BlogPostsTab = ({ posts, isLoading, isFetching }) => {
  const { params } = useQueryParams();
  const history = useHistory();

  const [postToRemoveId, setPostToRemoveId] = useState(null);

  const [deletePost] = useDeleteBlogPostMutation();
  const [updatePost] = useUpdateBlogPostMutation();

  const headers = useMemo(
    () => [
      { label: "Title", value: "title", type: columnTypes.ALPHA, sortable: true, maxWidth: "400px" },
      { label: "Category", value: "category", type: columnTypes.ALPHA, sortable: true, maxWidth: "150px" },
      { label: "Last change", value: "updated_at", type: columnTypes.NUMERIC, sortable: true, maxWidth: "150px" },
      { label: "Author", value: "author.name", type: columnTypes.ALPHA, sortable: true, maxWidth: "200px" },
      { label: "Status", value: "status", type: columnTypes.ALPHA, sortable: true, maxWidth: "150px" },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      posts?.blog_posts?.map((post) => {
        const date = format(parseISO(post?.updated_at || post?.created_at), "dd MMM yyyy");

        return {
          options: {
            key: post?.uid,
            onRowClick: () => {
              history.push(
                parseRoute({
                  route: routes.BLOG_EDIT,
                  params: {
                    id: post?.uid,
                  },
                }),
              );
            },
          },
          columns: {
            title: <NullableValue>{post?.title}</NullableValue>,
            category: <NullableValue>{post?.category}</NullableValue>,
            created_at: <NullableValue>{date}</NullableValue>,
            author: <NullableValue>{post?.author?.name}</NullableValue>,
            status: (
              <NullableValue>
                {post?.status === postStatuses.DRAFT ? (
                  <>
                    <Draft width="18px" height="18px" />
                    <Box flex ml="10px" alignCenter>
                      Draft
                    </Box>
                  </>
                ) : (
                  <>
                    <Success width="18px" height="18px" />
                    <Box flex ml="10px" alignCenter>
                      Published
                    </Box>
                  </>
                )}
              </NullableValue>
            ),
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "Edit",
                      value: "edit",
                      onClick: () => {
                        history.push(
                          parseRoute({
                            route: routes.BLOG_EDIT,
                            params: {
                              id: post?.uid,
                            },
                          }),
                        );
                      },
                    },
                    {
                      label: post?.status === postStatuses.DRAFT ? "Publish" : "Unpublish",
                      value: "publish",
                      onClick: () => {
                        updatePost({
                          uid: post?.uid,
                          data: {
                            status: post?.status === postStatuses.DRAFT ? postStatuses.PUBLISHED : postStatuses.DRAFT,
                          },
                        });
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setPostToRemoveId(post?.uid);
                      },
                    },
                  ]}
                />
              </>
            ),
          },
        };
      }),
    [posts],
  );

  const handlePostRemove = async () => {
    if (postToRemoveId) {
      try {
        await deletePost(postToRemoveId).unwrap();

        toast.success("Post removed successfully.", {
          toastId: toastIds.SUCCESS_REMOVE_POST,
        });
      } catch (error) {
        toast.error("An error occurred while removing post. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_POST,
        });
      }
    }
    setPostToRemoveId(null);
  };

  return (
    <>
      <Button
        type="button"
        variant={Button.Variants.ACTION}
        onClick={() => {
          history.push(
            parseRoute({
              route: routes.BLOG_CREATE,
            }),
          );
        }}
        css={css`
          margin-left: auto;
          display: flex;
        `}
      >
        Add post
      </Button>
      <Table
        isLoading={isLoading}
        isRefetchLoading={isFetching}
        headers={headers}
        rows={rows}
        meta={getMetaParams({ meta: posts?.meta, queryParams: params })}
        emptyStateText="No posts available"
      />
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected post?"
        id={modalKeys.UI_ID_REMOVE_POST_MODAL}
        isOpen={postToRemoveId}
        onConfirm={handlePostRemove}
        onCancel={() => {
          setPostToRemoveId(null);
        }}
      />
    </>
  );
};

export default BlogPostsTab;
