import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { InputGroup } from "components/forms";
import { BorderLine } from "components/shared";

function FormGroup({ children, title, noBorder }) {
  return (
    <Wrapper noBorder={noBorder}>
      {title && <Title>{title}</Title>}
      <InputContainer noBorder={noBorder}>
        <InputGroup>{children}</InputGroup>
      </InputContainer>
      {!noBorder && <BorderLine />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: ${({ noBorder }) => (noBorder ? "10px" : "30px")};
`;

const InputContainer = styled.div`
  margin-bottom: ${({ noBorder }) => (noBorder ? "0" : "20px")};
`;

const Title = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.INPUT_GROUP_LABEL_TEXT};
  font-weight: ${theme.fontWeights.BOLD};
  line-height: 1.69;
  color: ${theme.colors.INPUT_GROUP_LABEL_TEXT};
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export default FormGroup;
