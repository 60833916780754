import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="users_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 10.857 16"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".users_icon_st0{fill:#8794b2}"}</style>
      <path
        className="users_icon_st0"
        d="M5.622 6.046c-1.594 0-2.892-1.356-2.892-3.023S4.028 0 5.622 0c1.593 0 2.89 1.356 2.89 3.023s-1.297 3.023-2.89 3.023zm0-4.674c-.839 0-1.52.74-1.52 1.65s.681 1.651 1.52 1.651c.837 0 1.517-.74 1.517-1.65s-.68-1.65-1.517-1.65zM6.358 16h-1.94c-1.017 0-1.722-.397-1.811-1l-.941-3.179C.77 11.737.23 11.35.23 10.77V7.443c0-.66.698-1.07 1.822-1.07H3.78c.2 0 .392.089.522.242.076.07.481.236 1.154.236s1.079-.167 1.16-.243a.67.67 0 01.517-.235h1.729c1.123 0 1.822.41 1.822 1.07v3.326c0 .627-.63 1.028-1.656 1.067l-.86 3.154C8.089 15.599 7.38 16 6.359 16zm-2.451-1.44c.102.033.278.068.51.068h1.94c.231 0 .405-.035.508-.067l.977-3.588a.687.687 0 01.662-.506h.359c.186 0 .34-.019.45-.041v-2.64a2.286 2.286 0 00-.45-.04H7.385c-.438.303-1.127.477-1.928.477s-1.49-.174-1.928-.478H2.052c-.187 0-.34.02-.45.042v2.639c.11.022.263.04.45.04h.132c.304 0 .57.2.657.492l1.066 3.602zm5.594-6.716h.01-.01z"
      />
    </svg>
  );
}

export default SvgComponent;
