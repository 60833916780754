import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods, httpStatuses } from "utils/constants";
import { merge } from "utils/formatters";

const SLICE_KEY = "projects";

const projectsSchema = new schema.Entity("projects", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_PROJECTS: "projects/getProjects",
  GET_PROJECT: "projects/getProject",
  CREATE_PROJECT: "projects/createProject",
  REMOVE_PROJECT: "projects/removeProject",
};

const endpoints = {
  GET_PROJECTS: `/v1/projects`,
  GET_PROJECT: ({ id }) => `/v1/projects/${id}`,
  REMOVE_PROJECT: ({ id }) => `/v1/projects/${id}`,
  UPDATE_PROJECT: ({ id }) => `/v1/projects/${id}`,
  CREATE_PROJECT: `/v1/projects`,
};

export const projectsSelector = createSelector(
  ({ projects }) => projects,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const getProjects = createAsyncThunk(actionTypes.GET_PROJECTS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_PROJECTS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_PROJECTS,
    thunkApi,
    params,
    schema: { projects: [projectsSchema] },
  }),
);

export const getProject = createAsyncThunk(actionTypes.GET_PROJECT, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_PROJECT,
    method: httpMethods.GET,
    endpoint: endpoints.GET_PROJECT({ id }),
    thunkApi,
    errorHandler: ({ status }) => {
      if (status === httpStatuses.NOT_FOUND) {
        return "Project not found";
      }

      return null;
    },
  }),
);

export const removeProject = createAsyncThunk(actionTypes.REMOVE_PROJECT, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.REMOVE_PROJECT,
    method: httpMethods.DELETE,
    endpoint: endpoints.REMOVE_PROJECT({ id }),
    thunkApi,
  }),
);

export const updateProject = createAsyncThunk(actionTypes.UPDATE_PROJECT, async ({ id, data } = {}, thunkApi) =>
  request({
    actionType: actionTypes.UPDATE_PROJECT,
    method: httpMethods.PATCH,
    endpoint: endpoints.UPDATE_PROJECT({ id }),
    data,
    thunkApi,
  }),
);

export const createProject = createAsyncThunk(actionTypes.CREATE_PROJECT, async ({ data } = {}, thunkApi) =>
  request({
    actionType: actionTypes.CREATE_PROJECT,
    method: httpMethods.POST,
    endpoint: endpoints.CREATE_PROJECT,
    data,
    thunkApi,
  }),
);

const projectsSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.projects;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.projects;
    });
    builder.addCase(getProject.fulfilled, (state, { payload }) => {
      state.ids = merge({ array: state.ids, element: payload?.uid });
      state.entities = { ...state.entities, [payload?.uid]: payload };
    });
    builder.addCase(getProject.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = projectsSlice;

export default reducer;
