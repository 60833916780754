/* eslint-disable react/no-array-index-key */
import styled from "styled-components/macro";

import { theme } from "utils/theme";

function SummaryGrid({ items = [] }) {
  return (
    <Wrapper itemsCount={items.length}>
      {items.map((item, idx) => (
        <GridItem key={idx}>{item}</GridItem>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GridItem = styled.div`
  padding: 0 30px;

  &:not(:last-child) {
    border-right: 1px solid ${theme.colors.SUMMARY_ITEM_SEPARATOR};
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export default SummaryGrid;
