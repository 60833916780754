import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { toggleTypes } from "utils/constants";
import { theme } from "utils/theme";

import { OptionButton } from "components/forms";
import { Close } from "components/icons";

import { filtersSelector, removeSingleFilter, setFilter } from "Filters/redux/filtersSlice";

function AdvancedFiltersDisplay({ filterConfig, viewKey }) {
  const dispatch = useDispatch();

  const { filters: currentFilters } = useSelector((state) => filtersSelector({ state, viewKey }));

  const defaultFilters = filterConfig?.[viewKey]?.defaultFilters;

  return (
    <Wrapper>
      {currentFilters?.map((filter) => {
        const hasSorting = filter?.sorting?.value !== toggleTypes.OFF;
        const hasFiltering = filter?.filterEnabled?.value !== toggleTypes.OFF;

        return (
          (hasSorting || hasFiltering) && (
            <SingleFilterWrapper key={JSON.stringify(filter)}>
              <Label color={theme.colors.PRIMARY}>Column:&nbsp;</Label>
              <Label>{`${filter?.column?.label}`}</Label>
              <span>,&nbsp;</span>

              {hasSorting && (
                <>
                  <Label>{`Sorting: ${filter?.sorting?.label}`}</Label>
                  {hasFiltering && <span>,&nbsp;</span>}
                </>
              )}

              {hasFiltering && (
                <>
                  <Label>{`Filtering: ${filter?.filterType?.label} "${filter?.filterValue}"`}</Label>
                </>
              )}

              <RemoveFilterButtonWrapper>
                <OptionButton
                  color="transparent"
                  icon={<Close width="10px" height="10px" />}
                  onClick={() => {
                    if (currentFilters.length > 1) {
                      dispatch(removeSingleFilter({ viewKey, filter }));
                    } else {
                      dispatch(setFilter({ viewKey, filters: defaultFilters }));
                    }
                  }}
                />
              </RemoveFilterButtonWrapper>
            </SingleFilterWrapper>
          )
        );
      })}
    </Wrapper>
  );
}

const RemoveFilterButtonWrapper = styled.div`
  margin-right: 16px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const SingleFilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.ADVANCED_FILTER_LABEL};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 1.83;
  color: ${({ color }) => color};
`;

export default AdvancedFiltersDisplay;
