import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { UserDetails } from "components/icons";

function UserIndicator({ user }) {
  if (!user) {
    return null;
  }

  return (
    <Wrapper>
      <UserDetails width="11px" height="13px" />
      <UserEmail>{user?.email}</UserEmail>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserEmail = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.DOMAIN_NAME};
  line-height: 1.22;
  color: ${theme.colors.TEXT_DARK};
  margin-left: 10px;
`;

export default UserIndicator;
