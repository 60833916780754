import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods } from "utils/constants";

const SLICE_KEY = "serpReports";

const serpReportsSchema = new schema.Entity("serpReports", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_SERP_REPORTS: "serpReports/getSerpReports",
  REMOVE_SERP_REPORT: "serpReports/removeSerpReport",
  ON_DEMAND_SERP_REPORT: "serpReports/onDemandSerpReport",
  REPARSE_SERP_REPORT: "serpReports/ReparseSerpReport",
};

const endpoints = {
  GET_SERP_REPORTS: `/v1/serp-reports`,
  REMOVE_SERP_REPORT: ({ id }) => `/v1/serp-reports/${id}`,
  REPARSE_SERP_REPORT: ({ id }) => `/v1/serp-reports/${id}/reparse`,
  // eslint-disable-next-line camelcase
  ON_DEMAND_SERP_REPORT: ({ project_id }) => `/v1/projects/${project_id}/on-demand-serp-analysis`,
};

export const serpReportsSelector = createSelector(
  ({ serpReports }) => serpReports,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const getSerpReports = createAsyncThunk(actionTypes.GET_SERP_REPORTS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_SERP_REPORTS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_SERP_REPORTS,
    thunkApi,
    params,
    schema: { serpReports: [serpReportsSchema] },
  }),
);

export const removeSerpReport = createAsyncThunk(actionTypes.REMOVE_SERP_REPORT, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.REMOVE_SERP_REPORT,
    method: httpMethods.DELETE,
    endpoint: endpoints.REMOVE_SERP_REPORT({ id }),
    thunkApi,
  }),
);

export const onDemandSerpReport = createAsyncThunk(
  actionTypes.ON_DEMAND_SERP_REPORT,
  // eslint-disable-next-line camelcase
  async ({ project_id } = {}, thunkApi) =>
    request({
      actionType: actionTypes.ON_DEMAND_SERP_REPORT,
      method: httpMethods.PUT,
      endpoint: endpoints.ON_DEMAND_SERP_REPORT({ project_id }),
      thunkApi,
    }),
);

export const reparseSerpReport = createAsyncThunk(
  actionTypes.REPARSE_SERP_REPORT,
  // eslint-disable-next-line camelcase
  async ({ id } = {}, thunkApi) =>
    request({
      actionType: actionTypes.REPARSE_SERP_REPORT,
      method: httpMethods.PUT,
      endpoint: endpoints.REPARSE_SERP_REPORT({ id }),
      thunkApi,
    }),
);

const serpReportsSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getSerpReports.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.serpReports;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.serpReports;
    });
    builder.addCase(getSerpReports.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = serpReportsSlice;

export default reducer;
