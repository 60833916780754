import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
      <path
        d="M9 0a9 9 0 100 18A9 9 0 109 0zm-.793 12.632L5.36 9.062l1.564-1.248 1.429 1.792 4.598-4.752 1.437 1.39-6.18 6.388z"
        fill="#40c1d4"
      />
    </svg>
  );
}

export default SvgComponent;
