import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="logs_icon_Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 18.839 17.206"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".logs_icon_st0{fill:#8794b2}"}</style>
      <path
        className="logs_icon_st0"
        d="M9.401 1.4c.644 0 1.289.318 1.658.955l6.117 10.552c.74 1.276-.18 2.874-1.655 2.877l-12.196.022h-.004c-1.473 0-2.395-1.594-1.66-2.872L7.74 2.361A1.899 1.899 0 019.4 1.4m0-1.4a3.282 3.282 0 00-2.875 1.663L.446 12.236a3.283 3.283 0 00.005 3.314 3.283 3.283 0 002.87 1.656l12.203-.022a3.283 3.283 0 002.869-1.663 3.283 3.283 0 00-.006-3.317L12.27 1.654A3.283 3.283 0 009.401 0z"
      />
      <path
        className="logs_icon_st0"
        d="M9.416 11.966a.67.67 0 01.493.202.662.662 0 01.203.486.672.672 0 01-.203.493.672.672 0 01-.493.202.664.664 0 01-.486-.202.671.671 0 01-.202-.493c0-.189.067-.35.202-.486a.663.663 0 01.486-.202m0-1c-.456 0-.868.171-1.192.495a1.654 1.654 0 00-.496 1.193c0 .461.171.876.495 1.2.325.324.738.495 1.193.495.46 0 .875-.17 1.199-.494.325-.325.497-.74.497-1.201 0-.456-.172-.868-.496-1.193a1.664 1.664 0 00-1.2-.495zM10.111 6.45l-.164 2.663H8.893L8.728 6.45h1.383m1.064-1h-3.51l.065 1.062.165 2.662.058.939h2.934l.058-.939.164-2.662.066-1.062z"
      />
    </svg>
  );
}

export default SvgComponent;
