import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { columnTypes, modalKeys, tabKeys } from "utils/constants";
import { parseRoute } from "utils/formatters";
import { usePageLoading, useQueryParams } from "utils/hooks";
import { getMetaParams, getPaginationParams } from "utils/pagination";
import { routes } from "utils/routes";
import toast, { toastIds } from "utils/toast";

import { Remove } from "components/icons";
import { ConfirmationModal, DateTimeValue, MenuDropdown, NullableValue, NumericValue, Table } from "components/shared";

import { pendingActionsSelector } from "App/redux/appSlice";
import { actionTypes, getProjects, projectsSelector, removeProject } from "Projects/redux/projectsSlice";

function UserDetailsProjectsTab({ user }) {
  const { initialLoading } = usePageLoading();

  const { params } = useQueryParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const { ids: projectIds, entities: projectsEntities, meta: projectsMeta } = useSelector(projectsSelector);
  const loadingProjects = useSelector((state) => pendingActionsSelector({ state, actions: actionTypes.GET_PROJECTS }));

  useEffect(() => {
    dispatch(
      getProjects({
        params: {
          ...getPaginationParams({ params }),
          accountUID: user?.account_uid,
          admin: "true",
        },
      }),
    );
  }, [dispatch, params]);

  const [projectToRemoveId, setProjectToRemoveId] = useState(null);

  const headers = useMemo(
    () => [
      { label: "Domain", value: "domain", type: columnTypes.ALPHA, sortable: true },
      { label: "Keywords count", value: "keywords_count", type: columnTypes.NUMERIC, sortable: true },
      { label: "Competitors count", value: "competitors_count", type: columnTypes.NUMERIC, sortable: false },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    [],
  );

  const rows = useMemo(() => {
    return projectIds?.map((id) => {
      const project = projectsEntities?.[id];

      return {
        options: {
          key: id,
          onRowClick: () => {
            history.push(
              parseRoute({
                route: routes.PROJECT_DETAILS,
                params: {
                  id,
                },
              }),
            );
          },
        },
        columns: {
          domain: <NullableValue>{project?.domain}</NullableValue>,
          keywords_count: <NumericValue>{project?.keywords_count}</NumericValue>,
          competitors_count: <NumericValue>{project?.competitors?.length}</NumericValue>,
          created_at: <DateTimeValue date={project?.created_at} />,
          actions: (
            <>
              <MenuDropdown
                options={[
                  {
                    label: "Edit",
                    value: "edit",
                    onClick: () => {
                      history.push(
                        parseRoute({
                          route: routes.PROJECT_DETAILS,
                          params: {
                            id,
                          },
                          queryParams: {
                            tab: tabKeys.PROJECT_DETAILS_SETTINGS,
                          },
                        }),
                      );
                    },
                  },
                  {
                    label: "Remove",
                    value: "remove",
                    onClick: () => {
                      setProjectToRemoveId(id);
                    },
                  },
                ]}
              />
            </>
          ),
        },
      };
    });
  }, [projectIds]);

  const handleProjectRemove = async () => {
    if (projectToRemoveId) {
      try {
        const rs = await dispatch(removeProject({ id: projectToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Project removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_PROJECT,
          });

          dispatch(
            getProjects({
              params: {
                ...getPaginationParams({ params }),
                accountUID: user?.account_uid,
                admin: "true",
              },
            }),
          );
        } else {
          toast.error("An error occurred while removing project. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_PROJECT,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing project. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_PROJECT,
        });
      }
    }

    setProjectToRemoveId(null);
  };

  return (
    <>
      <Table
        isLoading={!projectIds}
        isRefetchLoading={loadingProjects || initialLoading}
        headers={headers}
        rows={rows}
        meta={getMetaParams({ meta: projectsMeta, queryParams: params })}
        emptyStateText="No projects available"
      />
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected project?"
        id={modalKeys.UI_ID_REMOVE_PROJECT_MODAL}
        isOpen={projectToRemoveId}
        onConfirm={handleProjectRemove}
        onCancel={() => {
          setProjectToRemoveId(null);
        }}
      />
    </>
  );
}

export default UserDetailsProjectsTab;
