import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Box, Container } from "components/utils";
import { BorderLine, TriangleArrow } from "components/shared";

function TabNavigation({ tabs = [], selectedTab, goToTab, OptionsComponent }) {
  return (
    <Wrapper>
      <Container margin="auto">
        <Box flex alignCenter justifyBetween>
          <TabWrapper>
            {tabs?.map((tab) => {
              const isTabActive = selectedTab?.key === tab?.key;

              return (
                <Tab active={isTabActive} onClick={() => goToTab(tab)} key={tab?.key}>
                  {tab?.label}
                  <TriangleArrow active={isTabActive} />
                </Tab>
              );
            })}
          </TabWrapper>
          {OptionsComponent && <OptionsComponent />}
        </Box>
      </Container>
      <BorderLine />
    </Wrapper>
  );
}

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Tab = styled.div`
  position: relative;
  font-size: 13px;
  line-height: 1.69;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${({ active }) => (active ? theme.colors.ACTIVE_TAB_TEXT : theme.colors.INACTIVE_TAB_TEXT)};
  padding: 16px 0;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.ACTIVE_TAB_TEXT};
    text-decoration: underline;
  }

  &:first-child {
    padding-right: 18px;

    .triangleArrowComponent {
      left: 26%;
    }
  }

  &:not(:first-child) {
    padding-right: 18px;
    padding-left: 18px;

    .triangleArrowComponent {
      left: 42%;
    }
  }
`;

export default TabNavigation;
