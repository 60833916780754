import { useMemo } from "react";

import { columnTypes } from "utils/constants";

import { Table, NullableValue } from "components/shared";

function ProjectDetailsCompetitionTab({ project }) {
  const { competitors } = project;

  const headers = useMemo(
    () => [{ label: "Competitor", value: "competitor", type: columnTypes.ALPHA, sortable: false }],
    [],
  );

  const rows = useMemo(
    () =>
      competitors?.map((competitor) => {
        return {
          options: {
            key: competitor,
          },
          columns: {
            competitor: <NullableValue>{competitor}</NullableValue>,
          },
        };
      }),
    [competitors],
  );

  return (
    <div>
      <Table isLoading={!competitors} headers={headers} rows={rows} emptyStateText="No competitors available" />
    </div>
  );
}

export default ProjectDetailsCompetitionTab;
