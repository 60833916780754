import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="plan_silver_icon__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 36.587 34.983"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".plan_silver_icon__st1{fill:#f43f27}.plan_silver_icon__st2{fill:#ed6f5e}"}</style>
      <path
        d="M29.793 20.122l-1.696 1.654a1.113 1.113 0 00-.32.984l1.735 10.118a1.113 1.113 0 01-1.614 1.173l-9.087-4.777a1.113 1.113 0 00-1.035 0l-9.087 4.777a1.113 1.113 0 01-1.614-1.173L8.81 22.76c.062-.36-.057-.729-.32-.984L1.14 14.61a1.113 1.113 0 01.616-1.898l10.159-1.476c.362-.052.676-.28.838-.608l4.543-9.206a1.113 1.113 0 011.995 0l4.543 9.206c.162.328.476.556.838.608l10.16 1.476a1.113 1.113 0 01.616 1.898l-2.171 2.116"
        opacity={0.49}
        fill="#f9d4d2"
      />
      <path
        className="plan_silver_icon__st1"
        d="M8.17 34.983c-.396 0-.788-.124-1.124-.368a1.902 1.902 0 01-.76-1.873L8.02 22.625a.31.31 0 00-.09-.275L.58 15.184a1.903 1.903 0 01-.485-1.962 1.902 1.902 0 011.546-1.303l10.158-1.476a.311.311 0 00.235-.17l4.543-9.206C16.9.41 17.56 0 18.293 0s1.393.409 1.717 1.067l4.543 9.206a.311.311 0 00.235.17l10.158 1.476c.727.105 1.32.605 1.546 1.303.226.699.04 1.45-.485 1.962l-2.17 2.116a.801.801 0 11-1.12-1.148l2.17-2.116a.3.3 0 00.08-.319.298.298 0 00-.25-.211l-10.16-1.476a1.916 1.916 0 01-1.442-1.048l-4.543-9.205c-.074-.151-.207-.173-.279-.173s-.204.022-.279.173l-4.543 9.205a1.916 1.916 0 01-1.44 1.048L1.87 13.506a.3.3 0 00-.251.211.3.3 0 00.079.32L9.05 21.2c.451.44.657 1.074.55 1.695L7.866 33.014a.3.3 0 00.123.304c.06.042.18.102.328.023l9.086-4.777a1.915 1.915 0 011.782 0l9.087 4.777c.15.079.27.02.327-.023a.3.3 0 00.124-.304l-1.735-10.118c-.107-.621.1-1.255.55-1.695l1.696-1.653a.802.802 0 011.12 1.148l-1.696 1.654a.311.311 0 00-.09.275l1.735 10.118a1.902 1.902 0 01-.761 1.872 1.905 1.905 0 01-2.016.146l-9.087-4.777a.31.31 0 00-.29 0l-9.085 4.777a1.92 1.92 0 01-.893.222z"
      />
      <path
        className="plan_silver_icon__st1"
        d="M15.253 25.273c-.306 0-.61-.096-.869-.284a1.47 1.47 0 01-.589-1.448l.495-2.887-2.108-2.078a1.472 1.472 0 01-.375-1.518 1.47 1.47 0 011.195-1.008l2.9-.42 1.324-2.648c.251-.509.76-.825 1.327-.825.568 0 1.077.316 1.328.825l1.296 2.627 2.928.441c.561.082 1.02.468 1.195 1.008s.031 1.122-.375 1.518l-2.098 2.045.485 2.92a1.47 1.47 0 01-.59 1.448 1.471 1.471 0 01-1.558.112l-2.594-1.363-2.627 1.364a1.483 1.483 0 01-.69.171zm3.3-2.982c.237 0 .474.056.69.17l2.593 1.363-.442-2.926c-.081-.482.078-.971.425-1.31l2.099-2.046-2.92-.485a1.48 1.48 0 01-1.115-.81l-1.295-2.626c-.006-.011-.011-.021-.035-.021l-1.33 2.648c-.215.437-.632.74-1.115.81l-2.897.42 2.076 2.11c.35.34.508.83.425 1.31l-.495 2.887 2.648-1.325a1.48 1.48 0 01.688-.17z"
      />
      <circle className="plan_silver_icon__st2" cx={18.095} cy={8.714} r={0.914} />
      <path className="plan_silver_icon__st2" d="M28.985 16.253a.914.914 0 11-1.828 0 .914.914 0 011.828 0z" />
      <circle className="plan_silver_icon__st2" cx={25.024} cy={28.667} r={0.914} />
      <circle className="plan_silver_icon__st2" cx={11.278} cy={27.944} r={0.914} />
      <path className="plan_silver_icon__st2" d="M8.423 15.53a.914.914 0 11-1.829 0 .914.914 0 011.829 0z" />
    </svg>
  );
}

export default SvgComponent;
