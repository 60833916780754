import styled from "styled-components/macro";

import { theme } from "utils/theme";

function TriangleArrow({ active }) {
  return <Wrapper active={active} className="triangleArrowComponent" />;
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 7px solid ${theme.colors.ACTIVE_TAB_INDICATOR};
  display: ${({ active }) => (active ? "block" : "none")};
`;

export default TriangleArrow;
