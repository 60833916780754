import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods, httpStatuses } from "utils/constants";
import { getOptionsFromEntities, merge } from "utils/formatters";

const SLICE_KEY = "users";

const usersSchema = new schema.Entity("users", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_USERS: "users/getUsers",
  GET_USER: "users/getUser",
  REMOVE_USER: "users/removeUser",
  UPDATE_USER: "users/updateUser",
  CREATE_USER: "users/createUser",
};

const endpoints = {
  GET_USERS: `/v1/users`,
  GET_USER: ({ id }) => `/v1/users/${id}`,
  REMOVE_USER: ({ id }) => `/v1/users/${id}`,
  UPDATE_USER: ({ id }) => `/v1/users/${id}`,
  CREATE_USER: `/v1/users`,
};

export const usersSelector = createSelector(
  ({ users }) => users,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const userOptionsSelector = createSelector(
  ({ users }) => users,
  ({ entities }) => ({
    userOptions: getOptionsFromEntities({ entities, labelProp: "email", valueProp: "uid" }),
  }),
);

export const getUsers = createAsyncThunk(actionTypes.GET_USERS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_USERS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_USERS,
    thunkApi,
    params,
    schema: { users: [usersSchema] },
  }),
);

export const getUser = createAsyncThunk(actionTypes.GET_USER, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_USER,
    method: httpMethods.GET,
    endpoint: endpoints.GET_USER({ id }),
    thunkApi,
    errorHandler: ({ status }) => {
      if (status === httpStatuses.NOT_FOUND) {
        return "User not found";
      }

      return null;
    },
  }),
);

export const removeUser = createAsyncThunk(actionTypes.REMOVE_USER, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.REMOVE_USER,
    method: httpMethods.DELETE,
    endpoint: endpoints.REMOVE_USER({ id }),
    thunkApi,
  }),
);

export const updateUser = createAsyncThunk(actionTypes.UPDATE_USER, async ({ id, data } = {}, thunkApi) =>
  request({
    actionType: actionTypes.UPDATE_USER,
    method: httpMethods.PATCH,
    endpoint: endpoints.UPDATE_USER({ id }),
    data,
    thunkApi,
  }),
);

export const createUser = createAsyncThunk(actionTypes.CREATE_USER, async ({ data } = {}, thunkApi) =>
  request({
    actionType: actionTypes.CREATE_USER,
    method: httpMethods.POST,
    endpoint: endpoints.CREATE_USER,
    data,
    thunkApi,
  }),
);

const authSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.users;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.users;
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.ids = merge({ array: state.ids, element: payload?.uid });
      state.entities = { ...state.entities, [payload?.uid]: payload };
    });
    builder.addCase(getUser.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = authSlice;

export default reducer;
