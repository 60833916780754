import cookie from "react-cookies";

import { SEODITY_AUTH_TOKEN_ADMIN_KEY } from "utils/constants";

export const getAuthToken = () => cookie.load(SEODITY_AUTH_TOKEN_ADMIN_KEY) || null;

export const setAuthToken = ({ token, expire }) =>
  cookie.save(SEODITY_AUTH_TOKEN_ADMIN_KEY, token, {
    expires: new Date(expire),
  });

export const unsetAuthToken = () => cookie.remove(SEODITY_AUTH_TOKEN_ADMIN_KEY);

export const getUserIdFromToken = ({ token } = {}) => JSON.parse(atob(token?.split(".")[1]));
