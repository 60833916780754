import styled from "styled-components/macro";

import { capitalize, getPlanIcon } from "utils/formatters";

function PlanIndicator({ planId } = {}) {
  const PlanIcon = getPlanIcon({ planId });

  return (
    <Wrapper>
      {PlanIcon ? (
        <PlanIconWrapper>
          <PlanIcon />
        </PlanIconWrapper>
      ) : null}
      {capitalize({ string: planId })}
    </Wrapper>
  );
}

const PlanIconWrapper = styled.div`
  margin-right: 10px;

  svg {
    min-width: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default PlanIndicator;
