import styled from "styled-components/macro";
import ReactModal from "react-modal";

import { theme } from "utils/theme";

import { CloseButton } from "components/shared";

const modalContentStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  minWidth: "800px",
  maxWidth: "800px",
  height: "fit-content",
  backgroundColor: theme.colors.WHITE,
  borderRadius: "9px",
  border: "none",
  padding: "30px",
  overflow: "auto",
  boxShadow: "0 1px 20px rgba(0,0,0,0.02), 0 1px 20px rgba(0,0,0,0.10)",
};

function Modal({ children, isOpen, onRequestClose, id, title, width, height }) {
  const widthStyles = width
    ? {
        width,
        minWidth: width,
        maxWidth: width,
      }
    : {};
  const heightStyles = height
    ? {
        minHeight: height,
        maxHeight: "calc(100vh - 20px)",
      }
    : {};

  const modalStyles = {
    overlay: {
      backgroundColor: theme.colors.MODAL_BACKDROP,
      zIndex: 1000,
    },
    content: {
      ...modalContentStyles,
      ...widthStyles,
      ...heightStyles,
    },
  };

  return (
    <ReactModal
      id={id}
      appElement={document.getElementById("root")}
      style={modalStyles}
      isOpen={Boolean(isOpen)}
      onRequestClose={onRequestClose}
    >
      {title && <Title>{title}</Title>}
      <div>{children}</div>
      <CloseButton onClick={onRequestClose} />
    </ReactModal>
  );
}

const Title = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.MODAL_TITLE};
  font-weight: ${theme.fontWeights.MEDIUM};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: ${theme.colors.MODAL_TITLE};
  margin-bottom: 40px;
`;

export default Modal;
