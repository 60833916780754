import React from "react";

const Icon = ({ className, color = "#9EADCF", width = "9px", height = "15px" }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 12.1 20.2"
    enableBackground="new 0 0 12.1 20.2"
  >
    <path
      fill={color}
      d="M9.4,0H2.7C1.2,0,0,1.2,0,2.7v14.8c0,1.5,1.2,2.7,2.7,2.7h6.7c1.5,0,2.7-1.2,2.7-2.7V2.7C12.1,1.2,10.9,0,9.4,0
z M2.7,1.4h6.7c0.7,0,1.3,0.6,1.3,1.3v11.7H1.4V2.7C1.4,2,2,1.4,2.7,1.4z M9.4,18.8H2.7c-0.7,0-1.3-0.6-1.3-1.3v-1.7h9.3v1.7
C10.7,18.3,10.1,18.8,9.4,18.8z"
    />
  </svg>
);

export default Icon;
