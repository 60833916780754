import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods } from "utils/constants";

const SLICE_KEY = "lighthouseReports";

const lighthouseReportsSchema = new schema.Entity("lighthouseReports", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_LIGHTHOUSE_REPORTS: "lighthouseReports/getLighthouseReports",
  REMOVE_LIGHTHOUSE_REPORT: "lighthouseReports/removeLighthouseReport",
};

const endpoints = {
  GET_LIGHTHOUSE_REPORTS: `/v1/lighthouse-reports`,
  REMOVE_LIGHTHOUSE_REPORT: ({ id }) => `/v1/lighthouse-reports/${id}`,
};

export const lighthouseReportsSelector = createSelector(
  ({ lighthouseReports }) => lighthouseReports,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const getLighthouseReports = createAsyncThunk(
  actionTypes.GET_LIGHTHOUSE_REPORTS,
  async ({ params } = {}, thunkApi) =>
    request({
      actionType: actionTypes.GET_LIGHTHOUSE_REPORTS,
      method: httpMethods.GET,
      endpoint: endpoints.GET_LIGHTHOUSE_REPORTS,
      thunkApi,
      params,
      schema: { lighthouseReports: [lighthouseReportsSchema] },
    }),
);

export const removeLighthouseReport = createAsyncThunk(
  actionTypes.REMOVE_LIGHTHOUSE_REPORT,
  async ({ id } = {}, thunkApi) =>
    request({
      actionType: actionTypes.REMOVE_LIGHTHOUSE_REPORT,
      method: httpMethods.DELETE,
      endpoint: endpoints.REMOVE_LIGHTHOUSE_REPORT({ id }),
      thunkApi,
    }),
);

const lighthouseReportsSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getLighthouseReports.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.lighthouseReports;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.lighthouseReports;
    });
    builder.addCase(getLighthouseReports.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = lighthouseReportsSlice;

export default reducer;
