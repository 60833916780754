import { useEffect } from "react";
import styled from "styled-components/macro";

import { Navbar, Breadcrumbs, ErrorHandler, AppLoadingIndicator, TabNavigation } from "components/shared";
import { Container } from "components/utils";

function AppTemplate({
  children,
  route = {},
  tabNavigation,
  TopPanelComponent,
  OptionsComponent,
  hideTabNavigation = false,
}) {
  const { label: title } = route;

  useEffect(() => {
    document.title = title ? `${title} | Seodity Admin Panel` : "Seodity Admin Panel";
  }, [title]);

  return (
    <ErrorHandler>
      <Wrapper>
        <Overflow>
          <div>
            <Navbar />
          </div>
          <Container>
            <div>
              <Breadcrumbs route={route} />
            </div>
            {TopPanelComponent && (
              <div>
                <TopPanelComponent />
              </div>
            )}
          </Container>
          {!hideTabNavigation && (
            <TabNavigation
              tabs={tabNavigation?.tabs}
              selectedTab={tabNavigation?.selectedTab}
              goToTab={tabNavigation?.goToTab}
              OptionsComponent={OptionsComponent}
            />
          )}
          <Container>
            <div>{children}</div>
          </Container>
          <AppLoadingIndicator />
        </Overflow>
      </Wrapper>
    </ErrorHandler>
  );
}

const Wrapper = styled.div`
  padding-bottom: 80px;
  min-height: 100vh;
`;

const Overflow = styled.div``;

export default AppTemplate;
