import styled from "styled-components/macro";

import { theme } from "utils/theme";

function CountryIndicator({ isoCode }) {
  return (
    isoCode && (
      <Wrapper>
        <Flag title={isoCode} alt={isoCode} src={`/static/img/flags/${isoCode?.toLowerCase()}.svg`} />
        <CountryIsoCode>{isoCode}</CountryIsoCode>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Flag = styled.img`
  width: 20px;
  height: auto;
`;

const CountryIsoCode = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.DOMAIN_NAME_SMALL};
  line-height: 1.22;
  color: ${theme.colors.COUNTRY_ISO_CODE};
  font-weight: ${theme.fontWeights.MEDIUM};
  margin-left: 10px;
  width: 15px;
`;

export default CountryIndicator;
