import React from "react";
import styled, { keyframes, css } from "styled-components/macro";

import { theme } from "utils/theme";

const Rotate = keyframes`
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ alignLeft }) =>
    alignLeft &&
    css`
      justify-content: flex-start;
    `};

  .spinner div {
    box-sizing: border-box;
  }

  .spinner > .spinner-segment {
    ${({ scale, color }) => css`
      width: ${`calc(144px * ${scale})`};
      height: ${`calc(144px * ${scale})`};
      top: ${`calc(28px * ${scale})`};
      left: ${`calc(28px * ${scale})`};
      border: ${`calc(16px * ${scale})`} solid ${color};
      border-color: ${color} transparent ${color} transparent;
    `};
    position: absolute;
    border-radius: 50%;
    animation: ${Rotate} 1s linear infinite;
  }

  .spinner > div:nth-child(2) {
    border-color: transparent;
  }

  .spinner > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }

  .spinner-out {
    ${({ scale }) => css`
      width: ${`calc(200px * ${scale})`};
      height: ${`calc(200px * ${scale})`};
    `};
    display: inline-block;
    overflow: hidden;
  }

  .spinner {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
  }
`;

const Spinner = ({ scale = "0.16", color = theme.colors.WHITE, alignLeft }) => (
  <Wrapper scale={scale} color={color} alignLeft={alignLeft}>
    <div className="spinner-out">
      <div className="spinner">
        <div className="spinner-segment" />
        <div className="spinner-segment">
          <div />
        </div>
      </div>
    </div>
  </Wrapper>
);

export default Spinner;
