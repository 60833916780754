import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.635 6.377" {...props}>
      <path
        fill="#ef3a4f"
        d="M1.354 3.931l3.084-1.384 3.16 2.567-1.652.143.094 1.12 3.58-.307-.005-.056.02-.002-.346-3.674-1.115.136.158 1.69L4.62 1.15 2.016 2.32 1.119 0 0 .434z"
      />
    </svg>
  );
}

export default SvgComponent;
