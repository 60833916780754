import { viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";

import { AppTemplate } from "components/templates";

function DashboardPage() {
  const route = getRouteByKey({ viewKey: viewKeys.DASHBOARD });

  return (
    <AppTemplate route={route}>
      <div>WORK IN PROGRESS 🚧</div>
    </AppTemplate>
  );
}

export default DashboardPage;
