import { useRef } from "react";
import { format, startOfDay, endOfDay, subMonths } from "date-fns";
import DayPicker, { DateUtils } from "react-day-picker";
import styled, { css } from "styled-components/macro";

import { dateFormats, weekdays, UI_ID_DATE_PICKER_TIMEFRAME_INPUT } from "utils/constants";
import { getEndDate, getStartDate } from "utils/dates";
import { useLegacyState, useOnClickOutside } from "utils/hooks";
import { theme } from "utils/theme";

import { Button, OptionButton } from "components/forms";
import { TimeframeValue } from "components/utils/Typography";
import { Box } from "components/utils";
import { Arrow, Calendar } from "components/icons";

const DEFAULT_FROM_DATE = startOfDay(subMonths(new Date(), 1));
const DEFAULT_TO_DATE = endOfDay(new Date(), 1);

const renderDay = (date) => {
  const day = date?.getDate();

  return (
    <DayOuterWrapper className="DayPicker-Day--outer-wrapper">
      <DayInnerWrapper className="DayPicker-Day--inner-wrapper">{day < 10 ? `0${day}` : day}</DayInnerWrapper>
    </DayOuterWrapper>
  );
};

const PickerNavbar = ({
  onNextClick,
  onPreviousClick,
  showPreviousButton,
  showNextButton,
  handleConfirm,
  handleReset,
  canConfirm,
}) => {
  return (
    <Box flex alignCenter justifyBetween margin="20px 30px 0 30px">
      <Box flex alignCenter>
        {showPreviousButton && (
          <MonthChangeButton onClick={() => onPreviousClick()} color={theme.colors.CALENDAR_PREVIOUS_MONTH_LABEL}>
            <ArrowLeft width="8px" height="5px" color={theme.colors.CALENDAR_PREVIOUS_MONTH_LABEL} />
            <ButtonText iconLeft>Previous month</ButtonText>
          </MonthChangeButton>
        )}
        {showNextButton && (
          <MonthChangeButton onClick={() => onNextClick()} color={theme.colors.CALENDAR_NEXT_MONTH_LABEL}>
            <ButtonText iconRight>Next month</ButtonText>
            <Arrow width="8px" height="5px" color={theme.colors.CALENDAR_NEXT_MONTH_LABEL} />
          </MonthChangeButton>
        )}
      </Box>
      <Box flex alignCenter>
        {handleReset && (
          <Box mr="10px">
            <Button variant={Button.Variants.SECONDARY} onClick={handleReset}>
              Reset
            </Button>
          </Box>
        )}
        {handleConfirm && (
          <Button disabled={!canConfirm} variant={Button.Variants.TERTIARY} onClick={handleConfirm}>
            Choose
          </Button>
        )}
      </Box>
    </Box>
  );
};

const PickerMonthName = ({ date, localeUtils }) => {
  const monthList = localeUtils.getMonths();
  const month = monthList[date.getMonth()];
  const year = date.getFullYear();

  return (
    <div className="DayPicker-Caption">
      <span className="DayPicker-Caption--month">{month}</span>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <span className="DayPicker-Caption--year">{year}</span>
    </div>
  );
};

function DateRangePicker({ onChange = () => {}, onReset = () => {}, value }) {
  const getInitialState = () => ({
    from: value?.from ? new Date(value?.from) : null,
    to: value?.to ? new Date(value?.to) : null,
    isOpen: false,
  });

  const [state, setState] = useLegacyState(getInitialState());

  const anchor = useRef();

  useOnClickOutside({
    ref: anchor,
    action: (targetId) => {
      if (targetId === UI_ID_DATE_PICKER_TIMEFRAME_INPUT) {
        return;
      }

      setState({ isOpen: false });
    },
  });

  const handleDayClick = async (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const range = DateUtils.addDayToRange(day, state);

    setState(range);
  };

  const handlePickerOpen = () => {
    setState({
      isOpen: !state.isOpen,
    });
  };

  const { from, to, isOpen } = state;

  const modifiers = {
    start: from,
    end: to,
    monday: { daysOfWeek: [weekdays.MONDAY] },
    sunday: { daysOfWeek: [weekdays.SUNDAY] },
  };
  const selectedDays = [from, { from, to }];

  const handleReset = () => {
    onReset();
    setState(getInitialState());
  };

  const handleConfirm = () => {
    setState({
      isOpen: false,
    });

    const fromDate = getStartDate({ date: from })?.toISOString();
    const toDate = getEndDate({ date: to }).toISOString();

    onChange({ from: fromDate, to: toDate });
  };

  const fromDateFormatted = format(from || DEFAULT_FROM_DATE, dateFormats.YYYY_MM_DD);
  const toDateFormatted = to ? format(to, dateFormats.YYYY_MM_DD) : null;

  const selectedOneDay = fromDateFormatted === toDateFormatted;
  const startedSelecting = fromDateFormatted && !toDateFormatted;

  const displayedTimeframe =
    selectedOneDay || startedSelecting ? fromDateFormatted : `${fromDateFormatted} - ${toDateFormatted}`;

  const defaultTimeframeLabel =
    !from && !to
      ? `${format(DEFAULT_FROM_DATE, dateFormats.YYYY_MM_DD)} - ${format(DEFAULT_TO_DATE, dateFormats.YYYY_MM_DD)}`
      : null;

  const canConfirm = from && to;

  const disabledDays = [
    {
      after: new Date(),
    },
  ];

  return (
    <Styler>
      <Box flex alignCenter>
        <Box mr="10px">
          <OptionButton
            onClick={handlePickerOpen}
            color={theme.colors.BUTTON_OPTION_PRIMARY_BACKGROUND}
            icon={<Calendar width="14px" height="15px" />}
          />
        </Box>
        <TimeframeValue>{defaultTimeframeLabel || displayedTimeframe}</TimeframeValue>
      </Box>
      <PickerWrapper ref={anchor} isOpen={isOpen}>
        <DayPicker
          fixedWeeks
          showOutsideDays
          numberOfMonths={2}
          month={subMonths(new Date(), 1)}
          firstDayOfWeek={weekdays.MONDAY}
          selectedDays={selectedDays}
          modifiers={modifiers}
          onDayClick={handleDayClick}
          disabledDays={disabledDays}
          navbarElement={
            <PickerNavbar canConfirm={canConfirm} handleConfirm={handleConfirm} handleReset={handleReset} />
          }
          captionElement={<PickerMonthName />}
          renderDay={renderDay}
        />
      </PickerWrapper>
    </Styler>
  );
}

const Styler = styled.div`
  position: relative;
  width: 100%;

  .DayPicker {
    &:focus {
      outline: none;
    }
  }

  .DayPickerInput {
    width: 100%;
    display: flex;
  }

  .DayPicker-Months {
    flex-wrap: nowrap;

    &:focus {
      outline: none;
    }
  }

  .DayPicker-Caption {
    margin-bottom: 30px;

    .DayPicker-Caption--month {
      font-family: ${theme.fonts.RUBIK};
      font-size: ${theme.sizes.CALENDAR_MONTH_TEXT};
      font-weight: ${theme.fontWeights.MEDIUM};
    }

    .DayPicker-Caption--year {
      font-family: ${theme.fonts.RUBIK};
      font-size: ${theme.sizes.CALENDAR_YEAR_TEXT};
      font-weight: ${theme.fontWeights.MEDIUM};
      color: ${theme.colors.CALENDAR_YEAR_LABEL};
    }
  }

  .DayPicker-Weekdays {
    font-family: ${theme.fonts.RUBIK};
    font-size: ${theme.sizes.CALENDAR_DAY_TEXT};
    font-weight: ${theme.fontWeights.MEDIUM};
  }

  .DayPicker-Weekday {
    font-family: ${theme.fonts.RUBIK};
    font-size: ${theme.sizes.CALENDAR_DAY_TEXT};
    font-weight: ${theme.fontWeights.MEDIUM};
    color: ${theme.colors.CALENDAR_DAY_LABEL};
  }

  .DayPicker-Day {
    padding: 0.5em 0;
    font-family: ${theme.fonts.RUBIK};
    font-size: ${theme.sizes.CALENDAR_DAY_TEXT};
    font-weight: ${theme.fontWeights.MEDIUM};
    color: ${theme.colors.CALENDAR_DAY_NORMAL_TEXT};

    &.DayPicker-Day--today {
      color: ${theme.colors.TEXT_DARK};
    }

    &.DayPicker-Day--sunday {
      background-color: unset;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .DayPicker-Month {
    margin: 0 30px;
  }

  .DayPicker-wrapper {
    padding: 30px 0;
    border-radius: 9px;
    background-color: ${theme.colors.CALENDAR_BACKGROUND};
    outline: none;
    display: flex;
    flex-direction: column-reverse;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    border-radius: 9px;

    &:focus,
    &:active {
      outline: none;
    }
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: unset;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: ${theme.colors.WHITE};
    background-color: unset;
    border-radius: 0;

    .DayPicker-Day--outer-wrapper {
      background-color: ${theme.colors.CALENDAR_DAY_SELECTED_RANGE_BACKGROUND};
    }

    &.DayPicker-Day--start {
      background-color: unset;
      border-radius: 9px;

      .DayPicker-Day--outer-wrapper {
        background-color: ${theme.colors.CALENDAR_DAY_SELECTED_RANGE_BACKGROUND};
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
      }

      .DayPicker-Day--inner-wrapper {
        background-color: ${theme.colors.CALENDAR_SELECTED_DAY_BACKGROUND};
      }
    }

    &.DayPicker-Day--end {
      background-color: unset;
      border-radius: 9px;

      .DayPicker-Day--outer-wrapper {
        background-color: ${theme.colors.CALENDAR_DAY_SELECTED_RANGE_BACKGROUND};
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
      }

      .DayPicker-Day--inner-wrapper {
        background-color: ${theme.colors.CALENDAR_SELECTED_DAY_BACKGROUND};
      }
    }

    &.DayPicker-Day--start,
    &.DayPicker-Day--monday {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;

      .DayPicker-Day--outer-wrapper {
        background-color: ${theme.colors.CALENDAR_DAY_SELECTED_RANGE_BACKGROUND};
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
      }
    }

    &.DayPicker-Day--end,
    &.DayPicker-Day--sunday {
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;

      .DayPicker-Day--outer-wrapper {
        background-color: ${theme.colors.CALENDAR_DAY_SELECTED_RANGE_BACKGROUND};
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
      }
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  .DayPicker-Day--disabled {
    cursor: not-allowed;
    color: ${theme.colors.CALENDAR_DAY_DISABLED_TEXT};
  }

  .DayPicker-Day--outside {
    color: ${theme.colors.CALENDAR_DAY_DISABLED_TEXT};
  }
`;

const ArrowLeft = styled(Arrow)`
  transform: rotate(180deg);
`;

const PickerWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 50px;
  left: -170px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const MonthChangeButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  appearance: none;
  outline: none;
  border: none;

  color: ${({ color }) => color};
`;

const ButtonText = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.CALENDAR_MONTH_CHANGE_BUTTON_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 2;

  ${({ iconLeft }) =>
    iconLeft &&
    css`
      margin-left: 10px;
      margin-right: 20px;
    `};

  ${({ iconRight }) =>
    iconRight &&
    css`
      margin-right: 10px;
    `};
`;

const DayInnerWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 3px;
  padding-top: 1px;
  padding-left: 10px;
  padding-right: 10px;
`;

const DayOuterWrapper = styled.div`
  height: 28px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default DateRangePicker;
