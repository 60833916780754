import { viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";

import { AppTemplate } from "components/templates";
import { TopPanel } from "components/shared";
import { PostForm } from "Blog/components";

const BlogCreatePage = () => {
  const route = getRouteByKey({ viewKey: viewKeys.BLOG_CREATE });

  const TopPanelComponent = () => <TopPanel title={route?.label} leftSection />;

  return (
    <AppTemplate route={route} TopPanelComponent={TopPanelComponent} hideTabNavigation>
      <PostForm />
    </AppTemplate>
  );
};

export default BlogCreatePage;
