import { useForm } from "react-hook-form";
import { useState } from "react";

import { operationTypes } from "utils/constants";
import { useCreateBlogAuthorMutation, useUpdateBlogAuthorMutation } from "api/services/blogAuthor";

import { Button, ButtonGroup, TextInput, ImageInput } from "components/forms";
import { Box } from "components/utils";
import toast, { toastIds } from "utils/toast";

const AuthorForm = ({ setOpened, author }) => {
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(author?.profile_image_url);
  // image is loading
  const [isLoading, setLoading] = useState(false);

  const [addAuthor] = useCreateBlogAuthorMutation();
  const [updateAuthor] = useUpdateBlogAuthorMutation();

  const handleOperation = async ({ values, type }) => {
    setOperationInProgress(true);

    if (type === operationTypes.CONFIRM) {
      if (author) {
        const authorToUpdate = {
          ...(author?.name !== values?.name && { name: values?.name }),
          ...(author?.slug !== values?.slug && { slug: values?.slug }),
          ...(author?.description !== values?.description && { description: values?.description }),
          ...(author?.profile_image_url !== currentImageUrl && { profile_image_url: currentImageUrl }),
          ...(author?.facebook_url !== values?.facebook && { facebook_url: values?.facebook }),
          ...(author?.linkedin_url !== values?.linkedin && { linkedin_url: values?.linkedin }),
          ...(author?.twitter_url !== values?.twitter && { twitter_url: values?.twitter }),
          ...(author?.website_url !== values?.website && { website_url: values?.website }),
        };

        if (Object.keys(authorToUpdate).length) {
          try {
            await updateAuthor({ uid: author?.uid, data: authorToUpdate }).unwrap();

            toast.success("Author updated successfully.", {
              toastId: toastIds.SUCCESS_UPDATE_AUTHOR,
            });
          } catch (error) {
            toast.error("An error occurred while updating author. Please try again.", {
              toastId: toastIds.ERROR_UPDATE_AUTHOR,
            });
          }
        }
      } else {
        const authorToAdd = {
          name: values?.name,
          slug: values?.slug,
          description: values?.description,
          profile_image_url: currentImageUrl,
          facebook_url: values?.facebook,
          linkedin_url: values?.linkedin,
          twitter_url: values?.twitter,
          website_url: values?.website,
        };

        try {
          await addAuthor(authorToAdd).unwrap();

          toast.success("Author created successfully.", {
            toastId: toastIds.SUCCESS_CREATE_AUTHOR,
          });
        } catch (error) {
          toast.error("An error occurred while creating author. Please try again.", {
            toastId: toastIds.ERROR_CREATE_AUTHOR,
          });
        }
      }
    }

    setOperationInProgress(false);
    setOpened(null);
  };

  const { register, handleSubmit, errors: formErrors, setError, clearErrors } = useForm({ reValidateMode: "onSubmit" });

  const fields = [
    {
      label: "Author",
      type: "text",
      name: "name",
      placeholder: "Enter the author's name",
      defaultValue: author?.name,
      component: TextInput,
      validation: {
        required: "Author is required",
      },
      autoFocus: true,
      margin: "40px 0 0 0",
    },
    {
      label: "Short description",
      type: "textarea",
      name: "description",
      height: "80px",
      defaultValue: author?.description,
      component: TextInput,
    },
    {
      label: "Slug",
      type: "text",
      name: "slug",
      placeholder: "name-surname",
      defaultValue: author?.slug,
      component: TextInput,
      validation: {
        required: "Slug is required",
        pattern: { value: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/i, message: "Invalid slug" },
      },
    },
    {
      label: "Facebook",
      type: "text",
      name: "facebook",
      defaultValue: author?.facebook_url,
      component: TextInput,
      validation: {
        pattern: {
          value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
          message: "Invalid url format",
        },
      },
    },
    {
      label: "Linkedin",
      type: "text",
      name: "linkedin",
      defaultValue: author?.linkedin_url,
      component: TextInput,
      validation: {
        pattern: {
          value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
          message: "Invalid url format",
        },
      },
    },
    {
      label: "Twitter",
      type: "text",
      name: "twitter",
      defaultValue: author?.twitter_url,
      component: TextInput,
      validation: {
        pattern: {
          value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
          message: "Invalid url format",
        },
      },
    },
    {
      label: "Website",
      type: "text",
      name: "website",
      defaultValue: author?.website_url,
      component: TextInput,
      validation: {
        pattern: {
          value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
          message: "Invalid url format",
        },
      },
    },
    {
      label: "Drag and drop title photo",
      name: "image",
      preview: currentImageUrl,
      component: ImageInput,
      setUrl: setCurrentImageUrl,
      setError,
      clearErrors,
      isLoading,
      setLoading,
    },
  ];

  return (
    <form onSubmit={handleSubmit((values) => handleOperation({ values, type: operationTypes.CONFIRM }))}>
      {fields.map(({ component: Component, validation, inputProps, ...fieldProps }) => (
        <Component
          key={fieldProps?.name}
          ref={register(validation)}
          error={formErrors?.[fieldProps?.name]?.message}
          {...inputProps}
          {...fieldProps}
        />
      ))}
      <Box flex justifyLeft margin="20px 0 0">
        <ButtonGroup>
          <Button
            type="button"
            loading={operationInProgress}
            variant={Button.Variants.DANGER}
            onClick={() => handleOperation({ type: operationTypes.CANCEL })}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading} loading={operationInProgress} variant={Button.Variants.SECONDARY}>
            {author ? "Save changes" : "Save"}
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
};

export default AuthorForm;
