import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.64 4.487" {...props}>
      <path
        d="M4.689.199a.55.55 0 00.073.774l.8.663-5.015.024A.55.55 0 000 2.21v.003a.55.55 0 00.552.547l5.114-.024-.876.793a.55.55 0 10.738.815l1.931-1.748a.55.55 0 00.181-.407v-.013a.547.547 0 00-.2-.41L5.464.125A.55.55 0 004.69.2z"
        fill="#8794b2"
      />
    </svg>
  );
}

export default SvgComponent;
