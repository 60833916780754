import styled from "styled-components/macro";

import { theme } from "utils/theme";

function Favicon({ faviconUrl, domain, margin }) {
  const domainInitials = domain?.slice(0, 1);

  return (
    <Wrapper faviconUrl={faviconUrl} margin={margin}>
      {faviconUrl ? (
        <ProjectDomainInitials>
          <img alt={domain} src={`data:image/png;base64,${faviconUrl}`} />
        </ProjectDomainInitials>
      ) : (
        <ProjectDomainInitials>{domainInitials}</ProjectDomainInitials>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ faviconUrl }) => (faviconUrl ? "white" : theme.colors.BLACK)};
  color: ${theme.colors.WHITE};
  margin: ${({ margin }) => margin};
`;

const ProjectDomainInitials = styled.div`
  height: 100%;
  padding-top: 1px;
  font-size: 11px;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  img {
    width: 16px;
    height: 16px;
  }
`;

export default Favicon;
