import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { columnTypes, viewKeys, tabKeys, modalKeys } from "utils/constants";
import { getRouteByKey, routes } from "utils/routes";
import { filterConfig } from "utils/filters";
import { useModal, usePageLoading, useQueryParams } from "utils/hooks";
import { getPaginationParams, getMetaParams } from "utils/pagination";
import { parseRoute } from "utils/formatters";
import toast, { toastIds } from "utils/toast";

import {
  AdvancedFiltersDisplay,
  AdvancedFiltersForm,
  ConfirmationModal,
  DateTimeValue,
  MenuDropdown,
  Modal,
  NullableValue,
  NumericValue,
  SummaryValue,
  Table,
  TopPanel,
} from "components/shared";
import { Box } from "components/utils";
import { Remove } from "components/icons";
import { SearchInput } from "components/forms";
import { AppTemplate } from "components/templates";

import { actionTypes, getProjects, projectsSelector, removeProject } from "Projects/redux/projectsSlice";
import { pendingActionsSelector } from "App/redux/appSlice";

function ProjectsPage() {
  const { initialLoading } = usePageLoading();

  const route = getRouteByKey({ viewKey: viewKeys.PROJECTS });

  const dispatch = useDispatch();
  const history = useHistory();

  const { params, setQuery } = useQueryParams();

  const { ids: projectIds, entities: projectsEntities, meta: projectsMeta } = useSelector(projectsSelector);
  const loadingProjects = useSelector((state) => pendingActionsSelector({ state, actions: actionTypes.GET_PROJECTS }));

  useEffect(() => {
    dispatch(
      getProjects({
        params: {
          ...getPaginationParams({ params }),
          search: params?.search,
          admin: "true",
        },
      }),
    );
  }, [dispatch, params]);

  const OptionsComponent = () => (
    <Box width="100%" flex alignCenter justifyBetween>
      <div>
        <AdvancedFiltersDisplay filterConfig={filterConfig} viewKey={viewKeys.PROJECTS} />
      </div>
    </Box>
  );

  const { isOpen: openedAdvancedFiltersId, setOpen: setOpenedAdvancedFiltersId } = useModal();

  const TopPanelComponent = useCallback(
    () => (
      <TopPanel
        title={route?.label}
        leftSection={
          <Box flex>
            <Box mr="20px">
              <SearchInput
                onChange={(search) => {
                  setQuery({ search });
                }}
                focusOnMount
                defaultValue={params?.search}
                width="370px"
                onButtonClick={() => {
                  setOpenedAdvancedFiltersId(modalKeys.UI_ID_ADVANCED_FILTERS_MODAL);
                }}
              />
            </Box>
          </Box>
        }
        rightSection={
          <Box>
            <SummaryValue value={projectsMeta?.all} caption="Projects count" />
          </Box>
        }
      />
    ),
    [projectsMeta, params],
  );

  const [projectToRemoveId, setProjectToRemoveId] = useState(null);

  const headers = useMemo(
    () => [
      { label: "User e-mail", value: "account.email", type: columnTypes.ALPHA, sortable: false },
      { label: "Domain", value: "domain", type: columnTypes.ALPHA, sortable: true },
      { label: "Keywords count", value: "keywords_count", type: columnTypes.NUMERIC, sortable: true },
      { label: "Competitors count", value: "competitors_count", type: columnTypes.NUMERIC, sortable: false },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    [],
  );

  const rows = useMemo(() => {
    return projectIds?.map((id) => {
      const project = projectsEntities?.[id];

      return {
        options: {
          key: id,
          onRowClick: () => {
            history.push(
              parseRoute({
                route: routes.PROJECT_DETAILS,
                params: {
                  id,
                },
              }),
            );
          },
        },
        columns: {
          user_email: <NullableValue>{project?.account?.email}</NullableValue>,
          domain: <NullableValue>{project?.domain}</NullableValue>,
          keywords_count: <NumericValue>{project?.keywords_count}</NumericValue>,
          competitors_count: <NumericValue>{project?.competitors?.length}</NumericValue>,
          created_at: <DateTimeValue date={project?.created_at} />,
          actions: (
            <>
              <MenuDropdown
                options={[
                  {
                    label: "Edit",
                    value: "edit",
                    onClick: () => {
                      history.push(
                        parseRoute({
                          route: routes.PROJECT_DETAILS,
                          params: {
                            id,
                          },
                          queryParams: {
                            tab: tabKeys.PROJECT_DETAILS_SETTINGS,
                          },
                        }),
                      );
                    },
                  },
                  {
                    label: "Remove",
                    value: "remove",
                    onClick: () => {
                      setProjectToRemoveId(id);
                    },
                  },
                ]}
              />
            </>
          ),
        },
      };
    });
  }, [projectIds]);

  const handleProjectRemove = async () => {
    if (projectToRemoveId) {
      try {
        const rs = await dispatch(removeProject({ id: projectToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Project removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_PROJECT,
          });

          dispatch(
            getProjects({
              params: {
                ...getPaginationParams({ params }),
                admin: "true",
              },
            }),
          );
        } else {
          toast.error("An error occurred while removing project. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_PROJECT,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing project. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_PROJECT,
        });
      }
    }

    setProjectToRemoveId(null);
  };

  return (
    <>
      <AppTemplate route={route} OptionsComponent={OptionsComponent} TopPanelComponent={TopPanelComponent}>
        <Table
          isLoading={!projectIds}
          isRefetchLoading={loadingProjects || initialLoading}
          headers={headers}
          rows={rows}
          meta={getMetaParams({ meta: projectsMeta, queryParams: params })}
          emptyStateText="No projects available"
        />
      </AppTemplate>
      <Modal
        title="Sorting and filtering"
        id={modalKeys.UI_ID_ADVANCED_FILTERS_MODAL}
        isOpen={openedAdvancedFiltersId === modalKeys.UI_ID_ADVANCED_FILTERS_MODAL}
        onRequestClose={() => {
          setOpenedAdvancedFiltersId(null);
        }}
      >
        <AdvancedFiltersForm
          filterConfig={filterConfig}
          viewKey={viewKeys.PROJECTS}
          onConfirm={() => {
            setOpenedAdvancedFiltersId(null);
          }}
          onCancel={() => {
            setOpenedAdvancedFiltersId(null);
          }}
        />
      </Modal>
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected project?"
        id={modalKeys.UI_ID_REMOVE_PROJECT_MODAL}
        isOpen={projectToRemoveId}
        onConfirm={handleProjectRemove}
        onCancel={() => {
          setProjectToRemoveId(null);
        }}
      />
    </>
  );
}

export default ProjectsPage;
