import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { DeviceDesktop, DeviceMobile } from "components/icons";

function KeywordValue({ isMobile }) {
  const deviceLabel = isMobile ? "Mobile" : "Desktop";

  return (
    deviceLabel && (
      <Wrapper>
        <DeviceIconWrapper title={deviceLabel}>{isMobile ? <DeviceMobile /> : <DeviceDesktop />}</DeviceIconWrapper>
        <DeviceNameWrapper>{deviceLabel}</DeviceNameWrapper>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 10px;
  }
`;

const DeviceNameWrapper = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.DOMAIN_NAME_SMALL};
  line-height: 1.22;
  color: ${theme.colors.TEXT_TABLE_ENTRY};
`;

const DeviceIconWrapper = styled.div`
  width: 15px;
`;

export default KeywordValue;
