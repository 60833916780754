import styled, { css } from "styled-components/macro";

function Box({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `};

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      align-items: center;
    `};

  ${({ justifyBetween }) =>
    justifyBetween &&
    css`
      justify-content: space-between;
    `};

  ${({ justifyCenter }) =>
    justifyCenter &&
    css`
      justify-content: center;
    `};

  ${({ justifyLeft }) =>
    justifyLeft &&
    css`
      justify-content: flex-start;
    `};

  ${({ justifyRight }) =>
    justifyRight &&
    css`
      justify-content: flex-end;
    `};

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `};

  ${({ margin }) => css`
    margin: ${margin};
  `};
  ${({ mt }) => css`
    margin-top: ${mt};
  `};
  ${({ mb }) => css`
    margin-bottom: ${mb};
  `};
  ${({ ml }) => css`
    margin-left: ${ml};
  `};
  ${({ mr }) => css`
    margin-right: ${mr};
  `};

  ${({ padding }) => css`
    padding: ${padding};
  `};
  ${({ pt }) => css`
    padding-top: ${pt};
  `};
  ${({ pb }) => css`
    padding-bottom: ${pb};
  `};
  ${({ pl }) => css`
    padding-left: ${pl};
  `};
  ${({ pr }) => css`
    padding-right: ${pr};
  `};
`;

export default Box;
