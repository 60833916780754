import { useState } from "react";

import { useQueryParams } from "utils/hooks";

const useTabs = ({ tabs = [] }) => {
  const { params, setQuery } = useQueryParams();

  const [selectedTab, setSelectedTab] = useState(tabs?.find((t) => t?.key === params?.tab) || tabs?.[0]);

  const goToTab = (tab) => {
    setSelectedTab(tab);
    setQuery({ tab: tab?.key });
  };

  return {
    selectedTab,
    goToTab,
  };
};

export default useTabs;
