import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["Prompts", "Prompt"],
});

const promptsApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getPrompts: build.query({
      query: ({ limit, offset, sortDir, sortField }) => ({
        url: "/v1/prompts",
        method: "GET",
        params: {
          limit,
          offset,
          sortDir,
          sortField,
        },
      }),
      providesTags: ["Prompts"],
    }),
    getPrompt: build.query({
      query: (uid) => `/v1/prompts/${uid}`,
      providesTags: (uid) => [{ type: "Prompt", id: uid }],
    }),
    updatePrompt: build.mutation({
      query({ uid, data }) {
        return {
          url: `/v1/prompts/${uid}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (uid) => [{ type: "Prompt", id: uid }, { type: "Prompts" }],
    }),
  }),
});

export const {
  useGetPromptsQuery,
  useGetPromptQuery,
  useUpdatePromptMutation,
} = promptsApi;
