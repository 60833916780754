import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg
      id="sort_numeric_desc_icon_Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 10.642 12.83"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.sort_numeric_desc_icon_st0{fill:${color}}`}</style>
      <path
        className="sort_numeric_desc_icon_st0"
        d="M3.817 10.304c0 1.497-.63 2.526-1.921 2.526-1.307 0-1.883-1.125-1.89-2.496 0-1.401.622-2.511 1.928-2.511 1.354 0 1.883 1.155 1.883 2.48zm-2.621.03c-.008 1.11.272 1.639.73 1.639s.708-.551.708-1.654c0-1.073-.24-1.64-.715-1.64-.436 0-.731.53-.723 1.655zM.445 4.105c.147.015.28.015.513 0 .358-.022.724-.119.996-.298.326-.216.544-.529.638-.894l-.024-.007c-.225.223-.552.35-1.011.35-.856 0-1.579-.573-1.579-1.513C-.022.797.771 0 1.884 0c1.299 0 1.913.954 1.913 2.086 0 1.006-.335 1.744-.887 2.228-.482.417-1.143.649-1.928.678a4.264 4.264 0 01-.537 0v-.887zm.716-2.429c0 .417.233.775.715.775.319 0 .544-.149.653-.32a.513.513 0 00.063-.283c0-.515-.203-1.021-.74-1.021-.404 0-.7.343-.691.85z"
      />
      <path
        d="M8.046 12.253c.107.118.236.17.42.18a.55.55 0 00.41-.198l1.64-1.978a.55.55 0 10-.847-.701l-.654.789.019-5.033a.565.565 0 00-.55-.551.55.55 0 00-.55.548l-.02 5.16-.801-.886a.551.551 0 00-.816.738l1.749 1.932z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
