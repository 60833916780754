import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Favicon } from "components/shared";

function DomainIndicator({ domain, faviconUrl, small }) {
  return (
    domain && (
      <Wrapper>
        <Favicon margin="0 10px 0 0" domain={domain} faviconUrl={faviconUrl} />
        <DomainName small={small}>{domain}</DomainName>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DomainName = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${({ small }) => (small ? theme.sizes.DOMAIN_NAME_SMALL : theme.sizes.DOMAIN_NAME)};
  line-height: 1.22;
  color: ${theme.colors.TEXT_DARK};
`;

export default DomainIndicator;
