import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { routes } from "utils/routes";

import { authSelector } from "Auth/redux/authSlice";

function PublicRoute({ component: Component, ...rest }) {
  const { token } = useSelector(authSelector);

  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.DASHBOARD,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PublicRoute;
