import { useTabs, useQueryParams } from "utils/hooks";
import { tabKeys, viewKeys, DEFAULT_PAGINATION_LIMIT } from "utils/constants";
import { getRouteByKey } from "utils/routes";
import styled from "styled-components/macro";
import { theme } from "utils/theme";

import { AppTemplate } from "components/templates";
import { SummaryValue, TopPanel } from "components/shared";
import { Box } from "components/utils";

import { useGetPromptsQuery } from "../../api/services/prompt";
import { PromptsTab } from "../tabs";

const PromptPage = () => {
  const route = getRouteByKey({ viewKey: viewKeys.PROMPTS });
  const { tabs } = route;
  const { params } = useQueryParams();

  const { selectedTab, goToTab } = useTabs({ tabs });

  const offset = params?.page ? (+params?.page - 1) * (params?.limit ? +params?.limit : DEFAULT_PAGINATION_LIMIT) : 0;
  const data = {
    limit: params?.limit ? +params?.limit : DEFAULT_PAGINATION_LIMIT,
    offset,
    sortDir: params?.sortDir,
    sortField: params?.sortField,
  };

  const { data: prompts, isLoading: isPromptsLoading, isFetching: isPromptsFetching } = useGetPromptsQuery(data);


  const TopPanelComponent = () => (
    <TopPanel
      title={route?.label}
      leftSection
      rightSection={
        <Box>
          <Grid>
            <GridItem>
              <SummaryValue value={prompts?.meta?.all} caption="Prompts count" />
            </GridItem>
          </Grid>
        </Box>
      }
    />
  );

  return (
    <AppTemplate
      route={route}
      tabNavigation={{
        tabs,
        goToTab,
        selectedTab,
      }}
      TopPanelComponent={prompts && TopPanelComponent}
    >
      {selectedTab?.key === tabKeys.PROMPTS && (
        <PromptsTab prompts={prompts} isLoading={isPromptsLoading} isFetching={isPromptsFetching} />
      )}
    </AppTemplate>
  );
};

const Grid = styled.div`
  display: flex;
  align-items: center;
`;

const GridItem = styled.div`
  padding: 0 30px;
  border-left: 1px solid ${theme.colors.SUMMARY_ITEM_SEPARATOR};

  &:last-child {
    border-right: 1px solid ${theme.colors.SUMMARY_ITEM_SEPARATOR};
  }
`;


export default PromptPage;
