import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods, httpStatuses } from "utils/constants";
import { merge } from "utils/formatters";

const SLICE_KEY = "admins";

const adminsSchema = new schema.Entity("admins", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
  error: null,
};

export const actionTypes = {
  GET_ADMINS: "admins/getAdmins",
  GET_ADMIN: "admins/getAdmin",
  CREATE_ADMIN: "admins/createAdmin",
};

const endpoints = {
  GET_ADMINS: `/v1/admins`,
  GET_ADMIN: ({ id }) => `/v1/admins/${id}`,
  CREATE_ADMIN: `/v1/admins`,
};

export const adminsSelector = createSelector(
  ({ admins }) => admins,
  ({ ids, entities, meta, error }) => ({
    ids,
    entities,
    meta,
    error,
  }),
);

export const getAdmins = createAsyncThunk(actionTypes.GET_ADMINS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_ADMINS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_ADMINS,
    thunkApi,
    params,
    schema: { admins: [adminsSchema] },
  }),
);

export const getAdmin = createAsyncThunk(actionTypes.GET_ADMIN, async ({ id } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_ADMIN,
    method: httpMethods.GET,
    endpoint: endpoints.GET_ADMIN({ id }),
    thunkApi,
    errorHandler: ({ status }) => {
      if (status === httpStatuses.NOT_FOUND) {
        return "Admin not found";
      }

      return null;
    },
  }),
);

export const createAdmin = createAsyncThunk(actionTypes.CREATE_ADMIN, async ({ data } = {}, thunkApi) =>
  request({
    actionType: actionTypes.CREATE_ADMIN,
    method: httpMethods.POST,
    endpoint: endpoints.CREATE_ADMIN,
    data,
    thunkApi,
  }),
);

const authSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getAdmins.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.admins;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.admins;
    });
    builder.addCase(getAdmin.fulfilled, (state, { payload }) => {
      state.ids = merge({ array: state.ids, element: payload?.uid });
      state.entities = { ...state.entities, [payload?.uid]: payload };
    });
    builder.addCase(getAdmin.rejected, (state, { payload }) => {
      state.error = payload;
    });
  },
});

const { reducer } = authSlice;

export default reducer;
