import { useState } from "react";
import styled from "styled-components/macro";

import { theme } from "utils/theme";
import { operationTypes } from "utils/constants";

import { Button, ButtonGroup } from "components/forms";
import { Modal, Placeholder } from "components/shared";
import { Box } from "components/utils";
import { Paragraph } from "components/utils/Typography";

function ConfirmationModal({
  icon = <Placeholder width="30px" height="30px" />,
  form,
  title,
  description,
  id,
  isOpen,
  width,
  onConfirm = () => {},
  onCancel = () => {},
}) {
  const [operationInProgress, setOperationInProgress] = useState(false);

  const handleOperation = async ({ type }) => {
    await setOperationInProgress(true);

    if (type === operationTypes.CONFIRM) {
      await onConfirm();
    } else if (type === operationTypes.CANCEL) {
      await onCancel();
    }

    setOperationInProgress(false);
  };

  return (
    <Modal id={id} isOpen={isOpen} width={width} height="200px" onRequestClose={onCancel}>
      <ContentWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <Box>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {form && <Form>{form}</Form>}
        </Box>
        <ButtonGroup>
          <Button
            disabled={operationInProgress}
            variant={Button.Variants.DANGER}
            onClick={() => handleOperation({ type: operationTypes.CANCEL })}
          >
            Cancel
          </Button>
          <Button
            loading={operationInProgress}
            variant={Button.Variants.SECONDARY}
            onClick={() => handleOperation({ type: operationTypes.CONFIRM })}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </ContentWrapper>
    </Modal>
  );
}

const Form = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.MODAL_TITLE};
  font-weight: ${theme.fontWeights.MEDIUM};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.colors.MODAL_TITLE};
  margin-bottom: 20px;
`;

const Description = styled(Paragraph)`
  margin-bottom: 20px;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export default ConfirmationModal;
