import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import toast, { toastIds } from "utils/toast";
import { columnTypes, modalKeys } from "utils/constants";
import { useModal, usePageLoading, useQueryParams } from "utils/hooks";
import { getMetaParams, getPaginationParams } from "utils/pagination";
import { routes } from "utils/routes";
import { parseRoute } from "utils/formatters";

import {
  Table,
  NullableValue,
  DateTimeValue,
  BooleanValue,
  ConfirmationModal,
  MenuDropdown,
  JsonDisplay,
  Drawer,
  DomainIndicator,
  LinkValue,
  NumericValue,
} from "components/shared";
import { Remove } from "components/icons";

import { pendingActionsSelector } from "App/redux/appSlice";
import {
  actionTypes,
  getProjectReportLogs,
  reportLogsSelector,
  removeReportLog,
} from "ReportLogs/redux/reportLogsSlice";
import { getProject } from "Projects/redux/projectsSlice";

function ProjectDetailsReportLogsTab({ project }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { initialLoading } = usePageLoading();
  const { params } = useQueryParams();

  const { ids: reportLogIds, entities: reportLogsEntities, meta: reportLogsMeta } = useSelector(reportLogsSelector);
  const loadingReportLogs = useSelector((state) =>
    pendingActionsSelector({ state, actions: actionTypes.GET_PROJECT_REPORT_LOGS }),
  );

  const [reportLogToRemoveId, setReportLogToRemoveId] = useState(null);
  const { isOpen: openedJsonId, setOpen: setOpenedJsonId } = useModal();

  useEffect(() => {
    dispatch(
      getProjectReportLogs({
        params: {
          ...getPaginationParams({ params }),
        },
        id: project?.uid,
      }),
    );
    dispatch(
      getProject({
        id: project?.uid,
      }),
    );
  }, [dispatch, params]);

  const headers = useMemo(
    () => [
      { label: "URL", value: "url", type: columnTypes.ALPHA, sortable: true },
      { label: "User e-mail", value: "account.email", type: columnTypes.ALPHA, sortable: false },
      { label: "Name", value: "name", type: columnTypes.ALPHA, sortable: true },
      { label: "Is enabled", value: "is_enabled", type: columnTypes.ALPHA, sortable: true },
      { label: "Is up", value: "is_up", type: columnTypes.ALPHA, sortable: true },
      {
        label: "Notifications",
        value: "are_notifications_enabled",
        type: columnTypes.ALPHA,
        sortable: true,
      },
      { label: "Check interval", value: "check_interval", type: columnTypes.NUMERIC, sortable: true },
      { label: "Last check at", value: "last_check_at", type: columnTypes.ALPHA, sortable: true, minWidth: "135px" },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true, minWidth: "135px" },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      reportLogIds?.map((id) => {
        const reportLog = reportLogsEntities?.[id];

        return {
          options: {
            key: id,
            onRowClick: () => {
              setOpenedJsonId(id);
            },
          },

          columns: {
            url: <DomainIndicator small faviconUrl={reportLog?.favicon_base64} domain={reportLog?.url} />,
            user_email: (
              <LinkValue
                onClick={() => {
                  history.push(
                    parseRoute({
                      route: routes.USER_DETAILS,
                      params: {
                        id: reportLog?.user?.uid,
                      },
                    }),
                  );
                }}
              >
                {reportLog?.user?.email}
              </LinkValue>
            ),
            name: <NullableValue>{reportLog?.name}</NullableValue>,
            is_enabled: <BooleanValue>{reportLog?.is_enabled}</BooleanValue>,
            is_up: <BooleanValue>{reportLog?.is_up}</BooleanValue>,
            are_notifications_enabled: <BooleanValue>{reportLog?.are_notifications_enabled}</BooleanValue>,
            check_interval: <NumericValue>{reportLog?.check_interval}</NumericValue>,
            last_check_at: <DateTimeValue date={reportLog?.last_check_at} />,
            created_at: <DateTimeValue date={reportLog?.created_at} />,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "JSON",
                      value: "json",
                      onClick: () => {
                        setOpenedJsonId(id);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setReportLogToRemoveId(id);
                      },
                    },
                  ]}
                />
                <Drawer
                  isOpen={openedJsonId === id}
                  onRequestClose={() => {
                    setOpenedJsonId(null);
                  }}
                >
                  <JsonDisplay json={reportLog} />
                </Drawer>
              </>
            ),
          },
        };
      }),
    [reportLogIds, openedJsonId],
  );

  const handleReportLogRemove = async () => {
    if (reportLogToRemoveId) {
      try {
        const rs = await dispatch(removeReportLog({ id: reportLogToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Repor log removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_REPORT_LOG,
          });

          dispatch(
            getProjectReportLogs({
              params: {
                ...getPaginationParams({ params }),
                userUID: project?.user_uid,
              },
            }),
          );
          dispatch(
            getProject({
              id: project?.uid,
            }),
          );
        } else {
          toast.error("An error occurred while removing report log. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_REPORT_LOG,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing report log. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_REPORT_LOG,
        });
      }
    }

    setReportLogToRemoveId(null);
  };

  return (
    <>
      <Table
        isLoading={!reportLogIds}
        isRefetchLoading={loadingReportLogs || initialLoading}
        headers={headers}
        rows={rows}
        meta={getMetaParams({ meta: reportLogsMeta, queryParams: params })}
        emptyStateText="No report logs available"
      />
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected report log?"
        id={modalKeys.UI_ID_REMOVE_REPORT_LOG_MODAL}
        isOpen={reportLogToRemoveId}
        onConfirm={handleReportLogRemove}
        onCancel={() => {
          setReportLogToRemoveId(null);
        }}
      />
    </>
  );
}

export default ProjectDetailsReportLogsTab;
