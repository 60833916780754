import { logLevels, logTypes, paymentStatuses, planTypes, reportStatuses } from "utils/entities";
import { theme } from "utils/theme";

import { Failed, PlanBronze, PlanGold, PlanSilver, Unavailable } from "components/icons";

export const numberToPercentage = ({ value }) => {
  const parsedValue = Math.abs(Math.round(value * 100));

  return `${parsedValue}%`;
};

export const getBadgeParams = ({ parameter }) => {
  switch (parameter) {
    case logLevels.INFO:
      return {
        backgroundColor: theme.colors.LOG_LEVEL_INFO_BACKGROUND,
        color: theme.colors.LOG_LEVEL_INFO_TEXT,
        label: "Info",
      };
    case logLevels.WARNING:
      return {
        backgroundColor: theme.colors.LOG_LEVEL_WARNING_BACKGROUND,
        color: theme.colors.LOG_LEVEL_WARNING_TEXT,
        label: "Warning",
      };
    case logLevels.ERROR:
      return {
        backgroundColor: theme.colors.LOG_LEVEL_ERROR_BACKGROUND,
        color: theme.colors.LOG_LEVEL_ERROR_TEXT,
        label: "Error",
      };
    case logLevels.CRITICAL:
      return {
        backgroundColor: theme.colors.LOG_LEVEL_CRITICAL_BACKGROUND,
        color: theme.colors.LOG_LEVEL_CRITICAL_TEXT,
        label: "Critical",
      };
    //
    case logTypes.PROJECT:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "Project",
      };
    case logTypes.LIGHTHOUSE_REPORT:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "Lighthouse report",
      };
    case logTypes.SEO_REPORT:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "SEO report",
      };
    case logTypes.SERP_REPORT:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "SERP report",
      };
    case logTypes.USER:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "User",
      };
    //
    case reportStatuses.DONE:
      return {
        backgroundColor: theme.colors.LOG_LEVEL_INFO_BACKGROUND,
        color: theme.colors.LOG_LEVEL_INFO_TEXT,
        label: "Done",
      };
    case reportStatuses.FAILURE:
      return {
        backgroundColor: theme.colors.LOG_LEVEL_CRITICAL_BACKGROUND,
        color: theme.colors.LOG_LEVEL_CRITICAL_TEXT,
        label: "Failure",
      };
    case reportStatuses.PREPARING:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "Preparing",
      };
    case reportStatuses.STARTED:
      return {
        backgroundColor: theme.colors.DEFAULT_BADGE_BACKGROUND,
        color: theme.colors.DEFAULT_BADGE_TEXT,
        label: "Started",
      };
    default:
      return {};
  }
};

export const parseRoute = ({ route = {}, params = {}, queryParams = {} }) => {
  Object.keys(params).map((param) => {
    route = route.replace(`:${param}?`, params[param]);
    route = route.replace(`:${param}`, params[param]);

    return route;
  });

  const queryParamKeys = Object.keys(queryParams);

  if (queryParamKeys?.length) {
    queryParamKeys.map((queryParam, idx) => {
      if (queryParamKeys?.length === 1 || (queryParamKeys?.length === 1 && idx === 0)) {
        route = `${route}?${queryParam}=${queryParams[queryParam]}`;
      } else {
        route += `&${queryParam}=${queryParams[queryParam]}`;
      }

      return route;
    });
  }

  return route;
};

export const merge = ({ array = [], elements = [], element }) => {
  if (element) {
    return array ? [...new Set([...array, element])] : [element];
  }

  return array ? [...new Set([...array, ...elements])] : [...elements];
};

export const capitalize = ({ string }) => string && string[0].toUpperCase() + string.slice(1);

export const getPlanIcon = ({ planId }) => {
  switch (planId) {
    case planTypes.DEMO:
    case planTypes.TRIAL:
    case planTypes.CUSTOM:
    default:
      return null;
    case planTypes.SEODITY_PLAN_1:
      return PlanBronze;
    case planTypes.SEODITY_PLAN_2:
      return PlanSilver;
    case planTypes.SEODITY_PLAN_3:
      return PlanGold;
  }
};

export const getPaymentStatusIcon = ({ paymentStatus }) => {
  switch (paymentStatus) {
    case paymentStatuses.FAILED:
      return {
        icon: Failed,
        label: "Failed",
      };
    default:
      return {
        icon: Unavailable,
        label: "",
      };
  }
};

export const convertStorageToString = ({ bytes }) =>
  bytes > 1073741824 // więcej niż 1GB
    ? `${Math.ceil((bytes / 1024 / 1024 / 1024) * 100) / 100} GB`
    : bytes > 1048576 // więcej niż 1MB
    ? `${Math.ceil((bytes / 1024 / 1024) * 100) / 100} MB`
    : bytes > 1024 // więcej niż 1KB
    ? `${Math.ceil((bytes / 1024) * 100) / 100} KB`
    : bytes > 1 // więcej niż 1B
    ? `${bytes} B`
    : "-";

export const getOptionsFromEntities = ({ entities, labelProp, valueProp }) =>
  !labelProp || !valueProp || !entities
    ? []
    : Object.values(entities)?.map((e) => ({
        label: e?.[labelProp],
        value: e?.[valueProp],
      }));

export const parseMoney = ({ amount, decimalDigits = 2 }) => parseInt(Math.round(amount * 10 ** decimalDigits), 10);

export const displayAmount = ({ amount, decimalDigits = 2 }) => (amount / 10 ** decimalDigits).toFixed(decimalDigits);

export const getPath = (...segments) =>
  segments?.reduce((acc, cur) => (acc.endsWith("/") && cur.startsWith("/") ? acc + cur.replace("/", "") : acc + cur));

export const getUniqueKeywordKey = ({ keyword }) =>
  `${keyword.keyword}-${keyword.country_iso_code}-${keyword.location}-${keyword.is_mobile}`;

export const getRegionLabel = ({ region }) => region?.replace(new RegExp(",", "g"), ", ");

export const getRegionValue = ({ region }) => region?.replace(new RegExp(", ", "g"), ",");

export const getCountryOptions = ({ countryData }) =>
  countryData
    ?.map(({ country_name: countryName, country_iso_code: countryIsoCode }) => ({
      label: countryName,
      value: countryIsoCode,
    }))
    ?.sort((prev, curr) => (prev.label < curr.label ? -1 : prev.label > curr.label ? 1 : 0));

export const getLanguageOptions = ({ languageData }) =>
  languageData
    ?.map(({ language_name: languageName, language_code: languageCode }) => ({
      label: languageName,
      value: languageCode,
    }))
    ?.sort((prev, curr) => (prev.label < curr.label ? -1 : prev.label > curr.label ? 1 : 0));

const filterCountries = ({ location, isoCode, regions }) =>
  location && isoCode && location.length >= 3
    ? regions.filter((region) => region.label.toLowerCase().includes(location.toLowerCase()))
    : [];

export const getRegionsForCountry = ({ regionData, location, isoCode }) => {
  const regions =
    regionData?.map((region) => ({
      label: getRegionLabel({ region: region?.location_name }),
      value: region,
    })) || [];

  return filterCountries({ location, isoCode, regions });
};

export const parseKeyword = ({ formValues }) => ({
  keyword: formValues?.keyword,
  country: formValues?.country?.label,
  country_iso_code: formValues?.country?.value,
  language: formValues?.language?.label,
  location: formValues?.region?.value?.location_name || formValues?.country?.label,
  is_mobile: Boolean(formValues?.is_mobile?.value),
});

export const isSameKeyword = ({ keyword1, keyword2 }) =>
  keyword1.country === keyword2.country &&
  keyword1.is_mobile === keyword2.is_mobile &&
  keyword1.keyword === keyword2.keyword &&
  keyword1.language === keyword2.language &&
  keyword1.location === keyword2.location;

export const parseJSON = (data) => {
  try {
    const o = JSON.parse(data);

    // Handle non-exception-throwing cases
    if (o && typeof o === "object") {
      return o;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return false;
};
