import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useCallback, useEffect } from "react";

import { useModal, useTabs } from "utils/hooks";
import { modalKeys, tabKeys, viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";
import { theme } from "utils/theme";
import { capitalize, getPlanIcon } from "utils/formatters";

import { AppTemplate } from "components/templates";
import {
  Drawer,
  JsonDisplay,
  LoadableValue,
  SummaryGrid,
  SummaryValue,
  TopPanel,
  UserIndicator,
} from "components/shared";
import { Message } from "components/utils/Typography";
import { Button } from "components/forms";
import { Box } from "components/utils";

import {
  UserDetailsProjectsTab,
  UserDetailsResourcesTab,
  UserDetailsActivityTab,
  UserDetailsSettingsTab,
} from "Users/tabs";
import { getUser, usersSelector } from "Users/redux/usersSlice";

function UserDetailsPage() {
  const route = getRouteByKey({ viewKey: viewKeys.USER_DETAILS });
  const { tabs } = route;

  const dispatch = useDispatch();

  const { id } = useParams();

  const { selectedTab, goToTab } = useTabs({ tabs });

  const { entities: usersEntities, error: userError } = useSelector(usersSelector);

  const user = usersEntities?.[id];

  useEffect(() => {
    dispatch(getUser({ id }));
  }, [dispatch]);

  const TopPanelComponent = () => (
    <TopPanel
      title={route?.label}
      leftSection={
        <Box>
          <LoadableValue
            spinnerProps={{
              color: theme.colors.PRIMARY,
              scale: "0.105",
              alignLeft: true,
            }}
          >
            <UserIndicator user={user} />
          </LoadableValue>
        </Box>
      }
      rightSection={
        <Box>
          <SummaryGrid
            items={[
              <SummaryValue
                caption="Plan"
                value={capitalize({ string: user?.plan_id })}
                icon={getPlanIcon({ planId: user?.plan_id })}
              />,
            ]}
          />
        </Box>
      }
    />
  );

  const { isOpen: openedUserDetailsJsonId, setOpen: setOpenedUserDetailsJsonId } = useModal();

  const OptionsComponent = useCallback(
    () => (
      <>
        <Button
          variant={Button.Variants.SECONDARY}
          onClick={() => {
            setOpenedUserDetailsJsonId(modalKeys.UI_ID_USER_DETAILS_JSON_MODAL);
          }}
        >
          JSON
        </Button>
        <Drawer
          isOpen={openedUserDetailsJsonId === modalKeys.UI_ID_USER_DETAILS_JSON_MODAL}
          onRequestClose={() => {
            setOpenedUserDetailsJsonId(null);
          }}
        >
          <JsonDisplay json={user} />
        </Drawer>
      </>
    ),
    [openedUserDetailsJsonId, user],
  );

  return (
    <AppTemplate
      route={route}
      tabNavigation={{
        tabs,
        goToTab,
        selectedTab,
      }}
      TopPanelComponent={!userError && TopPanelComponent}
      OptionsComponent={!userError && OptionsComponent}
    >
      {userError ? (
        <Message>{userError}</Message>
      ) : (
        <>
          {selectedTab?.key === tabKeys.USER_DETAILS_PROJECTS && <UserDetailsProjectsTab user={user} />}
          {selectedTab?.key === tabKeys.USER_DETAILS_RESOURCES && <UserDetailsResourcesTab />}
          {selectedTab?.key === tabKeys.USER_DETAILS_ACTIVITY && <UserDetailsActivityTab />}
          {selectedTab?.key === tabKeys.USER_DETAILS_SETTINGS && <UserDetailsSettingsTab user={user} />}
        </>
      )}
    </AppTemplate>
  );
}

export default UserDetailsPage;
