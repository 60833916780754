import { useParams } from "react-router";
import { viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";

import { AppTemplate } from "components/templates";
import { TopPanel } from "components/shared";

import { useGetBlogPostQuery } from "api/services/blogPost";
import { PostForm } from "Blog/components";

const BlogEditPage = () => {
  const route = getRouteByKey({ viewKey: viewKeys.BLOG_EDIT });
  const { id } = useParams();

  const { data: post, isLoading } = useGetBlogPostQuery(id);

  const TopPanelComponent = () => <TopPanel title={route?.label} leftSection />;

  return (
    <AppTemplate route={route} TopPanelComponent={TopPanelComponent} hideTabNavigation>
      <PostForm post={post} isPostLoading={isLoading} />
    </AppTemplate>
  );
};

export default BlogEditPage;
