import { SelectInput } from "components/forms";
import { Box } from "components/utils";
import styled, { css } from "styled-components/macro";

import { getPageCount } from "utils/pagination";
import { theme } from "utils/theme";
import { limitOptions } from "utils/options";
import { useQueryParams } from "utils/hooks";
import { getOptionByValue } from "utils/forms";
import { DEFAULT_PAGINATION_LIMIT } from "utils/constants";

function Pagination({ setPage, currentPage = 1, itemsCount, limit = DEFAULT_PAGINATION_LIMIT }) {
  const { setQuery } = useQueryParams();

  const pageCount = getPageCount({ itemsCount, limit });
  const currentPageNumber = parseInt(currentPage, 10);

  const canGoPrevious = currentPage > 1;
  const canGoNext = currentPageNumber < pageCount;

  return (
    <Wrapper>
      <Box flex alignCenter margin="0 20px 0 0">
        <PageLimiterLabel>On page:</PageLimiterLabel>
        <SelectInput
          width="64px"
          inverted
          onChange={({ value: newLimit }) => {
            setQuery({
              limit: newLimit,
              page: 1,
            });
          }}
          value={getOptionByValue({ options: limitOptions, value: parseInt(limit, 10) })}
          options={limitOptions}
        />
      </Box>
      {/* <NextPreviousButton
        disabled={!canGoPrevious}
        onClick={() => {
          setPage(1);
        }}
      >
        First
      </NextPreviousButton> */}
      <NextPreviousButton
        disabled={!canGoPrevious}
        onClick={() => {
          setPage(currentPageNumber - 1);
        }}
      >
        Previous
      </NextPreviousButton>
      <Box flex alignCenter margin="0 10px">
        <PaginationItem>{currentPage}</PaginationItem>
      </Box>
      <NextPreviousButton
        disabled={!canGoNext}
        onClick={() => {
          setPage(currentPageNumber + 1);
        }}
      >
        Next
      </NextPreviousButton>
      {/* <NextPreviousButton
        disabled={!canGoNext}
        onClick={() => {
          setPage(pageCount);
        }}
      >
        Last
      </NextPreviousButton> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NextPreviousButton = styled.button`
  font-family: ${theme.fonts.RUBIK};
  font-weight: ${theme.fontWeights.MEDIUM};
  font-size: ${theme.sizes.PAGINATION_TEXT};
  color: ${theme.colors.TABLE_PAGINATION_ACTIVE_TEXT};
  border: none;
  appearance: none;
  background: none;
  outline: none;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.TABLE_PAGINATION_DISABLED_TEXT};
      cursor: default;
      pointer-events: none;
    `};
`;

const PaginationItem = styled.div`
  min-width: 12px;
  font-size: ${theme.sizes.PAGINATION_NUMBER};
  font-weight: ${theme.fontWeights.MEDIUM};
  text-decoration: underline;
`;

const PageLimiterLabel = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-weight: ${theme.fontWeights.MEDIUM};
  font-size: ${theme.sizes.PAGINATION_TEXT};
  color: ${theme.colors.TABLE_PAGINATION_ACTIVE_TEXT};
`;

export default Pagination;
