import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg
      id="sort_numeric_asc_icon_Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 10.642 12.83"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.sort_numeric_asc_icon_st0{fill:${color}}`}</style>
      <path
        className="sort_numeric_asc_icon_st0"
        d="M3.74 2.382c0 1.438-.604 2.425-1.844 2.425C.641 4.807.09 3.727.081 2.411.081 1.066.678 0 1.933 0c1.3 0 1.807 1.109 1.807 2.382zm-2.516.029c-.008 1.066.261 1.573.702 1.573s.68-.529.68-1.588c0-1.03-.232-1.574-.688-1.574-.418 0-.702.509-.694 1.589zM.503 11.97c.141.015.268.015.493 0 .343-.021.694-.114.956-.286.313-.207.522-.508.612-.858l-.023-.007c-.216.214-.53.336-.97.336-.822 0-1.516-.55-1.516-1.452 0-.909.761-1.674 1.83-1.674 1.246 0 1.836.915 1.836 2.003 0 .965-.321 1.674-.851 2.139-.463.4-1.098.622-1.852.65a4.093 4.093 0 01-.515 0v-.85zm.687-2.332c0 .4.224.744.687.744.306 0 .522-.143.627-.307a.492.492 0 00.06-.272c0-.494-.194-.98-.71-.98-.388 0-.672.329-.664.815z"
      />
      <path
        d="M8.046 12.256c.107.118.236.17.42.18a.55.55 0 00.41-.198l1.64-1.978a.55.55 0 10-.847-.701l-.654.789.019-5.032a.565.565 0 00-.55-.552.55.55 0 00-.55.548l-.02 5.16-.801-.886a.551.551 0 00-.816.738l1.749 1.932z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
