import { useMemo, useState } from "react";
import { css } from "styled-components/macro";

import { columnTypes, modalKeys } from "utils/constants";
import { useModal, useQueryParams } from "utils/hooks";
import { getMetaParams } from "utils/pagination";
import toast, { toastIds } from "utils/toast";

import { NullableValue, Table, MenuDropdown, ConfirmationModal, ModalWithForm } from "components/shared";
import { Remove } from "components/icons";
import { Button } from "components/forms";

import { AuthorForm } from "Blog/components";
import { useDeleteBlogAuthorMutation } from "api/services/blogAuthor";

const BlogAuthorsTab = ({ authors, isLoading, isFetching }) => {
  const { params } = useQueryParams();

  const { isOpen: openedCreateAuthorId, setOpen: setOpenedCreateAuthorId } = useModal();
  const { isOpen: openedUpdateAuthorId, setOpen: setOpenedUpdateAuthorId } = useModal();
  const [authorToUpdate, setAuthorToUpdate] = useState(null);
  const [authorToRemoveId, setAuthorToRemoveId] = useState(null);

  const [deleteAuthor] = useDeleteBlogAuthorMutation();

  const headers = useMemo(
    () => [
      { label: "Author", value: "name", type: columnTypes.ALPHA, sortable: true, maxWidth: "30%" },
      { label: "Description", value: "description", type: columnTypes.ALPHA, sortable: true },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      authors?.blog_authors?.map((author) => {
        return {
          options: {
            key: author?.uid,
          },
          columns: {
            author: <NullableValue>{author?.name}</NullableValue>,
            description: <NullableValue>{author?.description}</NullableValue>,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "Edit",
                      value: "edit",
                      onClick: () => {
                        setAuthorToUpdate(author);
                        setOpenedUpdateAuthorId(modalKeys.UI_ID_UPDATE_AUTHOR_MODAL);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setAuthorToRemoveId(author?.uid);
                      },
                    },
                  ]}
                />
              </>
            ),
          },
        };
      }),
    [authors],
  );

  const handleAuthorRemove = async () => {
    if (authorToRemoveId) {
      try {
        await deleteAuthor(authorToRemoveId).unwrap();

        toast.success("Author removed successfully.", {
          toastId: toastIds.SUCCESS_REMOVE_AUTHOR,
        });
      } catch (error) {
        toast.error("An error occurred while removing author. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_AUTHOR,
        });
      }
    }
    setAuthorToRemoveId(null);
  };

  return (
    <>
      <Button
        type="button"
        variant={Button.Variants.ACTION}
        onClick={() => setOpenedCreateAuthorId(modalKeys.UI_ID_CREATE_AUTHOR_MODAL)}
        css={css`
          margin-left: auto;
          display: flex;
        `}
      >
        Add author
      </Button>
      <Table
        isLoading={isLoading}
        isRefetchLoading={isFetching}
        headers={headers}
        rows={rows}
        meta={getMetaParams({ queryParams: params })}
        emptyStateText="No authors available"
      />
      <ModalWithForm
        width="510px"
        title="Add new author"
        titleAlign="left"
        id={modalKeys.UI_ID_CREATE_AUTHOR_MODAL}
        isOpen={openedCreateAuthorId === modalKeys.UI_ID_CREATE_AUTHOR_MODAL}
        onRequestClose={() => {
          setOpenedCreateAuthorId(null);
        }}
      >
        <AuthorForm setOpened={setOpenedCreateAuthorId} />
      </ModalWithForm>
      <ModalWithForm
        width="510px"
        title="Edit"
        titleAlign="left"
        id={modalKeys.UI_ID_UPDATE_AUTHOR_MODAL}
        isOpen={openedUpdateAuthorId === modalKeys.UI_ID_UPDATE_AUTHOR_MODAL}
        onRequestClose={() => {
          setOpenedUpdateAuthorId(null);
        }}
      >
        <AuthorForm setOpened={setOpenedUpdateAuthorId} author={authorToUpdate} />
      </ModalWithForm>
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected author?"
        id={modalKeys.UI_ID_REMOVE_AUTHOR_MODAL}
        isOpen={authorToRemoveId}
        onConfirm={handleAuthorRemove}
        onCancel={() => {
          setAuthorToRemoveId(null);
        }}
      />
    </>
  );
};

export default BlogAuthorsTab;
