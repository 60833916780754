import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["User"],
});

const userApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: (uid) => `/v1/users/${uid}`,
      providesTags: (result, error, uid) => [{ type: "User", id: uid }],
    }),
    updateUser: build.mutation({
      query(data) {
        return {
          url: `/v1/users/${data.uid}`,
          method: "PATCH",
          body: data.data,
        };
      },
      invalidatesTags: () => [{ type: "User" }],
    }),
  }),
});

export const { useGetUserQuery, useUpdateUserMutation } = userApi;
