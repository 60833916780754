import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="menu_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 2.288 11.438"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".menu_icon_st0{fill:#636a7b}"}</style>
      <path
        className="menu_icon_st0"
        d="M1.145 2.287A1.144 1.144 0 111.143 0a1.144 1.144 0 01.002 2.287M1.145 6.862a1.143 1.143 0 11-.003-2.287 1.143 1.143 0 01.003 2.287M1.145 11.438a1.144 1.144 0 11-.002-2.288 1.144 1.144 0 01.002 2.287"
      />
    </svg>
  );
}

export default SvgComponent;
