/* eslint-disable react/no-array-index-key */
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { booleanOptions, isActiveOptions } from "utils/options";
import toast, { toastIds } from "utils/toast";
import { getOptionByValue, convertFormValuesToRequestInput, getChangedFields } from "utils/forms";

import { Button, ButtonGroup, FormGroup, SelectInput } from "components/forms";
import { Box } from "components/utils";

import { getProject, updateProject } from "Projects/redux/projectsSlice";

function EditProjectForm({ initialValues }) {
  const [operationInProgress, setOperationInProgress] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    errors: formErrors,
    control,
    reset,
    formState: { isDirty, isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: {
      ...initialValues,
      is_active: getOptionByValue({ options: isActiveOptions, value: initialValues?.is_active }),
    },
  });

  const disableSubmit = !isDirty || isSubmitting;

  const onSubmit = async (values) => {
    const changedValues = getChangedFields({ values, dirtyFields });
    const data = convertFormValuesToRequestInput({ values: changedValues });

    try {
      setOperationInProgress(true);

      const id = initialValues?.uid;

      const rs = await dispatch(updateProject({ id, data }));

      if (rs?.payload) {
        if (rs?.payload?.error) {
          toast.error("An error occurred while updating project data. Please try again shortly.", {
            toastId: toastIds.ERROR_UPDATE_PROJECT,
          });
        } else {
          toast.success("Project updated successfully", {
            toastId: toastIds.SUCCESS_UPDATE_PROJECT,
          });

          await dispatch(getProject({ id: initialValues?.uid }));
          reset(values);
        }
      }
    } catch (error) {
      //
    } finally {
      setOperationInProgress(false);
    }
  };

  const fieldGroups = [
    {
      label: "GENERAL",
      fields: [
        {
          label: "Is active",
          name: "is_active",
          component: SelectInput,
          validation: {
            required: "Is active value is required",
          },
          options: booleanOptions,
          control,
        },
        {
          emptySpace: true,
        },
        {
          emptySpace: true,
        },
        {
          emptySpace: true,
        },
        {
          emptySpace: true,
        },
      ],
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fieldGroups?.map(({ label, fields, noBorder }, fgIdx) => (
        <FormGroup key={label || fgIdx} title={label} noBorder={noBorder}>
          {fields.map(({ component: Component, validation, inputProps, emptySpace, ...fieldProps }, fIdx) =>
            emptySpace ? (
              <Box key={fIdx} />
            ) : (
              <Component
                key={fieldProps?.name}
                ref={fieldProps?.control ? null : register(validation)}
                error={formErrors?.[fieldProps?.name]?.message}
                width="100%"
                {...inputProps}
                {...fieldProps}
              />
            ),
          )}
        </FormGroup>
      ))}
      <Box flex justifyRight margin="20px 0 0">
        <ButtonGroup>
          <Button
            disabled={disableSubmit}
            type="submit"
            loading={operationInProgress}
            variant={Button.Variants.SECONDARY}
          >
            Save changes
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
}

export default EditProjectForm;
