import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="projects_icon_Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 20.708 16"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".projects_icon_st0{fill:#8794b2}"}</style>
      <path
        className="projects_icon_st0"
        d="M6.745 9.663h.937V6.255H3.508V9.663h3.237zm-2.3-2.47h2.3v1.533h-2.3V7.193zM3.295 11.707h11.5v.937h-11.5z"
      />
      <path
        className="projects_icon_st0"
        d="M18.733 0H6.039c-.63 0-1.226.292-1.598.782a1.835 1.835 0 00-.378 1.116v.854H2.632a2.67 2.67 0 00-2.12 1.04A2.487 2.487 0 000 5.307v8.14C0 14.854 1.18 16 2.632 16h12.693c1.451 0 2.632-1.146 2.632-2.554v-1.509h.776c1.09 0 1.975-.851 1.975-1.898v-8.14C20.708.85 19.822 0 18.733 0zm-2.088 13.446c0 .686-.591 1.242-1.32 1.242H2.632c-.729 0-1.32-.556-1.32-1.242v-8.14c0-.269.09-.518.245-.72.239-.317.631-.522 1.075-.522h12.693c.729 0 1.32.556 1.32 1.242v8.14zm2.751-3.407c0 .323-.297.586-.663.586h-.776v-5.32c0-1.408-1.18-2.553-2.632-2.553h-9.95v-.854c0-.147.06-.257.111-.324a.692.692 0 01.553-.262h12.694c.366 0 .663.263.663.586v8.14z"
      />
    </svg>
  );
}

export default SvgComponent;
