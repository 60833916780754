import styled, { css } from "styled-components/macro";

import { theme } from "utils/theme";

const baseText = css`
  font-family: ${theme.fonts.RUBIK};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

export const Breadcrumb = styled.div`
  ${baseText};
  font-size: ${theme.sizes.BREADCRUMB_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.BREADCRUMB_TEXT};
  line-height: 2;
  text-decoration: ${({ isLast }) => (isLast ? "none" : "underline")};
  cursor: ${({ isLink }) => (isLink ? "pointer" : "unset")};

  a {
    &,
    &:visited,
    &:hover,
    &:active {
      font-style: inherit;
      color: inherit;
      background-color: transparent;
      font-size: inherit;
      text-decoration: none;
      font-variant: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-family: inherit;
    }
  }
`;

export const AdminEmailLabel = styled.div`
  ${baseText};
  font-size: ${theme.sizes.ADMIN_EMAIL_LABEL_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.ADMIN_EMAIL_LABEL_TEXT};
  line-height: 1.83;
`;

export const SectionTitle = styled.div`
  ${baseText};
  font-size: ${theme.sizes.PAGE_HEADING_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.PAGE_HEADING_TEXT};
  line-height: 0.92;
`;

export const TimeframeValue = styled.div`
  ${baseText};
  font-size: ${theme.sizes.INPUT_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.CALENDAR_TIMEFRAME_VALUE_TEXT};
  line-height: 1.83;
`;

export const Message = styled.div`
  ${baseText};
  font-size: ${theme.sizes.INPUT_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${({ color }) => color};
  line-height: 1.83;
`;

export const Paragraph = styled.div`
  ${baseText};
  font-size: 12px;
  line-height: 1.83;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.SUMMARY_CAPTION_TEXT};
  text-align: center;
  margin-top: 8px;
`;
