import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { columnTypes, modalKeys, viewKeys } from "utils/constants";
import { getRouteByKey, routes } from "utils/routes";
import { useModal, usePageLoading, useQueryParams } from "utils/hooks";
import { getPaginationParams, getMetaParams } from "utils/pagination";
import toast, { toastIds } from "utils/toast";
import { parseRoute } from "utils/formatters";

import {
  Badge,
  ConfirmationModal,
  DateTimeValue,
  Drawer,
  JsonDisplay,
  LinkValue,
  MenuDropdown,
  NullableValue,
  SummaryValue,
  Table,
  TopPanel,
} from "components/shared";
import { Box } from "components/utils";
import { Remove } from "components/icons";
import { AppTemplate } from "components/templates";

import { pendingActionsSelector } from "App/redux/appSlice";
import {
  actionTypes,
  getLighthouseReports,
  lighthouseReportsSelector,
  removeLighthouseReport,
} from "Lighthouse/redux/lighthouseSlice";

function LighthouseReportsPage() {
  const { initialLoading } = usePageLoading();

  const route = getRouteByKey({ viewKey: viewKeys.LIGHTHOUSE_REPORTS });

  const dispatch = useDispatch();
  const history = useHistory();

  const { params } = useQueryParams();

  const {
    ids: lighthouseReportIds,
    entities: lighthouseReportEntities,
    meta: lighthouseReportsMeta,
  } = useSelector(lighthouseReportsSelector);
  const loadingLighthouseReports = useSelector((state) =>
    pendingActionsSelector({ state, actions: actionTypes.GET_LIGHTHOUSE_REPORTS }),
  );

  useEffect(() => {
    dispatch(
      getLighthouseReports({
        params: {
          ...getPaginationParams({ params }),
        },
      }),
    );
  }, [dispatch, params]);

  const TopPanelComponent = useCallback(
    () => (
      <TopPanel
        title={route?.label}
        leftSection={<Box />}
        rightSection={
          <Box>
            <SummaryValue value={lighthouseReportsMeta?.all} caption="Lighthouse reports count" />
          </Box>
        }
      />
    ),
    [lighthouseReportsMeta, params],
  );

  const headers = useMemo(
    () => [
      { label: "E-mail", value: "account.email", type: columnTypes.ALPHA, sortable: false },
      { label: "Domain", value: "project_domain", type: columnTypes.ALPHA, sortable: true },
      { label: "Status", value: "status", type: columnTypes.ALPHA, sortable: true },
      { label: "Error", value: "failure_reason", type: columnTypes.ALPHA, sortable: true },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    [],
  );

  const [reportToRemoveId, setReportToRemoveId] = useState(null);
  const { isOpen: openedJsonId, setOpen: setOpenedJsonId } = useModal();

  const rows = useMemo(
    () =>
      lighthouseReportIds?.map((id) => {
        const report = lighthouseReportEntities?.[id];

        return {
          options: {
            key: id,
            onRowClick: () => {
              setOpenedJsonId(id);
            },
          },
          columns: {
            email: (
              <LinkValue
                onClick={() => {
                  history.push(
                    parseRoute({
                      route: routes.USER_DETAILS,
                      params: {
                        id: report?.user?.uid,
                      },
                    }),
                  );
                }}
              >
                {report?.account?.email}
              </LinkValue>
            ),
            domain: <NullableValue>{report?.project_domain}</NullableValue>,
            status: <Badge parameter={report?.status} />,
            failure_reason: <NullableValue>{report?.failure_reason}</NullableValue>,
            created_at: <DateTimeValue date={report?.created_at} />,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "JSON",
                      value: "json",
                      onClick: () => {
                        setOpenedJsonId(id);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setReportToRemoveId(id);
                      },
                    },
                  ]}
                />
                <Drawer
                  isOpen={openedJsonId === id}
                  onRequestClose={() => {
                    setOpenedJsonId(null);
                  }}
                >
                  <JsonDisplay json={report} />
                </Drawer>
              </>
            ),
          },
        };
      }),
    [lighthouseReportIds, openedJsonId],
  );

  const handleReportRemove = async () => {
    if (reportToRemoveId) {
      try {
        const rs = await dispatch(removeLighthouseReport({ id: reportToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("Lighthouse report removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_LIGHTHOUSE_REPORT,
          });

          dispatch(
            getLighthouseReports({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error("An error occurred while removing lighthouse report. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_LIGHTHOUSE_REPORT,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing lighthouse report. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_LIGHTHOUSE_REPORT,
        });
      }
    }

    setReportToRemoveId(null);
  };

  return (
    <>
      <AppTemplate route={route} TopPanelComponent={TopPanelComponent}>
        <Table
          isLoading={!lighthouseReportIds}
          isRefetchLoading={loadingLighthouseReports || initialLoading}
          headers={headers}
          rows={rows}
          meta={getMetaParams({ meta: lighthouseReportsMeta, queryParams: params })}
          emptyStateText="No reports available"
        />
      </AppTemplate>
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected report?"
        id={modalKeys.UI_ID_REMOVE_REPORT_MODAL}
        isOpen={reportToRemoveId}
        onConfirm={handleReportRemove}
        onCancel={() => {
          setReportToRemoveId(null);
        }}
      />
    </>
  );
}

export default LighthouseReportsPage;
