import styled from "styled-components/macro";

import { getBadgeParams } from "utils/formatters";

function Badge({ parameter }) {
  if (!parameter) {
    return "-";
  }

  const { color, backgroundColor, label } = getBadgeParams({ parameter });

  return (
    <Wrapper backgroundColor={backgroundColor} color={color}>
      {label}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  border-radius: 4px;
  line-height: 2;
  padding: 0 10px;
`;

export default Badge;
