import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";

import { theme } from "utils/theme";
import { getNavbarRoutes } from "utils/routes";

import { Button } from "components/forms";
import { LoadableValue, NavLink, NavLogo } from "components/shared";
import { AdminEmailLabel } from "components/utils/Typography";

import { authSelector, logout } from "Auth/redux/authSlice";

function Navbar() {
  const { admin } = useSelector(authSelector);

  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(logout());
    } catch (error) {
      //
    }
  };

  const navbarRoutes = getNavbarRoutes();

  return (
    <div>
      <Wrapper>
        <LogoContainer>
          <NavLogo />
        </LogoContainer>
        <NavLinkContainer linkCount={navbarRoutes?.length}>
          {navbarRoutes?.map((route) => (
            <NavLink key={route?.path} route={route} />
          ))}
        </NavLinkContainer>
        <UserInfoContainer>
          <AdminEmailWrapper>
            <LoadableValue component={AdminEmailLabel}>{admin?.email}</LoadableValue>
          </AdminEmailWrapper>
          <Button variant={Button.Variants.SECONDARY} onClick={handleLogout}>
            Log out
          </Button>
        </UserInfoContainer>
      </Wrapper>
      <BottomLine />
    </div>
  );
}

const Wrapper = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-evenly;
  align-content: space-evenly;
  padding: ${theme.sizes.NAVBAR_PADDING};
  background-color: ${theme.colors.WHITE};
  height: ${theme.sizes.NAVBAR_HEIGHT};
  max-width: ${theme.sizes.CONTAINER_WIDTH};
  width: 100%;
  margin: 0 auto;

  & > * {
    margin-left: auto;
    margin-right: auto;
  }

  & > :first-child {
    margin-left: unset;
    margin-right: auto;
  }

  & > :last-child {
    margin-right: unset;
    margin-left: auto;
  }
`;

const AdminEmailWrapper = styled.div`
  margin-right: 30px;
`;

const LogoContainer = styled.div``;

const NavLinkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ linkCount }) => linkCount || 1}, 1fr);
  column-gap: 10px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BottomLine = styled.div`
  height: 1px;
  opacity: 0.2;
  width: 100%;
  background-color: ${theme.colors.NAVBAR_BOTTOM_LINE};
`;

export default Navbar;
