import * as React from "react";

function SvgComponent({ color = "#8695b1", ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.75 9.748" {...props}>
      <path
        d="M9.61 8.78L6.231 5.402l-.53-.529.53-.53L9.609.968C9.812.764 9.792.42 9.56.19c-.232-.232-.575-.252-.78-.05L5.405 3.518l-.53.53-.529-.53L.97.14C.765-.063.422-.043.189.189-.042.42-.062.764.14.967l3.378 3.378.53.53-.53.528L.14 8.779c-.205.206-.183.548.05.78.232.232.575.253.779.049L4.345 6.23l.53-.53.53.53L8.78 9.608c.204.204.547.183.78-.049.231-.232.253-.574.048-.78z"
        fill={color}
      />
    </svg>
  );
}

export default SvgComponent;
