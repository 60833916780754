import { useParams } from "react-router";
import { viewKeys } from "utils/constants";
import { getRouteByKey } from "utils/routes";

import { AppTemplate } from "components/templates";
import { TopPanel } from "components/shared";

import { useGetPromptQuery } from "../../api/services/prompt";
import { PromptForm } from "../components";

const PromptEditPage = () => {
  const route = getRouteByKey({ viewKey: viewKeys.PROMPT_EDIT });
  const { id } = useParams();

  const { data: prompt, isLoading } = useGetPromptQuery(id);

  const TopPanelComponent = () => <TopPanel title={route?.label} leftSection />;

  return (
    <AppTemplate route={route} TopPanelComponent={TopPanelComponent} hideTabNavigation>
      <PromptForm prompt={prompt} isPromptLoading={isLoading} />
    </AppTemplate>
  );
};

export default PromptEditPage;
