import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import qs from "query-string";

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const params = useMemo(() => qs.parse(location?.search, { ignoreQueryPrefix: true }), [location]);

  const setQuery = (newParams) => {
    const queryParams = qs.parse(location.search);
    const newParamsMerged = { ...queryParams, ...newParams };

    const newQueryString = qs.stringify(newParamsMerged, {
      skipNull: true,
      skipEmptyString: true,
    });

    history.push(`${newQueryString.length > 0 ? "?" : ""}${newQueryString}` || location.pathname);
  };

  return { params, setQuery };
};

export default useQueryParams;
