import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="info_icon_Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 3.879 9.165"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".info_icon_st0{fill:#2783b7}"}</style>
      <path
        className="info_icon_st0"
        d="M3.88 7.02c-.925 2.525-4.454 3.203-3.8-.07l.772-3.705h1.613c-.374 1.975-.643 3.179-.912 4.558-.269 1.402 1.473.362 1.87-.783h.456zM1.385 1.873a1.071 1.071 0 01-.29-.774c.014-.303.11-.552.317-.772.221-.207.47-.318.787-.318.317 0 .552.11.76.318.22.22.331.469.331.772 0 .318-.125.567-.345.774-.221.207-.485.304-.787.304a1.05 1.05 0 01-.773-.304z"
      />
    </svg>
  );
}

export default SvgComponent;
