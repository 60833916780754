/* eslint-disable react/no-array-index-key */
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import {
  booleanOptions,
  isActiveOptions,
  paymentStatusesOptions,
  planStatusesOptions,
  planTypesOptions,
} from "utils/options";
import toast, { toastIds } from "utils/toast";
import { dateFormats } from "utils/constants";
import { getOptionByValue, convertFormValuesToRequestInput, getChangedFields } from "utils/forms";

import { Button, ButtonGroup, FormGroup, SelectInput, TextInput } from "components/forms";
import { Box } from "components/utils";

import { getUser } from "Users/redux/usersSlice";
import { useUpdateUserMutation } from "api/services/user";
import { useUpdateAccountMutation } from "../../api/services/account";
import { useGetProductsQuery } from "../../api/services/app";

const getOptionsForProduct = () => {
  const options = [];
  options?.unshift({ label: "✅ enabled", value: "enabled" });
  options?.unshift({ label: "🚫 disabled", value: "disabled" });

  return options;
};

function EditUserForm({ initialValues }) {
  const [operationInProgress, setOperationInProgress] = useState(false);

  const dispatch = useDispatch();

  const [updateAccount] = useUpdateAccountMutation();
  const [updateUser] = useUpdateUserMutation();

  const { data: products } = useGetProductsQuery();

  const {
    register,
    handleSubmit,
    errors: formErrors,
    control,
    reset,
    formState: { isDirty, isSubmitting, dirtyFields },
  } = useForm({
    defaultValues: {
      ...initialValues,
      is_active: getOptionByValue({ options: isActiveOptions, value: initialValues?.account?.is_active }),
      rank_tracker_only_weekly_analysis: getOptionByValue({
        options: isActiveOptions,
        value: initialValues?.account?.rank_tracker_only_weekly_analysis,
      }),
      is_stale: getOptionByValue({ options: isActiveOptions, value: initialValues?.account?.is_stale }),
      fta: getOptionByValue({ options: isActiveOptions, value: initialValues?.account?.fta }),
      is_appsumo_account: getOptionByValue({
        options: isActiveOptions,
        value: initialValues?.account?.is_appsumo_account,
      }),
      plan_id: getOptionByValue({ options: planTypesOptions, value: initialValues?.account?.plan_id }),
      subscriptions: {
        "rank-tracker-keywords": getOptionByValue({
          options: getOptionsForProduct(products, "rank-tracker-keywords"),
          value: initialValues?.account?.subscriptions["rank-tracker-keywords"],
        }),
        "onsite-webpages": getOptionByValue({
          options: getOptionsForProduct(products, "onsite-webpages"),
          value: initialValues?.account?.subscriptions["onsite-webpages"],
        }),
        competitors: getOptionByValue({
          options: getOptionsForProduct(products, "competitors"),
          value: initialValues?.account?.subscriptions.competitors,
        }),
        backlinks: getOptionByValue({
          options: getOptionsForProduct(products, "backlinks"),
          value: initialValues?.account?.subscriptions.backlinks,
        }),
        "organic-paid-traffic": getOptionByValue({
          options: getOptionsForProduct(products, "organic-paid-traffic"),
          value: initialValues?.account?.subscriptions["organic-paid-traffic"],
        }),
        "keyword-research": getOptionByValue({
          options: getOptionsForProduct(products, "keyword-research"),
          value: initialValues?.account?.subscriptions["keyword-research"],
        }),
        "content-explorer": getOptionByValue({
          options: getOptionsForProduct(products, "content-explorer"),
          value: initialValues?.account?.subscriptions["content-explorer"],
        }),
        integrations: getOptionByValue({
          options: getOptionsForProduct(products, "integrations"),
          value: initialValues?.account?.subscriptions.integrations,
        }),
        reports: getOptionByValue({
          options: getOptionsForProduct(products, "reports"),
          value: initialValues?.account?.subscriptions.reports,
        }),
        "team-members": getOptionByValue({
          options: getOptionsForProduct(products, "team-members"),
          value: initialValues?.account?.subscriptions["team-members"],
        }),
        "ai-tokens": getOptionByValue({
          options: getOptionsForProduct(products, "ai-tokens"),
          value: initialValues?.account?.subscriptions["ai-tokens"],
        }),
        "seodity-credits": getOptionByValue({
          options: getOptionsForProduct(products, "seodity-credits"),
          value: initialValues?.account?.subscriptions["seodity-credits"],
        }),
        "api-credits": getOptionByValue({
          options: getOptionsForProduct(products, "api-credits"),
          value: initialValues?.account?.subscriptions["api-credits"],
        }),
      },
      payment_status: getOptionByValue({
        options: paymentStatusesOptions,
        value: initialValues?.account?.payment_status,
      }),
      plan_status: getOptionByValue({
        options: planStatusesOptions,
        value: initialValues?.account?.plan_status,
      }),
      active_until:
        initialValues?.account?.active_until &&
        format(new Date(initialValues?.account?.active_until), dateFormats.YYYY_MM_DD),
    },
  });

  const disableSubmit = !isDirty || isSubmitting;

  const onSubmit = async (values) => {
    const changedValues = getChangedFields({ values, dirtyFields });
    const data = convertFormValuesToRequestInput({ values: changedValues });

    try {
      setOperationInProgress(true);

      const id = initialValues?.uid;

      const { email, ...accountData } = data;
      const userData = { ...(email ? { email } : {}) };
      if (Object.keys(userData).length) {
        await updateUser({ uid: id, data: userData }).unwrap();
      }
      if (Object.keys(accountData).length) {
        await updateAccount({ uid: id, data: accountData }).unwrap();
      }

      toast.success("User updated successfully", {
        toastId: toastIds.SUCCESS_UPDATE_USER,
      });
      dispatch(getUser({ id: initialValues?.uid }));
      reset(values);
    } catch (error) {
      toast.error("An error occurred while updating user data. Please try again shortly.", {
        toastId: toastIds.ERROR_UPDATE_USER,
      });
    } finally {
      setOperationInProgress(false);
    }
  };

  const fieldGroups = [
    {
      label: "User",
      fields: [
        {
          label: "Email",
          name: "email",
          component: TextInput,
          validation: {
            required: "E-mail is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid e-mail address",
            },
          },
        },
        { emptySpace: true },
        { emptySpace: true },
      ],
    },
    {
      label: "Account",
      fields: [
        {
          label: "Is active",
          name: "is_active",
          component: SelectInput,
          validation: {
            required: "Is active value is required",
          },
          options: booleanOptions,
          control,
        },
        {
          label: "Is stale",
          name: "is_stale",
          component: SelectInput,
          validation: {
            required: "Is stale value is required",
          },
          options: booleanOptions,
          control,
        },
        {
          label: "FTA",
          name: "fta",
          component: SelectInput,
          validation: {
            required: "Is disabled value is required",
          },
          options: booleanOptions,
          control,
        },
        {
          label: "Rank Tracker Only Weekly",
          name: "rank_tracker_only_weekly_analysis",
          component: SelectInput,
          validation: {
            required: "Is rank_tracker_only_weekly_analysis value is required",
          },
          options: booleanOptions,
          control,
        },
      ],
    },
    {
      fields: [
        {
          label: "Plan",
          name: "plan_id",
          component: SelectInput,
          validation: {
            required: "Plan is required",
          },
          options: planTypesOptions,
          control,
        },
        {
          label: "Is LTD account",
          name: "is_appsumo_account",
          component: SelectInput,
          validation: {
            required: "Is LTD account value is required",
          },
          options: booleanOptions,
          control,
        },
        {
          label: "Plan status",
          name: "plan_status",
          component: SelectInput,
          options: planStatusesOptions,
          control,
        },
        {
          label: "Active until",
          name: "active_until",
          type: "date",
          component: TextInput,
          validation: {
            required: "Active until date is required",
          },
        },
        {
          label: "Payment status",
          name: "payment_status",
          component: SelectInput,
          options: paymentStatusesOptions,
          control,
        },
      ],
    },

    {
      noBorder: true,
      label: "Limits",
      fields: [
        {
          label: "Projects",
          type: "number",
          name: "resources_limits.projects",
          component: TextInput,
          validation: {
            required: "Projects limit is required",
          },
        },
        {
          label: "Keywords",
          type: "number",
          name: "resources_limits.keywords",
          component: TextInput,
          validation: {
            required: "Keywords limit is required",
          },
        },
        {
          label: "Webpages",
          type: "number",
          name: "resources_limits.webpages",
          component: TextInput,
          validation: {
            required: "Webpages limit is required",
          },
        },
        {
          label: "Competitors",
          type: "number",
          name: "resources_limits.competitors",
          component: TextInput,
          validation: {
            required: "Competitors limit is required",
          },
        },
        {
          label: "Backlinks",
          type: "number",
          name: "resources_limits.backlinks",
          component: TextInput,
          validation: {
            required: "Backlinks limit is required",
          },
        },
      ],
    },
    {
      fields: [
        {
          label: "Reports",
          type: "number",
          name: "resources_limits.reports",
          component: TextInput,
          validation: {
            required: "Reports limit is required",
          },
        },
        {
          label: "Team members",
          type: "number",
          name: "resources_limits.team_members",
          component: TextInput,
          validation: {
            required: "Team members limit is required",
          },
        },
        {
          label: "AI Tokens",
          type: "number",
          name: "resources_limits.ai_tokens",
          component: TextInput,
          validation: {
            required: "AI tokens limit is required",
          },
        },
        {
          label: "Seodity Credits",
          type: "number",
          name: "resources_limits.seodity_credits",
          component: TextInput,
          validation: {
            required: "Seodity Credits limit is required",
          },
        },
        {
          label: "API Credits",
          type: "number",
          name: "resources_limits.api_credits",
          component: TextInput,
          validation: {
            required: "API credits limit is required",
          },
        },
      ],
    },
    {
      noBorder: true,
      label: "Usage",
      fields: [
        {
          label: "Projects",
          type: "number",
          name: "resources_used.projects",
          component: TextInput,
          validation: {
            required: "Projects usage is required",
          },
        },
        {
          label: "Keywords",
          type: "number",
          name: "resources_used.keywords",
          component: TextInput,
          validation: {
            required: "Keywords usage is required",
          },
        },
        {
          label: "Webpages",
          type: "number",
          name: "resources_used.webpages",
          component: TextInput,
          validation: {
            required: "Webpages usage is required",
          },
        },
        {
          label: "Competitors",
          type: "number",
          name: "resources_used.competitors",
          component: TextInput,
          validation: {
            required: "Competitors usage is required",
          },
        },

        {
          label: "Backlinks",
          type: "number",
          name: "resources_used.backlinks",
          component: TextInput,
          validation: {
            required: "Backlinks usage is required",
          },
        },
      ],
    },
    {
      fields: [
        {
          label: "Reports",
          type: "number",
          name: "resources_used.reports",
          component: TextInput,
          validation: {
            required: "Reports usage is required",
          },
        },
        {
          label: "Team Members",
          type: "number",
          name: "resources_used.team_members",
          component: TextInput,
          validation: {
            required: "Team Members usage is required",
          },
        },
        {
          label: "AI Tokens",
          type: "number",
          name: "resources_used.ai_tokens",
          component: TextInput,
          validation: {
            required: "AI tokens usage is required",
          },
        },
        {
          label: "Seodity Credits",
          type: "number",
          name: "resources_used.seodity_credits",
          component: TextInput,
          validation: {
            required: "Seodity Credits usage is required",
          },
        },
        {
          label: "API Credits",
          type: "number",
          name: "resources_used.api_credits",
          component: TextInput,
          validation: {
            required: "API Credits usage is required",
          },
        },
      ],
    },

    {
      label: "Features Access",
      noBorder: true,
      fields: [
        {
          label: "Rank Tracker Keywords",
          name: "subscriptions.rank-tracker-keywords",
          component: SelectInput,
          options: getOptionsForProduct(products, "rank-tracker-keywords"),
          control,
        },
        {
          label: "Onsite Webpages",
          name: "subscriptions.onsite-webpages",
          component: SelectInput,
          options: getOptionsForProduct(products, "onsite-webpages"),
          control,
        },
        {
          label: "Competitors",
          name: "subscriptions.competitors",
          component: SelectInput,
          options: getOptionsForProduct(products, "competitors"),
          control,
        },
        {
          label: "Backlinks",
          name: "subscriptions.backlinks",
          component: SelectInput,
          options: getOptionsForProduct(products, "backlinks"),
          control,
        },
      ],
    },
    {
      noBorder: true,
      fields: [
        {
          label: "Organic & Paid Traffic",
          name: "subscriptions.organic-paid-traffic",
          component: SelectInput,
          options: getOptionsForProduct(products, "organic-paid-traffic"),
          control,
        },
        {
          label: "Keyword Research",
          name: "subscriptions.keyword-research",
          component: SelectInput,
          options: getOptionsForProduct(products, "keyword-research"),
          control,
        },
        {
          label: "Content Explorer",
          name: "subscriptions.content-explorer",
          component: SelectInput,
          options: getOptionsForProduct(products, "content-explorer"),
          control,
        },
        {
          label: "Integrations",
          name: "subscriptions.integrations",
          component: SelectInput,
          options: getOptionsForProduct(products, "integrations"),
          control,
        },
      ],
    },
    {
      fields: [
        {
          label: "Reports",
          name: "subscriptions.reports",
          component: SelectInput,
          options: getOptionsForProduct(products, "reports"),
          control,
        },
        {
          label: "Team Members",
          name: "subscriptions.team-members",
          component: SelectInput,
          options: getOptionsForProduct(products, "team-members"),
          control,
        },
        {
          label: "AI Tokens",
          name: "subscriptions.ai-tokens",
          component: SelectInput,
          options: getOptionsForProduct(products, "ai-tokens"),
          control,
        },
        {
          label: "Seodity Credits",
          name: "subscriptions.seodity-credits",
          component: SelectInput,
          options: getOptionsForProduct(products, "seodity-credits"),
          control,
        },
      ],
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fieldGroups?.map(({ label, fields, noBorder }, fgIdx) => (
        <FormGroup key={label || fgIdx} title={label} noBorder={noBorder}>
          {fields.map(({ component: Component, validation, inputProps, emptySpace, ...fieldProps }, fIdx) =>
            emptySpace ? (
              <Box key={fIdx} />
            ) : (
              <Component
                key={fieldProps?.name}
                ref={fieldProps?.control ? null : register(validation)}
                error={formErrors?.[fieldProps?.name]?.message}
                width="100%"
                {...inputProps}
                {...fieldProps}
              />
            ),
          )}
        </FormGroup>
      ))}
      <Box flex justifyRight margin="20px 0 0">
        <ButtonGroup>
          <Button
            disabled={disableSubmit}
            type="submit"
            loading={operationInProgress}
            variant={Button.Variants.SECONDARY}
          >
            Save changes
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  );
}

export default EditUserForm;
