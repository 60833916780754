import React from "react";

const Icon = ({ className, color = "#9EADCF", width = "15px", height = "13px" }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 22 19"
    enableBackground="new 0 0 22 19"
  >
    <g>
      <path
        fill={color}
        d="M19,0H2.7C1.2,0,0,1.2,0,2.7V12c0,1.5,1.2,2.7,2.7,2.7H19c1.5,0,2.7-1.2,2.7-2.7V2.7C21.7,1.2,20.5,0,19,0z
     M2.7,1.4H19c0.7,0,1.3,0.6,1.3,1.3v6.8H1.4V2.7C1.4,2,2,1.4,2.7,1.4z M19,13.2H2.7c-0.7,0-1.3-0.6-1.3-1.3v-1h18.9v1
    C20.3,12.7,19.7,13.2,19,13.2z"
      />
      <rect fill={color} x="7.2" y="16.8" className="st0" width="7.4" height="1.4" />
    </g>
  </svg>
);

export default Icon;
