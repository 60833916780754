import { useState } from "react";

const useModal = ({ initialModalId = null } = {}) => {
  const [openedModalId, setOpenedModalId] = useState(initialModalId);

  return {
    isOpen: openedModalId,
    setOpen: setOpenedModalId,
  };
};

export default useModal;
