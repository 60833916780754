import React, { Component } from "react";

import { SectionTitle } from "components/utils/Typography";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <SectionTitle>An error occurred.</SectionTitle>;
    }

    return children;
  }
}

export default ErrorHandler;
