import styled from "styled-components/macro";

function ButtonGroup({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  > button:not(:last-child) {
    margin-right: 10px;
  }
`;

export default ButtonGroup;
