import { DEFAULT_PAGINATION_LIMIT } from "utils/constants";

export const getPaginationOffset = ({ page = 1, limit = DEFAULT_PAGINATION_LIMIT }) => (page - 1) * limit || null;

export const getPaginationParams = ({ params }) =>
  params
    ? {
        sortDir: params?.sortDir,
        sortField: params?.sortField,
        offset: getPaginationOffset({ page: params?.page, limit: params?.limit }) || 0,
        limit: params?.limit || DEFAULT_PAGINATION_LIMIT,
      }
    : {};

export const getPageCount = ({ itemsCount, limit }) => Math.ceil(itemsCount / limit) || 1;

export const getMetaParams = ({ meta, queryParams }) => ({
  ...meta,
  page: queryParams?.page,
  limit: queryParams?.limit,
  sortDir: queryParams?.sortDir,
  sortField: queryParams?.sortField,
});
