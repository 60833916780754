import { Quote } from "components/icons";
import styled from "styled-components/macro";
import { theme } from "utils/theme";
import { ImageComponent } from "components/editor-tools";
import { Box } from "components/utils";

const QuoteComponent = ({ header, text, alt, url, setData, rerender }) => {
  const handlePaste = (e) => {
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <Box flex justifyBetween>
        <ImageWrapper>
          <ImageComponent url={url} alt={alt} setData={setData} onlyAlt rerender={rerender} />
        </ImageWrapper>
        <Editable
          data-placeholder="Header"
          dangerouslySetInnerHTML={{ __html: header }}
          contentEditable
          className="header"
          onPaste={handlePaste}
        />
        <div>
          <Quote width={91} height={74} />
        </div>
      </Box>
      <Editable
        data-placeholder="Enter a quote"
        dangerouslySetInnerHTML={{ __html: text }}
        contentEditable
        as="p"
        className="text"
        onPaste={handlePaste}
      />
    </Wrapper>
  );
};

const Wrapper = styled.blockquote`
  margin-top: 30px;
  padding: 40px;
  background-color: ${theme.colors.BLOG_QUOTE};
  border-left: 2px solid ${theme.colors.BLOG_QUOTE_BORDER};
  line-height: 2.1;
`;

const ImageWrapper = styled.div`
  margin-right: 20px;
  max-width: 120px;

  img {
    max-width: 60px;
    border-radius: 50%;
  }
`;

const Editable = styled.div`
  outline: none;
  min-width: 70px;

  &.header {
    margin-right: 10px;
  }

  &.text {
    margin-top: 30px;
  }

  &:empty {
    &::before {
      display: block;
      position: absolute;
      color: #707684;
      content: attr(data-placeholder);
      font-weight: normal;
    }
  }
`;

export default QuoteComponent;
