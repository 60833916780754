import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useCreateBlogImageMutation } from "api/services/blog";
import { Spinner, CloseButton } from "components/shared";
import { theme } from "utils/theme";
import { useDropzone } from "react-dropzone";

const ImageComponent = ({ url, caption, alt, link, setData, onlyAlt, rerender }) => {
  const [preview, setUrl] = useState(url);
  const [isLoading, setLoading] = useState(false);
  const [createBlogImage] = useCreateBlogImageMutation();
  const [file, setFile] = useState(null);

  const ref = useRef(null);
  const mountedComponent = useRef(true);

  useEffect(() => {
    if (file) {
      if (mountedComponent.current) {
        setLoading(true);
      }
      const reader = new FileReader();

      reader.onload = async () => {
        const result = await createBlogImage({ body: reader.result, type: file.type });
        setData("url", result?.data?.url);
        if (mountedComponent.current) {
          setUrl(result?.data?.url);
          setLoading(false);
        } else if (rerender) {
          rerender();
        }
      };
      reader.readAsArrayBuffer(file);
    }
    return () => {
      mountedComponent.current = false;
    };
  }, [file]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const text = clipboardData.getData("text/html");
    setData("caption", text);

    if (ref?.current) {
      ref.current.innerHTML = text;

      const range = document.createRange();
      range.selectNodeContents(ref.current);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg,image/png,image/gif",
    noDragEventsBubbling: true,
  });

  return (
    <>
      <ImageWrapper>
        {isLoading && <Spinner color={theme.colors.PRIMARY} />}
        {preview && (
          <>
            <Image src={preview} />
            {onlyAlt && (
              <CloseButton
                onClick={() => {
                  setUrl("");
                  setData("url", "");
                }}
                width={10}
                height={10}
                top={-10}
                right={25}
              />
            )}
          </>
        )}
      </ImageWrapper>
      {preview ? (
        <>
          {!onlyAlt && (
            <Input
              data-placeholder="Caption"
              dangerouslySetInnerHTML={{ __html: caption }}
              contentEditable
              as="div"
              className="caption"
              onPaste={handlePaste}
              ref={ref}
            />
          )}
          <Input defaultValue={alt} placeholder="Alt text" onChange={(e) => setData("alt", e.target.value)} />
          {!onlyAlt && (
            <Input defaultValue={link} placeholder="Image link" onChange={(e) => setData("link", e.target.value)} />
          )}
        </>
      ) : (
        !isLoading && (
          <ImageButton {...getRootProps()}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.15 13.628A7.749 7.749 0 0 0 10 17.75a7.74 7.74 0 0 0 6.305-3.242l-2.387-2.127-2.765 2.244-4.389-4.496-3.614 3.5zm-.787-2.303l4.446-4.371 4.52 4.63 2.534-2.057 3.533 2.797c.23-.734.354-1.514.354-2.324a7.75 7.75 0 1 0-15.387 1.325zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
            </svg>
            Select an Image
            <FileInput {...getInputProps()} />
          </ImageButton>
        )
      )}
    </>
  );
};

const Image = styled.img`
  max-width: 100%;
  display: block;
`;

const ImageWrapper = styled.div`
  margin-bottom: 10px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  &.withBorder {
    border: 1px solid #e8e8eb;
  }

  &.withBackground {
    padding: 15px;
    background: #cdd1e0;

    ${Image} {
      max-width: 60%;
      margin: 0 auto;
    }
  }

  &.stretched > ${Image} {
    width: 100%;
  }
`;

const ImageButton = styled.div`
  padding: 13px;
  border-radius: 3px;
  border: 1px solid rgba(201, 201, 204, 0.48);
  font-size: 15px;
  background: ${theme.colors.WHITE};
  box-shadow: 0 2px 2px 0 rgb(18 30 57 / 4%);
  color: #707684;
  text-align: center;
  position: relative;

  &:hover {
    background: #fbfcfe;
    box-shadow: 0 1px 3px 0 rgb(18 30 57 / 8%);
  }

  svg {
    margin-right: 6px;
  }
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 3px;
  outline: none;
  font-size: 18px;
  margin-bottom: 5px;
  position: relative;

  &.caption:empty {
    &::before {
      display: block;
      position: absolute;
      color: #707684;
      content: attr(data-placeholder);
      font-weight: normal;
    }
  }
`;

export default ImageComponent;
