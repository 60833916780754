import { isMatch } from "date-fns";

import { dateFormats } from "utils/constants";

export const getChangedFields = ({ values, dirtyFields }) =>
  Object.entries(values)?.reduce(
    (acc, [key, value]) =>
      Object.keys(dirtyFields).includes(key)
        ? {
            ...acc,
            [key]: value,
          }
        : {
            ...acc,
          },
    {},
  );

export const convertFormValuesToRequestInput = ({ values = {} }) =>
  Object.entries(values)?.reduce((vals, [key, value]) => {
    const isJson = typeof value === "object" && key.includes("resources_");

    if (isJson) {
      const parsedResourcesJson = Object.entries(value)?.reduce(
        (vs, [k, v]) => ({
          ...vs,
          [k]: parseInt(v, 10),
        }),
        {},
      );

      return {
        ...vals,
        [key]: parsedResourcesJson,
      };
    }

    if (key.includes("subscriptions")) {
      const obj = {};
      Object.keys(value).forEach((k) => {
        if (value[k]) {
          obj[k] = value[k].value;
        }
      });

      return { ...vals, subscriptions: obj };
    }

    const isSelectInput = typeof value === "object";

    if (isSelectInput) {
      const v = value?.value === "no" ? false : value?.value === "yes" ? true : value?.value;

      return {
        ...vals,
        [key]: v,
      };
    }

    const isDate = isMatch(typeof value === "string" ? value?.replace("T", " ") : value, dateFormats.YYYY_MM_DD);

    if (isDate) {
      return {
        ...vals,
        [key]: new Date(value)?.toISOString(),
      };
    }

    const isIntegerNumber = parseInt(value, 10);

    if (!Number.isNaN(isIntegerNumber)) {
      return {
        ...vals,
        [key]: parseInt(value, 10),
      };
    }

    return {
      ...vals,
      [key]: value,
    };
  }, {});

export const getOptionByValue = ({ options = [], value }) => options?.find((op) => op?.value === value);

export const shouldDisableFormSubmission = ({ nullableFields = [], formValues }) =>
  Object.entries(formValues)?.some(([key, value]) => !nullableFields.includes(key) && !value);
