import { useState } from "react";
import styled, { css } from "styled-components/macro";
import { useDebouncedCallback } from "use-debounce";

import { theme } from "utils/theme";

import { Close, Filter, Search } from "components/icons";

function SearchInput({
  onChange = () => {},
  type = "text",
  label,
  placeholder,
  width,
  value,
  focusOnMount,
  onButtonClick,
  defaultValue,
  debounceDelay = 500,
  ...props
}) {
  const debounced = useDebouncedCallback((v) => {
    onChange(v);
  }, debounceDelay);

  const [isFocused, setFocused] = useState(false);

  const handleFocusGain = () => {
    if (!isFocused) {
      setFocused(true);
    }
  };

  const handleFocusLoss = () => {
    if (isFocused) {
      setFocused(false);
    }
  };

  const handleInputClear = () => {
    onChange("");
  };

  const handleValueChange = (e) => {
    debounced(e.target.value);
  };

  // useEffect(() => {
  //   console.log("mount");
  //   return () => {
  //     console.log("unmount");
  //   };
  // }, []);

  return (
    <OuterWrapper isFocused={isFocused} width={width}>
      <InnerWrapper isFocused={isFocused} width={width} showButton={onButtonClick}>
        <SearchIconWrapper>
          <Search color={isFocused ? theme.colors.INPUT_ACTIVE_BORDER : "unset"} height="12px" width="11px" />
        </SearchIconWrapper>
        <Input
          {...props}
          ref={(i) => {
            if (focusOnMount && defaultValue) {
              return i && i.focus();
            }

            return i;
          }}
          type={type}
          placeholder={placeholder}
          onFocus={handleFocusGain}
          onBlur={handleFocusLoss}
          defaultValue={defaultValue}
          value={value}
          onChange={handleValueChange}
        />
        {defaultValue && (
          <ClearIconWrapper onClick={handleInputClear}>
            <Close color={isFocused ? theme.colors.INPUT_ACTIVE_BORDER : "unset"} height="12px" width="11px" />
          </ClearIconWrapper>
        )}
      </InnerWrapper>
      {onButtonClick && (
        <ButtonWrapper onClick={onButtonClick}>
          <Filter width="18px" height="16px" />
        </ButtonWrapper>
      )}
    </OuterWrapper>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  min-height: 42px;
  cursor: pointer;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  border-left: 1px solid ${theme.colors.SEARCH_INPUT_BUTTON_SEPARATOR};
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &:hover {
    svg * {
      fill: ${theme.colors.BUTTON_OPTION_HOVER};
    }
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: ${({ width }) => width || "100%"};
  height: 42px;
  line-height: 1.5;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid ${({ isFocused }) => (isFocused ? theme.colors.INPUT_ACTIVE_BORDER : "transparent")};
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;

  ${({ showButton }) =>
    !showButton &&
    css`
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
    `};
`;

const OuterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => (width === "100%" ? "100%" : "auto")};
  max-width: ${({ width }) => width};
  background-color: ${theme.colors.INPUT_BACKGROUND};
  border-radius: 9px;
`;

const Input = styled.input`
  margin: 0;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 9px 14px 9px 22px;
  box-shadow: none;
  font-weight: 600;
  line-height: 1.83;
  border-radius: 9px;
  border: none;
  background-color: ${theme.colors.INPUT_BACKGROUND};
  font-size: ${theme.sizes.INPUT_TEXT};
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.INPUT_TEXT};

  &:focus,
  &:active {
    outline: none;
  }
`;

const SearchIconWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearIconWrapper = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
`;

export default SearchInput;
