import ReactJson from "react-json-view";

import { Message } from "components/utils/Typography";

function JsonDisplay({ json }) {
  if (!json || Object.keys(json)?.length === 0) {
    return <Message>The response is empty or invalid.</Message>;
  }

  return <ReactJson src={json} collapsed={1} displayDataTypes={false} sortKeys />;
}

export default JsonDisplay;
