import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: [
    "Projects",
    "Project",
    "ProjectReportLogs",
    "ProjectLighthouseReports",
    "ProjectSERPReports",
    "ProjectSEOReports",
  ],
});

const projectApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query({
      query: (slug) => `/v1/projects/${slug}`,
      providesTags: (result, error, slug) => [{ type: "Project", id: slug }],
    }),
    getProjects: build.query({
      query: () => `/v1/projects?limit=100`,
      providesTags: ["Projects"],
    }),
    updateProject: build.mutation({
      query(data) {
        return {
          url: `/v1/projects/${data.uid}`,
          method: "PATCH",
          body: data.data,
        };
      },
      invalidatesTags: (result) => [
        { type: "Project", id: result.slug },
        { type: "User", id: result.user_uid },
      ],
    }),
    deleteProject: build.mutation({
      query(uid) {
        return {
          url: `/v1/projects/${uid}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Projects", "User"],
    }),
    getProjectReportLogs: build.query({
      query(data) {
        return {
          url: `/v1/projects/${data.slug}/report-logs`,
          method: "GET",
          params: {
            limit: data.limit,
            offset: data.offset,
          },
        };
      },
      providesTags: (result, error, data) => [{ type: "ProjectReportLogs", id: data.slug }],
      transformResponse: (response) => response.reportLogs,
    }),
    getProjectLighthouseReports: build.query({
      query(data) {
        return {
          url: `/v1/projects/${data.slug}/lighthouse-reports`,
          method: "GET",
          params: {
            limit: data.limit,
            offset: data.offset,
          },
        };
      },
      providesTags: (result, error, data) => [{ type: "ProjectLighthouseReports", id: data.slug }],
      transformResponse: (response) => response.lighthouseReports,
    }),
    getProjectSERPReports: build.query({
      query(data) {
        return {
          url: `/v1/projects/${data.slug}/serp-reports`,
          method: "GET",
          params: {
            domain: data.domain,
            limit: data.limit,
            offset: data.offset,
          },
        };
      },
      providesTags: (result, error, data) => [{ type: "ProjectSERPReports", id: data.slug }],
      transformResponse: (response) => response.serpReports,
    }),
    getProjectSEOReports: build.query({
      query(data) {
        return {
          url: `/v1/projects/${data.slug}/seo-reports`,
          method: "GET",
          params: {
            limit: data.limit,
            offset: data.offset,
          },
        };
      },
      providesTags: (result, error, data) => [{ type: "ProjectSEOReports", id: data.slug }],
      transformResponse: (response) => response.seoReports,
    }),
    onDemandSEOAnalysis: build.mutation({
      query(data) {
        return {
          url: `/v1/projects/${data.uid}/on-demand-seo-analysis`,
          method: "PUT",
        };
      },
      invalidatesTags: () => [{ type: "ProjectSEOReports" }],
    }),
    onDemandSERPAnalysis: build.mutation({
      query(data) {
        return {
          url: `/v1/projects/${data.uid}/on-demand-serp-analysis`,
          method: "PUT",
        };
      },
      invalidatesTags: () => [{ type: "ProjectSERPReports" }],
    }),
  }),
});

export const {
  useGetProjectQuery,
  useGetProjectsQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectReportLogsQuery,
  useGetProjectLighthouseReportsQuery,
  useGetProjectSERPReportsQuery,
  useGetProjectSEOReportsQuery,
  useOnDemandSEOAnalysisMutation,
  useOnDemandSERPAnalysisMutation,
} = projectApi;
