import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["BlogPosts", "BlogPost", "BlogCategories"],
});

const blogPostApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getBlogPosts: build.query({
      query: ({ limit, offset, sortDir, sortField }) => ({
        url: "/v1/blog-posts",
        method: "GET",
        params: {
          limit,
          offset,
          sortDir,
          sortField,
        },
      }),
      providesTags: ["BlogPosts"],
    }),
    getBlogPost: build.query({
      query: (uid) => `/v1/blog-posts/${uid}`,
      providesTags: (uid) => [{ type: "BlogPost", id: uid }],
    }),
    createBlogPost: build.mutation({
      query(data) {
        return {
          url: `/v1/blog-posts`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (uid) => [{ type: "BlogPost", id: uid }, { type: "BlogPosts" }, { type: "BlogCategories" }],
    }),
    updateBlogPost: build.mutation({
      query({ uid, data }) {
        return {
          url: `/v1/blog-posts/${uid}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: (uid) => [{ type: "BlogPost", id: uid }, { type: "BlogPosts" }],
    }),
    deleteBlogPost: build.mutation({
      query(uid) {
        return { url: `/v1/blog-posts/${uid}`, method: "DELETE" };
      },
      invalidatesTags: (uid) => [{ type: "BlogPost", id: uid }, { type: "BlogPosts" }],
    }),
    getCategories: build.query({
      query: () => "/v1/blog-post/categories",
      providesTags: ["BlogCategories"],
    }),
  }),
});

export const {
  useGetBlogPostsQuery,
  useGetBlogPostQuery,
  useUpdateBlogPostMutation,
  useDeleteBlogPostMutation,
  useCreateBlogPostMutation,
  useGetCategoriesQuery,
} = blogPostApi;
