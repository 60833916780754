import { useRef, useState } from "react";
import styled from "styled-components/macro";

import { UI_ID_DATE_PICKER_TIMEFRAME_INPUT } from "utils/constants";
import { useLegacyState, useOnClickOutside } from "utils/hooks";
import { theme } from "utils/theme";

import { OptionButton } from "components/forms";
import { Box } from "components/utils";
import { Arrow, Menu } from "components/icons";

function MenuDropdown({ options = [] }) {
  const [state, setState] = useLegacyState({
    isOpen: false,
  });

  const [focusedItem, setFocusedItem] = useState(null);

  const anchor = useRef();

  useOnClickOutside({
    ref: anchor,
    action: (targetId) => {
      if (targetId === UI_ID_DATE_PICKER_TIMEFRAME_INPUT) {
        return;
      }

      setState({ isOpen: false });
    },
  });

  const handlePickerOpen = () => {
    setState({
      isOpen: !state.isOpen,
    });
  };

  const { isOpen } = state;

  return (
    <Styler>
      <Box flex alignCenter>
        <Box mr="10px">
          <OptionButton
            width="20px"
            height="20px"
            onClick={handlePickerOpen}
            color="transparent"
            icon={<Menu width="3px" height="12px" />}
          />
        </Box>
      </Box>
      <MenuWrapper ref={anchor} isOpen={isOpen}>
        <MenuContent>
          <ArrowUp />
          {options?.map((option) => {
            const isItemFocused = focusedItem === option?.value;

            return (
              <MenuOption
                isFocused={isItemFocused}
                onMouseEnter={() => {
                  setFocusedItem(option?.value);
                }}
                key={option?.value}
                onClick={() => {
                  if (option?.closeOnClick) {
                    handlePickerOpen();
                  }
                  if (option?.onClick) {
                    option?.onClick(option);
                  }
                }}
              >
                <OptionWrapper>
                  {isItemFocused && (
                    <FocusedItemWrapper>
                      <Arrow width="8px" height="5px" color={theme.colors.WHITE} />
                    </FocusedItemWrapper>
                  )}
                  <div>{option?.label}</div>
                </OptionWrapper>
              </MenuOption>
            );
          })}
        </MenuContent>
      </MenuWrapper>
    </Styler>
  );
}

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FocusedItemWrapper = styled.div`
  width: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  pointer-events: none;
`;

const ArrowUp = styled.div`
  position: absolute;
  top: -5px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${theme.colors.SELECT_INPUT_MENU_ARROW_BACKGROUND};
`;

const MenuContent = styled.div`
  height: auto;
  max-height: 256px;
  min-width: 100px;
  overflow-y: auto;
  padding: 10px 0;
  border-radius: 9px;
  background-color: ${theme.colors.TOOLTIP_BACKGROUND};
`;

const MenuOption = styled.div`
  width: 100%;
  min-width: 140px;
  padding: 8px 20px;
  margin-left: 0;
  cursor: pointer;
  font-family: ${theme.fonts.RUBIK};
  font-size: 12px;
  font-weight: ${theme.fontWeights.MEDIUM};
  color: ${theme.colors.WHITE};
  background-color: ${({ isFocused }) =>
    isFocused ? theme.colors.SELECT_INPUT_MENU_ITEM_HOVER_BACKGROUND : theme.colors.TOOLTIP_BACKGROUND};
  text-align: left;
`;

const Styler = styled.div`
  position: relative;
  width: 100%;
`;

const MenuWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 30px;
  right: 4px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

export default MenuDropdown;
