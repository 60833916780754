import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { columnTypes, modalKeys, tabKeys, viewKeys } from "utils/constants";
import { getRouteByKey, routes } from "utils/routes";
import { filterConfig } from "utils/filters";
import { useModal, usePageLoading, useQueryParams } from "utils/hooks";
import { getPaginationParams, getMetaParams } from "utils/pagination";
import { theme } from "utils/theme";
import { parseRoute } from "utils/formatters";
import toast, { toastIds } from "utils/toast";

import {
  AdvancedFiltersDisplay,
  AdvancedFiltersForm,
  DateTimeValue,
  Modal,
  SummaryValue,
  Table,
  TopPanel,
  PlanIndicator,
  UserIndicatorInTable,
  MenuDropdown,
  ConfirmationModal,
  ModalWithForm,
} from "components/shared";
import { Box } from "components/utils";
import { OptionButton, SearchInput } from "components/forms";
import { AppTemplate } from "components/templates";
import { Add, Disable, Plus, Remove } from "components/icons";

import { actionTypes, getUsers, usersSelector, removeUser, createUser, updateUser } from "Users/redux/usersSlice";
import { pendingActionsSelector } from "App/redux/appSlice";
import { CreateUserForm } from "Users/components";

function UsersPage() {
  const { initialLoading } = usePageLoading();

  const route = getRouteByKey({ viewKey: viewKeys.USERS });

  const dispatch = useDispatch();
  const history = useHistory();

  const { params, setQuery } = useQueryParams();

  const { ids: userIds, entities: usersEntities, meta: usersMeta } = useSelector(usersSelector);
  const loadingUsers = useSelector((state) => pendingActionsSelector({ state, actions: actionTypes.GET_USERS }));

  useEffect(() => {
    dispatch(
      getUsers({
        params: {
          ...getPaginationParams({ params }),
          search: params?.search,
        },
      }),
    );
  }, [dispatch, params]);

  const OptionsComponent = () => (
    <Box width="100%" flex alignCenter justifyBetween>
      <div>
        <AdvancedFiltersDisplay filterConfig={filterConfig} viewKey={viewKeys.USERS} />
      </div>
    </Box>
  );

  const { isOpen: openedAdvancedFiltersId, setOpen: setOpenedAdvancedFiltersId } = useModal();
  const { isOpen: openedCreateUserId, setOpen: setOpenedCreateUserId } = useModal();

  const TopPanelComponent = useCallback(
    () => (
      <TopPanel
        title={route?.label}
        leftSection={
          <Box flex>
            <Box mr="20px">
              <SearchInput
                onChange={(search) => {
                  setQuery({ search });
                }}
                focusOnMount
                defaultValue={params?.search}
                width="370px"
                onButtonClick={() => {
                  setOpenedAdvancedFiltersId(modalKeys.UI_ID_ADVANCED_FILTERS_MODAL);
                }}
              />
            </Box>
            <Box>
              <OptionButton
                hoverBackgroundColor={theme.colors.BUTTON_OPTION_HOVER}
                hoverIconColor={theme.colors.WHITE}
                icon={<Plus width="10px" height="10px" color={theme.colors.WHITE} />}
                onClick={() => setOpenedCreateUserId(modalKeys.UI_ID_CREATE_USER_MODAL)}
              />
            </Box>
          </Box>
        }
        rightSection={
          <Box>
            <SummaryValue value={usersMeta?.all} caption="Users count" />
          </Box>
        }
      />
    ),
    [usersMeta, params],
  );

  const headers = useMemo(
    () => [
      { label: "E-mail", value: "email", type: columnTypes.ALPHA, sortable: true },
      { label: "Role", value: "role", type: columnTypes.ALPHA },
      { label: "Plan", value: "plan_id", type: columnTypes.ALPHA, sortable: true },
      { label: "Plan status", value: "plan_status", type: columnTypes.ALPHA, sortable: true },
      { label: "Is AppSumo Account", value: "is_appsumo_account", type: columnTypes.ALPHA, sortable: true },
      { label: "Active until", value: "active_until", type: columnTypes.ALPHA, sortable: true },
      { label: "Last active", value: "last_active", type: columnTypes.ALPHA, sortable: true },
      { label: "Created at", value: "created_at", type: columnTypes.ALPHA, sortable: true },
    ],
    [],
  );

  const [userToRemoveId, setUserToRemoveId] = useState(null);
  const [userToActivateId, setUserToActivateId] = useState(null);

  const userToActivate = usersEntities?.[userToActivateId];

  const activationProps = userToActivate?.is_active
    ? {
        data: { is_active: false },
        successMessage: "User deactivated successfully.",
        errorMessage: "An error occurred while deactivating user. Please try again.",
        confirmationModalTitle: "Deactivate",
        confirmationModalDescription: "Are you sure you want to deactivate selected user?",
      }
    : {
        data: { is_active: true },
        successMessage: "User activated successfully.",
        errorMessage: "An error occurred while activating user. Please try again.",
        confirmationModalTitle: "Activate",
        confirmationModalDescription: "Are you sure you want to activate selected user?",
      };

  const getRoleName = (role) => {
    switch (role) {
      case 0:
        return "Seodity Admin";
      case 1:
        return "Account Owner";
      case 2:
        return "Team Member";
      case 3:
        return "Client";
      default:
        return "Unknown";
    }
  };

  const rows = useMemo(
    () =>
      userIds?.map((id) => {
        const user = usersEntities?.[id];

        const activationButtonLabel = user?.account?.is_active ? "Deactivate" : "Activate";

        return {
          options: {
            key: id,
            onRowClick: () => {
              history.push(
                parseRoute({
                  route: routes.USER_DETAILS,
                  params: {
                    id,
                  },
                }),
              );
            },
          },
          columns: {
            email: <UserIndicatorInTable user={user} />,
            role: getRoleName(user?.role),
            plan_id: <PlanIndicator planId={user?.account?.plan_id} />,
            plan_status: user?.account?.plan_status,
            is_appsumo_account: user?.account?.is_appsumo_account ? "Yes" : "No",
            active_until: <DateTimeValue date={user?.account?.active_until} />,
            last_active: <DateTimeValue date={user?.last_active} />,
            created_at: <DateTimeValue date={user?.created_at} />,
            actions: (
              <>
                <MenuDropdown
                  options={[
                    {
                      label: "Edit",
                      value: "edit",
                      onClick: () => {
                        history.push(
                          parseRoute({
                            route: routes.USER_DETAILS,
                            params: {
                              id,
                            },
                            queryParams: {
                              tab: tabKeys.USER_DETAILS_SETTINGS,
                            },
                          }),
                        );
                      },
                    },
                    {
                      label: activationButtonLabel,
                      value: "activate",
                      onClick: () => {
                        setUserToActivateId(id);
                      },
                    },
                    {
                      label: "Remove",
                      value: "remove",
                      onClick: () => {
                        setUserToRemoveId(id);
                      },
                    },
                  ]}
                />
              </>
            ),
          },
        };
      }),
    [userIds],
  );

  const handleUserActivate = async () => {
    if (userToActivateId) {
      try {
        const rs = await dispatch(
          updateUser({
            id: userToActivateId,
            data: activationProps.data,
          }),
        );

        const hasError = rs?.error;

        if (!hasError) {
          toast.success(activationProps.successMessage, {
            toastId: toastIds.SUCCESS_UPDATE_USER,
          });

          dispatch(
            getUsers({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error(activationProps.errorMessage, {
            toastId: toastIds.ERROR_UPDATE_USER,
          });
        }
      } catch (error) {
        toast.error(activationProps.errorMessage, {
          toastId: toastIds.ERROR_UPDATE_USER,
        });
      }
    }

    setUserToActivateId(null);
  };

  const handleUserRemove = async () => {
    if (userToRemoveId) {
      try {
        const rs = await dispatch(removeUser({ id: userToRemoveId }));

        const hasError = rs?.error;

        if (!hasError) {
          toast.success("User removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_USER,
          });

          dispatch(
            getUsers({
              params: {
                ...getPaginationParams({ params }),
              },
            }),
          );
        } else {
          toast.error("An error occurred while removing user. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_USER,
          });
        }
      } catch (error) {
        toast.error("An error occurred while removing user. Please try again.", {
          toastId: toastIds.ERROR_REMOVE_USER,
        });
      }
    }

    setUserToRemoveId(null);
  };

  const handleUserCreate = async (values) => {
    try {
      const rs = await dispatch(
        createUser({
          data: {
            email: values?.email,
          },
        }),
      );

      const hasError = rs?.error;

      if (!hasError) {
        toast.success("User created successfully.", {
          toastId: toastIds.SUCCESS_CREATE_USER,
        });

        setOpenedCreateUserId(null);

        dispatch(
          getUsers({
            params: {
              ...getPaginationParams({ params }),
            },
          }),
        );
      } else {
        toast.error("An error occurred while creating user. Please try again.", {
          toastId: toastIds.ERROR_CREATE_USER,
        });
      }
    } catch (error) {
      toast.error("An error occurred while creating user. Please try again.", {
        toastId: toastIds.ERROR_CREATE_USER,
      });
    }
  };

  return (
    <>
      <AppTemplate route={route} OptionsComponent={OptionsComponent} TopPanelComponent={TopPanelComponent}>
        <Table
          isLoading={!userIds}
          isRefetchLoading={loadingUsers || initialLoading}
          headers={headers}
          rows={rows}
          meta={getMetaParams({ meta: usersMeta, queryParams: params })}
          emptyStateText="No users available"
        />
      </AppTemplate>
      <Modal
        title="Sorting and filtering"
        id={modalKeys.UI_ID_ADVANCED_FILTERS_MODAL}
        isOpen={openedAdvancedFiltersId === modalKeys.UI_ID_ADVANCED_FILTERS_MODAL}
        onRequestClose={() => {
          setOpenedAdvancedFiltersId(null);
        }}
      >
        <AdvancedFiltersForm
          filterConfig={filterConfig}
          viewKey={viewKeys.USERS}
          onConfirm={() => {
            setOpenedAdvancedFiltersId(null);
          }}
          onCancel={() => {
            setOpenedAdvancedFiltersId(null);
          }}
        />
      </Modal>
      <ModalWithForm
        icon={<Add width="32px" height="32px" />}
        width="400px"
        title="Add new user"
        id={modalKeys.UI_ID_CREATE_USER_MODAL}
        isOpen={openedCreateUserId === modalKeys.UI_ID_CREATE_USER_MODAL}
        onRequestClose={() => {
          setOpenedCreateUserId(null);
        }}
      >
        <CreateUserForm
          onConfirm={handleUserCreate}
          onCancel={() => {
            setOpenedCreateUserId(null);
          }}
        />
      </ModalWithForm>
      <ConfirmationModal
        width="300px"
        icon={<Disable width="32px" height="32px" />}
        title={activationProps?.confirmationModalTitle}
        description={activationProps?.confirmationModalDescription}
        id={modalKeys.UI_ID_ACTIVATE_USER_MODAL}
        isOpen={userToActivateId}
        onConfirm={handleUserActivate}
        onCancel={() => {
          setUserToActivateId(null);
        }}
      />
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove selected user?"
        id={modalKeys.UI_ID_REMOVE_USER_MODAL}
        isOpen={userToRemoveId}
        onConfirm={handleUserRemove}
        onCancel={() => {
          setUserToRemoveId(null);
        }}
      />
    </>
  );
}

export default UsersPage;
