import styled from "styled-components/macro";

import { Close } from "components/icons";
import { theme } from "utils/theme";

function CloseButton({ onClick, color, width = 10, height = 10, top = 0, right = 0, ...props }) {
  return (
    <Button {...{ width, height, top, right, color }} onClick={onClick}>
      <CloseButtonIcon width={width} height={height} {...props} />
    </Button>
  );
}

const Button = styled.div`
  position: absolute;
  width: ${({ size }) => size && `${size}px`};
  height: ${({ size }) => size && `${size}px`};
  top: ${({ top }) => top && `${top}px`};
  bottom: ${({ bottom }) => bottom && `${bottom}px`};
  left: ${({ left }) => left && `${left}px`};
  right: ${({ right }) => right && `${right}px`};

  svg * {
    fill: ${({ color }) => color};
  }

  &:hover {
    svg * {
      fill: ${theme.colors.BUTTON_OPTION_HOVER};
    }
  }

  &:focus {
    outline: none;
  }
`;

const CloseButtonIcon = styled(Close)`
  z-index: 1000;
  position: ${({ position }) => position};
  cursor: pointer;
  box-sizing: content-box;
  padding: 14px;
`;

export default CloseButton;
