import { useForm } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router";

import { operationTypes, modalKeys, postStatuses } from "utils/constants";
import { useGetBlogAuthorsQuery } from "api/services/blogAuthor";
import {
  useGetCategoriesQuery,
  useCreateBlogPostMutation,
  useUpdateBlogPostMutation,
  useDeleteBlogPostMutation,
} from "api/services/blogPost";
import toast, { toastIds } from "utils/toast";
import { routes } from "utils/routes";

import { Button, ButtonGroup, TextInput, ImageInput, Editor, SelectInput } from "components/forms";
import { Box } from "components/utils";
import { Remove } from "components/icons";
import { Spinner, ConfirmationModal } from "components/shared";
import { theme } from "utils/theme";
import { parseRoute, parseJSON } from "utils/formatters";

const PostForm = ({ post, isPostLoading }) => {
  const history = useHistory();
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [postToRemoveId, setPostToRemoveId] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  // image is loading
  const [isLoading, setLoading] = useState(false);
  const [isDone, setDone] = useState(false);

  useEffect(() => {
    setCurrentImageUrl(post?.image_url);
  }, [post]);

  useEffect(() => {
    if (isDone) {
      history.push(
        parseRoute({
          route: routes.BLOG,
        }),
      );
    }
  }, [isDone]);

  const { data: categories, isLoading: isCategoriesLoading } = useGetCategoriesQuery();
  const { data: authors, isLoading: isAuthorsLoading } = useGetBlogAuthorsQuery({ limit: 50, offset: 0 });

  const authorsData = [];
  authors?.blog_authors?.forEach((author) => authorsData.push({ label: author?.name, value: author?.uid }));
  const categoriesData = [];
  categories?.categories?.forEach((category) => categoriesData.push({ label: category, value: category }));

  const [addPost] = useCreateBlogPostMutation();
  const [updatePost] = useUpdateBlogPostMutation();
  const [removePost] = useDeleteBlogPostMutation();

  const editorRef = useRef(null);

  const handleOperation = async ({ values, type }) => {
    setOperationInProgress(true);
    const content = JSON.stringify(await editorRef?.current?.save());

    if (type === operationTypes.CANCEL) {
      if (post) {
        try {
          await removePost(post?.uid);

          toast.success("Post removed successfully.", {
            toastId: toastIds.SUCCESS_REMOVE_POST,
          });
        } catch (error) {
          toast.error("An error occurred while removing post. Please try again.", {
            toastId: toastIds.ERROR_REMOVE_POST,
          });
        }
      }
    } else if (post) {
      const postToUpdate = {
        title: values?.title,
        slug: values?.slug,
        description: values?.description,
        content,
        image_url: currentImageUrl,
        category: values?.category?.value,
        author_uid: values?.author_uid?.value,
        status: type === operationTypes.CONFIRM ? postStatuses.PUBLISHED : postStatuses.DRAFT,
      };

      try {
        await updatePost({ uid: post?.uid, data: postToUpdate }).unwrap();

        toast.success("Post updated successfully.", {
          toastId: toastIds.SUCCESS_UPDATE_POST,
        });
      } catch (error) {
        toast.error("An error occurred while updating post. Please try again.", {
          toastId: toastIds.ERROR_UPDATE_POST,
        });
      }
    } else {
      const postToAdd = {
        title: values?.title,
        slug: values?.slug,
        description: values?.description,
        content,
        image_url: currentImageUrl,
        category: values?.category ? values?.category?.value : "",
        author_uid: values?.author_uid?.value,
        status: type === operationTypes.CONFIRM ? postStatuses.PUBLISHED : postStatuses.DRAFT,
      };

      try {
        await addPost(postToAdd).unwrap();

        toast.success("Post created successfully.", {
          toastId: toastIds.SUCCESS_CREATE_POST,
        });
      } catch (error) {
        toast.error("An error occurred while creating post. Please try again.", {
          toastId: toastIds.ERROR_CREATE_POST,
        });
      }
    }

    setOperationInProgress(false);
    if (!(type === operationTypes.CONFIRM && post)) {
      setDone(true);
    }
  };

  const { register, handleSubmit, errors: formErrors, setError, clearErrors, control } = useForm();

  const fields = [
    {
      label: "Title",
      type: "text",
      name: "title",
      defaultValue: post?.title,
      component: TextInput,
      validation: {
        required: "Title is required",
      },
      autoFocus: true,
      margin: "0",
    },
    {
      label: "Short description",
      type: "textarea",
      name: "description",
      height: "80px",
      defaultValue: post?.description,
      component: TextInput,
      validation: {
        required: "Description is required",
      },
    },
    {
      label: "Slug",
      type: "text",
      name: "slug",
      placeholder: "post-slug",
      defaultValue: post?.slug,
      component: TextInput,
      validation: {
        required: "Slug is required",
        pattern: { value: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/i, message: "Invalid slug" },
      },
    },
    {
      label: "Category",
      type: "select",
      name: "category",
      placeholder: "Select category or enter new one",
      value: post?.category || null,
      options: categoriesData,
      component: SelectInput,
      control,
      creatable: true,
    },
    {
      label: "Author",
      type: "select",
      name: "author_uid",
      placeholder: "Select author",
      value: post?.author_uid || null,
      options: authorsData,
      component: SelectInput,
      control,
    },
    {
      label: "Drag and drop title photo",
      name: "image",
      preview: currentImageUrl,
      component: ImageInput,
      setUrl: setCurrentImageUrl,
      setError,
      clearErrors,
      isLoading,
      setLoading,
      height: "220px",
    },
  ];

  return (
    <form onSubmit={handleSubmit((values) => handleOperation({ values, type: operationTypes.CONFIRM }))}>
      <ConfirmationModal
        icon={<Remove width="32px" height="32px" />}
        width="300px"
        title="Remove"
        description="Are you sure you want to remove this post?"
        id={modalKeys.UI_ID_REMOVE_AUTHOR_MODAL}
        isOpen={postToRemoveId}
        onConfirm={() => handleOperation({ type: operationTypes.CANCEL })}
        onCancel={() => {
          setPostToRemoveId(null);
        }}
      />
      <Box flex justifyRight margin="-40px 0 10px">
        <ButtonGroup>
          <Button
            type="button"
            disabled={isLoading}
            loading={operationInProgress}
            variant={Button.Variants.DANGER}
            onClick={() => {
              if (post) {
                setPostToRemoveId(post?.uid);
              } else {
                handleOperation({ type: operationTypes.CANCEL });
              }
            }}
          >
            {post ? "Remove" : "Cancel"}
          </Button>
          <Button
            type="submit"
            disabled={isLoading || isCategoriesLoading || isAuthorsLoading}
            loading={operationInProgress}
            variant={Button.Variants.SECONDARY}
            onClick={handleSubmit((values) => handleOperation({ values, type: operationTypes.DRAFT }))}
          >
            Save as draft
          </Button>
          <Button
            type="submit"
            disabled={isLoading || isCategoriesLoading || isAuthorsLoading}
            loading={operationInProgress}
            variant={Button.Variants.ACTION}
          >
            {post ? "Publish changes" : "Post"}
          </Button>
        </ButtonGroup>
      </Box>
      <Box flex position="relative">
        {isPostLoading || isCategoriesLoading || isAuthorsLoading ? (
          <Disabler>
            <Spinner color={theme.colors.PRIMARY} />
          </Disabler>
        ) : (
          <>
            <FieldsWrapper>
              {fields.map(({ component: Component, validation, inputProps, ...fieldProps }) => (
                <Component
                  key={fieldProps?.name}
                  ref={register(validation)}
                  error={formErrors?.[fieldProps?.name]?.message}
                  {...inputProps}
                  {...fieldProps}
                />
              ))}
            </FieldsWrapper>

            <EditorWrapper>
              <Editor blocks={parseJSON(post?.content)} ref={editorRef} height={currentImageUrl ? "800px" : "715px"} />
            </EditorWrapper>
          </>
        )}
      </Box>
    </form>
  );
};

const EditorWrapper = styled.div`
  flex-basis: 810px;
`;

const FieldsWrapper = styled.div`
  margin-right: 20px;
  flex: 1;
  max-width: 32%;
`;

const Disabler = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${theme.colors.LOADING_OVERLAY};
  width: 100%;
  height: 60vh;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PostForm;
