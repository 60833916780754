import { AppTemplate } from "components/templates";
import { Message } from "components/utils/Typography";

function NoMatchPage() {
  return (
    <AppTemplate>
      <Message>Page not found</Message>
    </AppTemplate>
  );
}

export default NoMatchPage;
