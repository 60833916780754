import { set } from "date-fns";

export const getStartDate = ({ date }) =>
  set(typeof date === "string" ? new Date(date) : date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

export const getEndDate = ({ date }) =>
  set(typeof date === "string" ? new Date(date) : date, {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  });
