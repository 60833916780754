import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M12.74 20H7.26A7.26 7.26 0 010 12.74V7.26A7.26 7.26 0 017.26 0h5.48A7.26 7.26 0 0120 7.26v5.48A7.26 7.26 0 0112.74 20z"
        fill="#fcd2df"
      />
      <path
        fill="#dc4b68"
        d="M13.359 7.702l-1.06-1.06L10 8.938 7.702 6.641l-1.06 1.06L8.938 10l-2.298 2.298 1.06 1.06L10 11.062l2.298 2.298 1.06-1.06L11.062 10z"
      />
    </svg>
  );
}

export default SvgComponent;
