import styled from "styled-components/macro";

import { Unavailable } from "components/icons";

function UserIndicatorInTable({ user }) {
  return (
    <Wrapper>
      {user?.email}
      {user?.is_active === false ? (
        <UserIconWrapper title="User inactive">
          <Unavailable />
        </UserIconWrapper>
      ) : null}
    </Wrapper>
  );
}

const UserIconWrapper = styled.div`
  margin-left: 10px;

  svg {
    min-width: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default UserIndicatorInTable;
