import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="disable_icon__Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 26.152 25.761"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".disable_icon__st1{fill:#8c99b6}"}</style>
      <path
        d="M18.152 25.76H8c-4.4 0-8-3.6-8-8V8c0-4.4 3.6-8 8-8h10.152c4.4 0 8 3.6 8 8v9.76c0 4.4-3.6 8-8 8z"
        opacity={0.15}
        fill="#8c99b6"
      />
      <path
        className="disable_icon__st1"
        d="M16.366 9.936c-2.568 0-4.657 2.089-4.657 4.656s2.09 4.656 4.657 4.656 4.655-2.088 4.655-4.656-2.088-4.656-4.655-4.656zm-3.456 4.656c0-.875.338-1.667.876-2.276l4.273 5.27a3.414 3.414 0 01-1.693.462 3.46 3.46 0 01-3.456-3.456zm6.074 2.23l-4.258-5.253a3.412 3.412 0 011.64-.432 3.46 3.46 0 013.456 3.455c0 .854-.323 1.626-.838 2.23zM10.098 11.074c1.488 0 2.699-1.265 2.699-2.82s-1.211-2.818-2.699-2.818c-1.488 0-2.7 1.265-2.7 2.819s1.212 2.819 2.7 2.819zm0-4.238c.716 0 1.298.637 1.298 1.419s-.582 1.418-1.298 1.418c-.716 0-1.3-.636-1.3-1.418s.584-1.419 1.3-1.419zM11.794 18.295a.7.7 0 00-.606.351 1.563 1.563 0 01-.423.053H9.007c-.175 0-.312-.023-.401-.046l-.954-3.225a.7.7 0 00-.672-.502h-.12c-.13 0-.24-.01-.328-.024V12.64c.088-.015.198-.025.329-.025h1.317c.411.275 1.043.433 1.771.433a.7.7 0 100-1.4c-.57 0-.926-.138-.987-.187a.7.7 0 00-.533-.246H6.86c-1.067 0-1.73.402-1.73 1.048v3.016c0 .558.496.934 1.32 1.026l.837 2.826c.089.584.757.968 1.719.968h1.758c1.034 0 1.73-.443 1.73-1.103a.7.7 0 00-.7-.7z"
      />
    </svg>
  );
}

export default SvgComponent;
