import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M12.74 20H7.26A7.26 7.26 0 010 12.74V7.26A7.26 7.26 0 017.26 0h5.48A7.26 7.26 0 0120 7.26v5.48A7.26 7.26 0 0112.74 20z"
        opacity={0.9}
        fill="#e0ecf6"
      />
      <path
        d="M8.603 13.622c-.215 0-.429-.17-.6-.298l-1.71-2.226c-.171-.385-.171-.813.168-1.069.344-.259.815-.259 1.115.128l1.112 1.412 3.765-4.62c.3-.257.771-.344 1.112-.044.3.301.344.727.087 1.07L9.2 13.325c-.17.214-.341.297-.598.297z"
        fill="#249fd3"
      />
    </svg>
  );
}

export default SvgComponent;
