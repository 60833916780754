import { Fragment } from "react";
import styled from "styled-components/macro";

import { theme } from "utils/theme";

import { Breadcrumb } from "components/utils/Typography";
import { ArrowBreadcrumbs } from "components/icons";
import { Link } from "react-router-dom";

function Breadcrumbs({ route }) {
  const { breadcrumbs } = route;

  if (breadcrumbs?.length === 1) {
    const [breadcrumb] = breadcrumbs;

    return (
      <Wrapper>
        <Breadcrumb isLast>{breadcrumb?.label}</Breadcrumb>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {breadcrumbs?.map(({ label, path }, idx) => (
        <Fragment key={label}>
          <BreadcrumbWrapper>
            <Breadcrumb isLink={path} key={label} isLast={idx === breadcrumbs.length - 1}>
              {path ? <Link to={path}>{label}</Link> : label}
            </Breadcrumb>
          </BreadcrumbWrapper>
          {idx !== breadcrumbs.length - 1 && (
            <ArrowWrapper>
              <ArrowBreadcrumbs width="8px" height="5px" title="Breadcrumb arrow" />
            </ArrowWrapper>
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.WHITE};
  color: ${theme.colors.BLACK};
  min-height: ${theme.sizes.BREADCRUMBS_HEIGHT};
  margin-top: 8px;
  margin-bottom: 16px;
`;

const BreadcrumbWrapper = styled.div`
  margin-right: 10px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 2px;
  margin-right: 10px;
`;

export default Breadcrumbs;
