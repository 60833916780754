import styled from "styled-components/macro";

import { theme } from "utils/theme";

function Container({ children, ...props }) {
  return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
  margin: ${({ margin }) => margin || "0 auto"};
  background-color: ${theme.colors.WHITE};
  max-width: ${theme.sizes.CONTAINER_WIDTH};
  padding: 0 ${theme.sizes.CONTENT_PADDING};
  width: 100%;
`;

export default Container;
