import { mainApi } from "api/api";

const apiWithTag = mainApi.enhanceEndpoints({
  addTagTypes: ["BlogImages"],
});

const blogPostApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    createBlogImage: build.mutation({
      query({ body, type }) {
        return {
          url: `/v1/blog/images`,
          method: "POST",
          headers: {
            "content-type": type,
          },
          body,
        };
      },
      invalidatesTags: () => [{ type: "BlogImages" }],
    }),
  }),
});

export const { useCreateBlogImageMutation } = blogPostApi;
export const { createBlogImage } = blogPostApi.endpoints;
