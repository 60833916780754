import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";

import { getAuthToken, setAuthToken, unsetAuthToken } from "utils/auth";
import { httpMethods } from "utils/constants";
import { request } from "utils/api";

const SLICE_KEY = "auth";

const INITIAL_STATE = {
  admin: null,
  token: getAuthToken(),
};

const actionTypes = {
  GET_ADMIN: "auth/getAdmin",
  LOGIN: "auth/login",
  LOGOUT: "auth/logout",
};

const endpoints = {
  GET_ADMIN: ({ uid }) => `/v1/users/${uid}`,
  LOGIN: "/v1/auth/get-token",
};

export const authSelector = createSelector(
  ({ auth }) => auth,
  ({ admin, token }) => ({
    admin,
    token,
  }),
);

export const getAdmin = createAsyncThunk(actionTypes.GET_ADMIN, async ({ uid }, thunkApi) =>
  request({
    actionType: actionTypes.GET_ADMIN,
    method: httpMethods.GET,
    endpoint: endpoints.GET_ADMIN({ uid }),
    thunkApi,
  }),
);

export const login = createAsyncThunk(actionTypes.LOGIN, async (credentials, thunkApi) =>
  request({
    actionType: actionTypes.LOGIN,
    method: httpMethods.POST,
    endpoint: endpoints.LOGIN,
    data: credentials,
    thunkApi,
  }),
);

export const logout = createAsyncThunk(actionTypes.LOGOUT, async () => {
  await unsetAuthToken();
});

const authSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { token, expire } = payload;

      state.token = token;
      setAuthToken({
        token,
        expire,
      });
    });
    builder.addCase(getAdmin.fulfilled, (state, { payload }) => {
      state.admin = payload;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.admin = null;
      state.token = null;
    });
  },
});

const { reducer } = authSlice;

export default reducer;
