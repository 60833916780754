import * as React from "react";

function SvgComponent({ color = "#4a4e59", ...props }) {
  return (
    <svg
      id="sort_alpha_desc_icon_Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 11.355 12.833"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.sort_alpha_desc_icon_st1{fill:${color}}`}</style>
      <path
        d="M3.374 11.839h-1.98l-.351.994H0l1.82-4.886h1.127l1.813 4.886H3.717l-.343-.994zm-.266-.75L2.38 9.005l-.728 2.086h1.456z"
        fill={color}
      />
      <path
        className="sort_alpha_desc_icon_st1"
        d="M8.759 12.364c.107.118.236.169.42.18a.55.55 0 00.41-.199l1.64-1.978a.55.55 0 10-.847-.7l-.654.788.019-5.032a.565.565 0 00-.55-.552.55.55 0 00-.55.548l-.02 5.16-.801-.885a.551.551 0 00-.816.738l1.749 1.932zM4.004 4.997H.286V4.31L2.68.875H.351V0h3.588v.684L1.546 4.122h2.458v.875z"
      />
    </svg>
  );
}

export default SvgComponent;
