import { useState } from "react";

const useLegacyState = (initialValue) => {
  const [state, setState] = useState(initialValue);

  const setStateFn = (updatedObj) => {
    if (typeof updatedObj === "function") {
      setState(updatedObj);
    } else {
      setState((oldState) => ({ ...oldState, ...updatedObj }));
    }
  };

  return [state, setStateFn];
};

export default useLegacyState;
