import { tabKeys, viewKeys } from "utils/constants";

import { PrivateRoute, PublicRoute } from "components/routes";
import { Placeholder } from "components/shared";
import { Blog, Dashboard, Logs, Projects, Users, Invoices, Calendar, Draft } from "components/icons";

import { LoginPage } from "Auth/pages";
import { DashboardPage } from "Dashboard/pages";
import { ProjectDetailsPage, ProjectsPage } from "Projects/pages";
import { UsersPage, UserDetailsPage } from "Users/pages";
import { LogsPage } from "Logs/pages";
import { BlogPage, BlogCreatePage, BlogEditPage } from "Blog/pages";
import { LighthouseReportsPage } from "Lighthouse/pages";
import ReportLogsPage from "ReportLogs/pages/ReportLogsPage";
import { SerpReportsPage } from "Serp/pages";
import { SeoReportsPage } from "Seo/pages";
import { PromptEditPage, PromptPage } from "../Prompt/pages";

export const routes = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  PROJECTS: "/projects",
  USER_DETAILS: "/users/:id",
  PROJECT_DETAILS: "/projects/:id",
  USERS: "/users",
  SETTINGS: "/settings",
  LOGS: "/logs",
  ADMINS: "/admins",
  LIGHTHOUSE_REPORTS: "/lighthouse-reports",
  REPORT_LOGS: "/report-logs",
  SERP_REPORTS: "/serp-reports",
  SEO_REPORTS: "/seo-reports",
  BLOG: "/blog",
  BLOG_CREATE: "/blog/create",
  BLOG_EDIT: "/blog/edit/:id",
  PROMPTS: "/prompts",
  PROMPT_EDIT: "/prompts/edit/:id",
};

const router = [
  {
    component: PublicRoute,
    path: routes.LOGIN,
    page: LoginPage,
    label: "Log in",
    viewKey: viewKeys.LOGIN,
  },
  {
    component: PrivateRoute,
    path: routes.DASHBOARD,
    page: DashboardPage,
    label: "Dashboard",
    viewKey: viewKeys.DASHBOARD,
    breadcrumbs: [
      {
        label: "Dashboard",
        path: routes.DASHBOARD,
      },
    ],
    navIcon: {
      component: Dashboard,
      props: {
        width: "16px",
        height: "16px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.PROJECTS,
    page: ProjectsPage,
    label: "Projects",
    viewKey: viewKeys.PROJECTS,
    breadcrumbs: [
      {
        label: "Projects",
        path: routes.PROJECTS,
      },
    ],
    navIcon: {
      component: Projects,
      props: {
        width: "22px",
        height: "18px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.PROJECT_DETAILS,
    page: ProjectDetailsPage,
    label: "Project details",
    viewKey: viewKeys.PROJECT_DETAILS,
    breadcrumbs: [
      {
        label: "Projects",
        path: routes.PROJECTS,
      },
      { label: "Details" },
    ],
    tabs: [
      { key: tabKeys.PROJECT_DETAILS_INFO, label: "Info" },
      { key: tabKeys.PROJECT_DETAILS_RESOURCES, label: "Resources" },
      { key: tabKeys.PROJECT_DETAILS_KEYWORDS, label: "Keywords" },
      { key: tabKeys.PROJECT_DETAILS_COMPETITION, label: "Competition" },
      { key: tabKeys.PROJECT_DETAILS_REPORT_LOGS, label: "Report Logs" },
      { key: tabKeys.PROJECT_DETAILS_SETTINGS, label: "Settings" },
    ],
  },
  {
    component: PrivateRoute,
    path: routes.USERS,
    page: UsersPage,
    label: "Users",
    viewKey: viewKeys.USERS,
    breadcrumbs: [
      {
        label: "Users",
        path: routes.USERS,
      },
    ],
    navIcon: {
      component: Users,
      props: {
        width: "11px",
        height: "17px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.USER_DETAILS,
    page: UserDetailsPage,
    label: "User details",
    viewKey: viewKeys.USER_DETAILS,
    breadcrumbs: [
      {
        label: "Users",
        path: routes.USERS,
      },
      { label: "Details" },
    ],
    tabs: [
      { key: tabKeys.USER_DETAILS_PROJECTS, label: "Projects" },
      { key: tabKeys.USER_DETAILS_RESOURCES, label: "Resources" },
      { key: tabKeys.USER_DETAILS_ACTIVITY, label: "Activity" },
      { key: tabKeys.USER_DETAILS_INVOICES, label: "Invoices" },
      { key: tabKeys.USER_DETAILS_SETTINGS, label: "Settings" },
    ],
  },
  {
    component: PrivateRoute,
    path: routes.LOGS,
    page: LogsPage,
    label: "Logs",
    viewKey: viewKeys.LOGS,
    breadcrumbs: [
      {
        label: "Logs",
        path: routes.LOGS,
      },
    ],
    navIcon: {
      component: Logs,
      props: {
        width: "21px",
        height: "19px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.SEO_REPORTS,
    page: SeoReportsPage,
    label: "SEO reports",
    viewKey: viewKeys.SEO_REPORTS,
    breadcrumbs: [
      {
        label: "SEO reports",
        path: routes.SEO_REPORTS,
      },
    ],
    navIcon: {
      component: Placeholder,
      props: {
        width: "20px",
        height: "20px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.SERP_REPORTS,
    page: SerpReportsPage,
    label: "SERP reports",
    viewKey: viewKeys.SERP_REPORTS,
    breadcrumbs: [
      {
        label: "SERP reports",
        path: routes.SERP_REPORTS,
      },
    ],
    navIcon: {
      component: Calendar,
      props: {
        width: "20px",
        height: "20px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.LIGHTHOUSE_REPORTS,
    page: LighthouseReportsPage,
    label: "Lighthouse reports",
    viewKey: viewKeys.LIGHTHOUSE_REPORTS,
    breadcrumbs: [
      {
        label: "Lighthouse reports",
        path: routes.LIGHTHOUSE_REPORTS,
      },
    ],
    navIcon: {
      component: Placeholder,
      props: {
        width: "20px",
        height: "20px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.REPORT_LOGS,
    page: ReportLogsPage,
    label: "Report logs",
    viewKey: viewKeys.REPORT_LOGS,
    breadcrumbs: [
      {
        label: "Report logs",
        path: routes.REPORT_LOGS,
      },
    ],
    navIcon: {
      component: Invoices,
      props: {
        width: "20px",
        height: "20px",
      },
    },
  },
  {
    component: PrivateRoute,
    path: routes.BLOG,
    page: BlogPage,
    label: "Blog",
    viewKey: viewKeys.BLOG,
    breadcrumbs: [
      {
        label: "Blog",
        path: routes.BLOG,
      },
    ],
    navIcon: {
      component: Blog,
      props: {
        width: "17px",
        height: "18px",
      },
    },
    tabs: [
      { key: tabKeys.BLOG_POSTS, label: "Posts" },
      { key: tabKeys.BLOG_AUTHORS, label: "Authors" },
    ],
  },
  {
    component: PrivateRoute,
    path: routes.BLOG_CREATE,
    page: BlogCreatePage,
    label: "New post",
    viewKey: viewKeys.BLOG_CREATE,
    breadcrumbs: [
      {
        label: "Blog",
        path: routes.BLOG,
      },
      { label: "New post" },
    ],
  },
  {
    component: PrivateRoute,
    path: routes.BLOG_EDIT,
    page: BlogEditPage,
    label: "Edit post",
    viewKey: viewKeys.BLOG_EDIT,
    breadcrumbs: [
      {
        label: "Blog",
        path: routes.BLOG,
      },
      { label: "Edit post" },
    ],
  },

  {
    component: PrivateRoute,
    path: routes.PROMPTS,
    page: PromptPage,
    label: "Prompts",
    viewKey: viewKeys.PROMPTS,
    breadcrumbs: [
      {
        label: "Prompts",
        path: routes.PROMPT_EDIT,
      },
    ],
    navIcon: {
      component: Draft,
      props: {
        width: "17px",
        height: "18px",
      },
    },
    tabs: [
      { key: tabKeys.PROMPTS, label: "Prompts" },
    ],
  },

  {
    component: PrivateRoute,
    path: routes.PROMPT_EDIT,
    page: PromptEditPage,
    label: "Edit prompts",
    viewKey: viewKeys.PROMPT_EDIT,
    breadcrumbs: [
      {
        label: "Prompts",
        path: routes.PROMPTS,
      },
      { label: "Edit prompt" },
    ],
  },
];

export const getRouteByKey = ({ viewKey }) => router.find((route) => route.viewKey === viewKey);

export const getNavbarRoutes = () => {
  const availableRoutes = [
    viewKeys.DASHBOARD,
    viewKeys.PROJECTS,
    viewKeys.USERS,
    viewKeys.LOGS,
    viewKeys.SEO_REPORTS,
    viewKeys.SERP_REPORTS,
    viewKeys.LIGHTHOUSE_REPORTS,
    viewKeys.REPORT_LOGS,
    viewKeys.BLOG,
    viewKeys.PROMPTS,
  ];

  return availableRoutes.map((route) => getRouteByKey({ viewKey: route }));
};

export default router;
