import styled from "styled-components/macro";

import { theme } from "utils/theme";

function Placeholder({ title, color, ...props }) {
  return <Wrapper color={color} title={title} {...props} />;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ color }) => color || `linear-gradient(45deg, red, blue)`};
  color: ${theme.colors.WHITE};

  ${({ children, ...props }) => ({ ...props })};
`;

export default Placeholder;
