import { useHistory } from "react-router";
import styled from "styled-components/macro";

import { parseRoute } from "utils/formatters";
import { routes } from "utils/routes";
import { theme } from "utils/theme";

function UserEmailIndicator({ user }) {
  const history = useHistory();

  return (
    <>
      <Label>E-mail:</Label>
      <Value
        onClick={() => {
          history.push(
            parseRoute({
              route: routes.USER_DETAILS,
              params: {
                id: user?.uid,
              },
            }),
          );
        }}
      >
        {user?.email}
      </Value>
    </>
  );
}

const Label = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.DOMAIN_NAME_SMALL};
  font-weight: ${theme.fontWeights.MEDIUM};
  line-height: 2.67;
  text-align: left;
  color: ${theme.colors.TEXT_DARK};
`;

const Value = styled.div`
  font-family: ${theme.fonts.RUBIK};
  font-size: ${theme.sizes.DOMAIN_NAME};
  line-height: 1.78;
  text-align: left;
  color: ${theme.colors.TEXT_DARK};
  text-decoration: underline;
  cursor: pointer;
`;

export default UserEmailIndicator;
