import { useTabs, useQueryParams } from "utils/hooks";
import { tabKeys, viewKeys, DEFAULT_PAGINATION_LIMIT } from "utils/constants";
import { getRouteByKey } from "utils/routes";
import styled from "styled-components/macro";
import { theme } from "utils/theme";

import { AppTemplate } from "components/templates";
import { SummaryValue, TopPanel } from "components/shared";
import { Box } from "components/utils";

import { BlogPostsTab, BlogAuthorsTab } from "Blog/tabs";
import { useGetBlogPostsQuery } from "api/services/blogPost";
import { useGetBlogAuthorsQuery } from "api/services/blogAuthor";

const BlogPage = () => {
  const route = getRouteByKey({ viewKey: viewKeys.BLOG });
  const { tabs } = route;
  const { params } = useQueryParams();

  const { selectedTab, goToTab } = useTabs({ tabs });

  const offset = params?.page ? (+params?.page - 1) * (params?.limit ? +params?.limit : DEFAULT_PAGINATION_LIMIT) : 0;
  const data = {
    limit: params?.limit ? +params?.limit : DEFAULT_PAGINATION_LIMIT,
    offset,
    sortDir: params?.sortDir,
    sortField: params?.sortField,
  };

  const { data: posts, isLoading: isPostsLoading, isFetching: isPostsFetching } = useGetBlogPostsQuery(data);

  const { data: authors, isLoading: isAuthorsLoading, isFetching: isAuthorsFetching } = useGetBlogAuthorsQuery(data);

  const TopPanelComponent = () => (
    <TopPanel
      title={route?.label}
      leftSection
      rightSection={
        <Box>
          <Grid>
            <GridItem>
              <SummaryValue value={posts?.meta?.all} caption="Posts published" />
            </GridItem>
            <GridItem>
              <SummaryValue value={authors?.meta?.all} caption="Number of authors" />
            </GridItem>
          </Grid>
        </Box>
      }
    />
  );

  return (
    <AppTemplate
      route={route}
      tabNavigation={{
        tabs,
        goToTab,
        selectedTab,
      }}
      TopPanelComponent={posts && TopPanelComponent}
    >
      {selectedTab?.key === tabKeys.BLOG_POSTS && (
        <BlogPostsTab posts={posts} isLoading={isPostsLoading} isFetching={isPostsFetching} />
      )}
      {selectedTab?.key === tabKeys.BLOG_AUTHORS && (
        <BlogAuthorsTab authors={authors} isLoading={isAuthorsLoading} isFetching={isAuthorsFetching} />
      )}
    </AppTemplate>
  );
};

const Grid = styled.div`
  display: flex;
  align-items: center;
`;

const GridItem = styled.div`
  padding: 0 30px;
  border-left: 1px solid ${theme.colors.SUMMARY_ITEM_SEPARATOR};

  &:last-child {
    border-right: 1px solid ${theme.colors.SUMMARY_ITEM_SEPARATOR};
  }
`;

export default BlogPage;
