import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className="logo__prefix__seodity-logo-svg"
      width={28}
      {...props}
    >
      <path d="M49.688 56.62a1.758 1.758 0 102.489 2.487 1.758 1.758 0 10-2.489-2.487" fill="#ed655e" />
      <linearGradient
        id="logo__prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={9.802}
        y1={32.689}
        x2={58.412}
        y2={32.689}
      >
        <stop offset={0} stopColor="#e78b5f" />
        <stop offset={1} stopColor="#ed655e" />
      </linearGradient>
      <path
        d="M58.074 47.889l-.893-.893v.006l-6.842-6.845 3.736-13.94L37.869 10.01l-22.136 5.931-5.931 22.137 16.206 16.206L39.91 50.56l2.18 2.179c.062.047.124.101.18.156l1.997 1.997c.559.45 1.659.949 3.008-.399.432-.432.696-.823.843-1.168.033-.077.059-.153.083-.225.015-.045.026-.088.035-.13l.004-.029c.052-.241.047-.459.008-.646-.068-.367-.246-.609-.272-.645-.003 0-.003-.003-.003-.003l-2.088-2.088-.213-.213h.023l-.94-.941-.657-.656a1.668 1.668 0 00-.181-.157l-5.229-5.231a12.19 12.19 0 01-.32.202c-4.611 2.821-10.722 2.238-14.715-1.757-4.679-4.679-4.679-12.266 0-16.945s12.266-4.679 16.945 0c4.088 4.085 4.605 10.388 1.556 15.034l4.703 4.707h.002l.263.266 1.751 1.748 3.396 3.396c.047.036.092.077.133.119l1.997 1.996c.559.45 1.66.949 3.008-.399 1.445-1.444 1.004-2.42.667-2.839"
        fill="url(#logo__prefix__a)"
      />
      <linearGradient
        id="logo__prefix__b"
        gradientUnits="userSpaceOnUse"
        x1={14.563}
        y1={58.614}
        x2={46.063}
        y2={4.054}
      >
        <stop offset={0} stopColor="#e78b5f" />
        <stop offset={1} stopColor="#ed655e" />
      </linearGradient>
      <path
        d="M57.859 39.725L62 24.269 40.039 2.308l-30 8.039-8.039 30 21.961 21.961 15.531-4.161"
        fill="none"
        stroke="url(#logo__prefix__b)"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <linearGradient
        id="logo__prefix__c"
        gradientUnits="userSpaceOnUse"
        x1={25.301}
        y1={32.193}
        x2={38.467}
        y2={32.193}
      >
        <stop offset={0} stopColor="#e78b5f" />
        <stop offset={1} stopColor="#ed655e" />
      </linearGradient>
      <path d="M27.23 27.539a6.583 6.583 0 109.31 9.31 6.583 6.583 0 00-9.31-9.31" fill="url(#logo__prefix__c)" />
    </svg>
  );
}

export default SvgComponent;
