/* eslint-disable no-console */
import axios from "axios";
import { normalize } from "normalizr";

import config from "utils/config";
import { routes } from "utils/routes";
import { httpMethods, httpStatuses } from "utils/constants";
import { getAuthToken, unsetAuthToken } from "utils/auth";
import { getPath } from "utils/formatters";
import toast from "utils/toast";

import {
  decreaseLoading,
  handleRequestLoadingStart,
  handleRequestLoadingEnd,
  increaseLoading,
} from "App/redux/appSlice";

const api = axios.create({
  baseURL: config.API_URL,
});

api.interceptors.request.use(
  (axiosConfig) => {
    const authToken = getAuthToken();

    if (authToken) {
      axiosConfig.headers.Authorization = `Bearer ${authToken}`;
    }

    return axiosConfig;
  },
  ({ response }) => Promise.resolve(response),
);

api.interceptors.response.use(
  (axiosConfig) => axiosConfig,
  ({ response }) => {
    switch (response?.status) {
      case httpStatuses.UNAUTHORIZED:
        if (window.location.href.includes(routes.DASHBOARD)) {
          unsetAuthToken();
          window.location.href = routes.LOGIN;
        }
        break;

      default:
        break;
    }

    return Promise.reject(response);
  },
);

export const request = async ({
  actionType,
  method,
  endpoint,
  params,
  data,
  schema,
  errorHandler,
  thunkApi: { rejectWithValue, dispatch },
}) => {
  try {
    dispatch(increaseLoading());
    if (actionType) {
      dispatch(handleRequestLoadingStart({ actionType }));
    }

    const response = await api({
      url: endpoint,
      method,
      data,
      params,
    });

    const responseData = schema ? normalize(response.data, schema) : response.data;

    return responseData;
  } catch (error) {
    if (errorHandler) {
      return rejectWithValue(errorHandler({ error: error.data, status: error.status }));
    }

    return rejectWithValue(error.data);
  } finally {
    if (actionType) {
      dispatch(handleRequestLoadingEnd({ actionType }));
    }

    dispatch(decreaseLoading());
  }
};

export const fileUpload = async ({
  actionType,
  endpoint,
  file,
  errorHandler,
  type = "document",
  thunkApi: { rejectWithValue, dispatch },
}) => {
  try {
    dispatch(increaseLoading());
    if (actionType) {
      dispatch(handleRequestLoadingStart({ actionType }));
    }

    const formData = new FormData();
    formData.append("file", file);

    const response = await api({
      url: endpoint,
      method: httpMethods.POST,
      data: formData,
      params: {
        type,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
    });

    return {
      uid: response?.data?.uid,
    };
  } catch (error) {
    if (errorHandler) {
      return rejectWithValue(errorHandler({ error: error.data, status: error.status }));
    }

    return rejectWithValue(error.data);
  } finally {
    if (actionType) {
      dispatch(handleRequestLoadingEnd({ actionType }));
    }

    dispatch(decreaseLoading());
  }
};

export const fileDownload = async ({ actionType, endpoint, errorHandler, thunkApi: { rejectWithValue, dispatch } }) => {
  try {
    dispatch(increaseLoading());
    if (actionType) {
      dispatch(handleRequestLoadingStart({ actionType }));
    }

    const a = document.createElement("a");
    const href = getPath(config.API_URL, endpoint);
    a.href = href;
    a.target = "_blank";
    a.click();
    a.remove();

    return null;
  } catch (error) {
    if (errorHandler) {
      return rejectWithValue(errorHandler({ error: error.data, status: error.status }));
    }

    return rejectWithValue(error.data);
  } finally {
    if (actionType) {
      dispatch(handleRequestLoadingEnd({ actionType }));
    }

    dispatch(decreaseLoading());
  }
};

export const axiosRequest = async ({
  url,
  method = "get",
  options = {},
  successStatusCode = 200,
  onError,
  onFinally,
  dataSetter,
  errorMessage = "An error occurred. Please try again.",
}) => {
  try {
    const rs = await api(url, {
      method,
      ...options,
    });

    if (rs?.status === successStatusCode) {
      if (dataSetter) {
        return dataSetter(rs?.data);
      }
    }
  } catch (error) {
    toast.error(errorMessage);
    console.error({ error });

    if (onError) {
      onError();
    }
  } finally {
    if (onFinally) {
      onFinally();
    }
  }

  return null;
};

export default api;
