import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { schema } from "normalizr";

import { request } from "utils/api";
import { httpMethods } from "utils/constants";

const SLICE_KEY = "logs";

const logsSchema = new schema.Entity("logs", {}, { idAttribute: "uid" });

const INITIAL_STATE = {
  ids: null,
  entities: {},
  meta: {},
};

export const actionTypes = {
  GET_LOGS: "logs/getLogs",
};

const endpoints = {
  GET_LOGS: `/v1/logs`,
};

export const logsSelector = createSelector(
  ({ logs }) => logs,
  ({ ids, entities, meta }) => ({
    ids,
    entities,
    meta,
  }),
);

// available params example:
// userUID: "359100f2ac25416b9e471ddc405f8c22",
// type: "project",
// level: "info",
export const getLogs = createAsyncThunk(actionTypes.GET_LOGS, async ({ params } = {}, thunkApi) =>
  request({
    actionType: actionTypes.GET_LOGS,
    method: httpMethods.GET,
    endpoint: endpoints.GET_LOGS,
    thunkApi,
    params,
    schema: { logs: [logsSchema] },
  }),
);

const authSlice = createSlice({
  name: SLICE_KEY,
  initialState: INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(getLogs.fulfilled, (state, { payload }) => {
      state.ids = payload?.result?.logs;
      state.meta = payload?.result?.meta;
      state.entities = payload?.entities?.logs;
    });
  },
});

const { reducer } = authSlice;

export default reducer;
