import styled from "styled-components/macro";
import { NavLink as ReactRouterNavLink, useRouteMatch } from "react-router-dom";

import { theme } from "utils/theme";

const activeLinkStyles = {
  backgroundColor: theme.colors.NAVLINK_ACTIVE_BACKGROUND,
};

function NavLink({ route }) {
  const { component: IconComponent, props: iconProps } = route?.navIcon;

  const match = useRouteMatch(route?.path);

  return (
    <Link to={route?.path} activeStyle={activeLinkStyles} match={match}>
      {IconComponent && <IconComponent {...iconProps} />}
    </Link>
  );
}

const Link = styled(ReactRouterNavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 9px;

  &:hover {
    svg * {
      fill: ${theme.colors.NAVLINK_ACTIVE_ICON};
    }
  }

  svg * {
    fill: ${({ match }) => match && theme.colors.NAVLINK_ACTIVE_ICON};
  }
`;

export default NavLink;
