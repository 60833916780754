import styled from "styled-components/macro";

function OptionsBar({ LeftSideComponent, RightSideComponent }) {
  return (
    <Wrapper>
      <LeftSide>{LeftSideComponent && <LeftSideComponent />}</LeftSide>
      <RightSide>{RightSideComponent && <RightSideComponent />}</RightSide>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const LeftSide = styled.div``;

const RightSide = styled.div``;

export default OptionsBar;
